import {
  Button,
  Col,
  Flex,
  Row,
  Space,
  Typography,
} from "antd";
import { ContentState, EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SmartText from "../../../components/SmartText";
import { RiShapesLine } from "react-icons/ri";
import { FaSignature } from "react-icons/fa";
import { MdOutlineTextIncrease } from "react-icons/md";

const CreateSimpleEmail = ({ editorState, setEditorState }) => {
  const { Text } = Typography;
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const [selected, setSelected] = useState(1);
 

  return (
    <Row className="full-height-column" style={{ flex: 1 }}>
      <Col
        span={19}
        className="full-height-column"
        style={{ background: "#fff", borderRight: "1px solid #cbd5e0" }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(e) => setEditorState(e)}
        />
      </Col>
      <Col span={5} className="full-height-column">
        <Flex
          align="start"
          justify="space-between"
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "98%",
              background: "#f9f9f9",
            }}
          >
            <Formatters
              selected={selected}
              onEditorStateChange={handleEditorChange}
              editorState={editorState}
            />
          </div>

          <Space
            direction="vertical"
            size={0}
            style={{
              background: "#d4d4d4",
              borderLeft: "1px solid #d4d4d4",
              height: "100%",
              width: "100px",
            }}
          >
            <SideMenuButtons
              handleClick={(e) => setSelected(e)}
              item={1}
              active={selected == 1}
              icon={<FaSignature style={{ fontSize: 30 }} />}
              text="Signature"
            />
            <SideMenuButtons
              handleClick={(e) => setSelected(e)}
              item={2}
              active={selected == 2}
              icon={<RiShapesLine style={{ fontSize: 30 }} />}
              text="User"
            />
            <SideMenuButtons
              handleClick={(e) => setSelected(e)}
              item={3}
              active={selected == 3}
              icon={<MdOutlineTextIncrease style={{ fontSize: 28 }} />}
              text="Text"
            />
          </Space>
        </Flex>
      </Col>
    </Row>
  );
};

const SideMenuButtons = ({ item, text, icon, active, handleClick }) => {
  const { Text } = Typography;

  return (
    <Flex
      vertical
      onClick={() => handleClick(item)}
      align="center"
      justify="center"
      style={{
        cursor: "pointer",
        background: active ? "#f9f9f9" : "",
        padding: 3,
        height: 75,
      }}
    >
      {icon}
      <Text strong>{text}</Text>
    </Flex>
  );
};

const Formatters = ({ onEditorStateChange, editorState, selected }) => {
  const axios = useAxiosPrivate();

  const [signatures, setSignatures] = useState([]);

  useEffect(async () => {
    const response = await axios.post("/Signature/GetPaged", {
      pageSize: 999,
      page: 1,
    });

    setSignatures(response.data.data);
  }, []);

  const insertData = (value) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "TOKEN",
      "IMMUTABLE",
      { text: value }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithText = Modifier.replaceText(
      contentStateWithEntity,
      selectionState,
      value,
      editorState.getCurrentInlineStyle(),
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithText,
      "insert-characters"
    );
    onEditorStateChange(newEditorState);
  };

  const appendHtmlToEnd = (html) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentHtml = stateToHTML(currentContent);
    const updatedHtml = currentContentHtml + html;
    const newContentState = stateFromHTML(updatedHtml);

    const newEditorState = EditorState.createWithContent(newContentState);
    onEditorStateChange(newEditorState);
  };

  const onSignatureChanged = (e) => {
    const signature = signatures.find((c) => c.id == e);

    if (!signature) {
      return;
    }
    appendHtmlToEnd(signature.text);
  };

  const items = [
    {
      key: "1",
      label: "Fullname",
      value: "-fullname-",
    },
    {
      key: "2",
      label: "Firstname",
      value: "-firstname-",
    },
    {
      key: "3",
      label: "Lastname",
      value: "-lastname-",
    },
    {
      key: "4",
      label: "Email",
      value: "-email-",
    },
    {
      key: "5",
      label: "Phone",
      value: "-phone-",
    },
  ];

  const { Text } = Typography;
  return (
    <Space
      direction="vertical"
      size={24}
      className="w-100"
      style={{ padding: 10 }}
    >
      {selected == 1 && (
        <Space className="w-100" direction="vertical" size={0}>
          <Text>Insert pre-created signatures</Text>

          <Row gutter={[6, 6]}>
            {signatures?.map((c) => (
              <Col span={12}>
                <Button
                  onClick={() => onSignatureChanged(c.id)}
                  style={{
                    height: "75px",
                    width: "100%",
                    border: "1px solid #d4d4d4",
                    borderRadius: 0,
                  }}
                  size="large"
                >
                  {c.name}
                </Button>
              </Col>
            ))}
          </Row>
        </Space>
      )}

      {selected == 2 && (
        <Space className="w-100" direction="vertical" size={0}>
          <Text>Insert user data</Text>
          <Row gutter={[6, 6]}>
            {items.map((c) => (
              <Col span={8}>
                {" "}
                <Button
                  style={{
                    height: "75px",
                    width: "100%",
                    border: "1px solid #d4d4d4",
                    borderRadius: 0,
                    whiteSpace: 'normal'
                  }}
                  size="large"
                  onClick={() => insertData(c.value)}
                >
                  {c.label}
                </Button>
              </Col>
            ))}
          </Row>
        </Space>
      )}

      {selected == 3 && (<Space size={0} className="w-100" direction="vertical">
      <Text>Try ai tools</Text>
        <SmartText
          text={editorState.getCurrentContent().getPlainText()}
          setText={(e) => {
            const newContentState = ContentState.createFromText(e);
            const newEditorState =
            EditorState.createWithContent(newContentState);
            
            onEditorStateChange(newEditorState);
          }}
          />
          </Space>
      )}
    </Space>
  );
};

export default CreateSimpleEmail;
