import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  message,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import ContactStats from "./ContactStats";
import ButtonIcon from "../../shared/ButtonIcon";
import {
  MdEmail,
  MdFilterAlt,
  MdFilterAltOff,
  MdOutlineWhatsapp,
  MdSms,
} from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactAuthorFilter from "./Filters/ContactAuthorFilter";
import ContactFromDateFilter from "./Filters/ContactFromDateFilter";
import ContactToDateFilter from "./Filters/ContactToDateFilter";
import ContactOrderByFilter from "./Filters/ContactOrderByFilter";
import ContactOrderDirectionFilter from "./Filters/ContactOrderDirectionFilter";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import { UserOutlined } from "@ant-design/icons";
import env from "../../env.json";
import { useContext } from "react";
import { AudienceContext } from "./AudienceContext";
import { AuthorContext } from "../../context/AuthorsContext";
import NoDataFound from "../../shared/NoDataFound";
import TableLoading from "../../shared/TableLoading";
import ContactOptions from "./ContactOptions";
import ContactTagsFilter from "./Filters/ContactTagsFilter";
import ProfileAvatar from "../../components/ProfileAvatar";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AddTagToContactSider from "./AddTagToContactSider";
import CreateContactDrawer from "./Create/CreateContactDrawer";
import EditContactDrawer from "./Edit/EditContactDrawer";
const ContactList = () => {
  const navigate = useNavigate();

  const perPage = 7;
  const { authors } = useContext(AuthorContext);
  const axios = useAxiosPrivate();

  const {
    filters,
    setFilters,
    loading,
    contacts,
    setContacts,
    page,
    setPage,
    stats,
    setStats,
  } = useContext(AudienceContext);

  const { Text, Title } = Typography;
  const [showFilters, setShowFilters] = useState(() => false);
  const [current, setCurrent] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    const response = await axios.delete(`/Contacts/${item}`);

    if (response.data.success) {
      setContacts((prev) => prev.filter((c) => c.id != item));
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const deleteFromTag = async (tagId, contactId) => {
    const response = await axios.delete(
      `/Contacts/Remove-From-Tag/${tagId}/${contactId}`
    );

    if (response.data.success) {
      setContacts((prev) =>
        prev.map((c) =>
          c.id == contactId
            ? {
                ...c,
                tags: c.tags.filter((t) => t.id != tagId),
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const onAssigned = (id, newTags) => {
    setContacts((prev) =>
      prev.map((c) =>
        c.id == id
          ? {
              ...c,
              tags: newTags,
            }
          : c
      )
    );
  };

  const onEdit = (item) => {
    setContacts((prev) => prev.map((c) => (c.id == item.id ? item : c)));
  };

  const [addOpen, setAddOpen] = useState(false);
  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <EditContactDrawer
        onEdit={onEdit}
        item={current}
        open={editOpen}
        setOpen={setEditOpen}
      />

      <AddTagToContactSider
        onUpdate={onAssigned}
        setOpen={setEditTagsOpen}
        open={editTagsOpen}
        contact={current}
      />

      <CreateContactDrawer
        open={addOpen}
        setOpen={setAddOpen}
        onAdd={(e) => {
          setStats((prev) => ({
            ...prev,
            total: prev.total + 1,
          }));

          setContacts((prev) => [e, ...prev]);
        }}
      />
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <ContactStats />

        <Card size="small">
          <Flex align="center" justify="space-between" gap={12} wrap="wrap">
            <Title level={4} style={{ margin: 0 }}>
              Contacts
            </Title>

            <Space>
              <Button
                size="large"
                className="flex-button new-buttons"
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon size="large" icon={<MdFilterAlt />} background={"#f1f1f1"} />
                ) : (
                  <ButtonIcon size="large"
                    icon={<MdFilterAltOff />}
                    background={"#f1f1f1"}
                  />
                )}
              </Button>

              <Button
                type="primary"
                size="large"
                onClick={() => setAddOpen(true)}
                className="flex-button new-buttons"
              >
                Create contact
                <ButtonIcon size="large"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>

              <Button
                type="primary"
                size="large"
                onClick={() => navigate("/audience/import")}
                className="flex-button new-buttons"
              >
                Import contacts
                <ButtonIcon size="large"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Space>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <ContactAuthorFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <ContactFromDateFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <ContactToDateFilter />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <ContactTagsFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <ContactOrderByFilter />
            <ContactOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={5}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={7}>
                <Text className="column-header">Tags</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Email</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Phone</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Verification</Text>
              </Col>
              <Col span={1}>
                <Text className="column-header">Tool</Text>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !contacts || contacts.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New contacts"
                description="Click the New Contact button on the Contacts page to create a new contact"
                title="You have not created any contacts yet"
              />
            </Card>
          ) : (
            <>
              {contacts?.map((c, ind) => (
                <Card size="small" key={ind}>
                  <Row gutter={[6, 6]} style={{ height: "100%" }}>
                    <Col span={5} className="center-table-column">
                      <Flex align="center" justify="start" gap={6}>
                        <ProfileAvatar name={`${c.firstname} ${c.lastname}`} />
                        <Text
                          type="link"
                          onClick={() => navigate(`/audience/contact/${c.id}`)}
                        >
                          {c.firstname ? c.firstname : "-"}{" "}
                          {c.lastname ? c.lastname : "-"}
                        </Text>
                      </Flex>
                    </Col>
                    <Col span={7} className="center-table-column">
                      {c.tags?.slice(0, 1).map((d, i) => (
                        <Tag
                          key={i}
                          style={{ padding: 5 }}
                          className={"black-color-tag"}
                          color={"#e3e3e3"}
                        >
                          <Flex gap={12} align="center" justify="space-between">
                            {d.name}

                            <Popconfirm
                              title="Remove contact from tag"
                              description="Are you sure to delete this tag-contact association?"
                              onConfirm={() => deleteFromTag(d.id, c.id)}
                              okText="Yes, remove it"
                              cancelText="No"
                            >
                              <Button
                                size="small"
                                shape="circle"
                                className="flex-button"
                                icon={<IoClose />}
                              ></Button>
                            </Popconfirm>
                          </Flex>
                        </Tag>
                      ))}
                      {c.tags?.length > 1 ? (
                        <Tag
                          style={{ padding: 6 }}
                          className={"black-color-tag"}
                          color={"#e3e3e3"}
                        >
                          + {c.tags.length - 1} more...
                        </Tag>
                      ) : (
                        ""
                      )}

                      <Button
                        shape="circle"
                        className="flex-button"
                        onClick={() => {
                          setCurrent(c);
                          setEditTagsOpen(true);
                        }}
                        icon={<FiPlus />}
                      />
                    </Col>
                    <Col span={5} className="center-table-column">
                      <Tag
                        style={{ padding: 5 }}
                        className={"black-color-tag"}
                        color={"#e3e3e3"}
                      >
                        <Flex gap={12} align="center" justify="space-between">
                          {c.email}

                          <Tooltip title="Send email">
                            <Button
                              size="small"
                              shape="circle"
                              className="flex-button"
                              icon={<MdEmail />}
                            ></Button>
                          </Tooltip>
                        </Flex>
                      </Tag>
                    </Col>
                    <Col span={4} className="center-table-column">
                      {c.phone ? (
                        <Tag
                          style={{ padding: 5 }}
                          className={"black-color-tag"}
                          color={"#e3e3e3"}
                        >
                          <Flex gap={12} align="center" justify="space-between">
                            {c.phone}

                            <Flex align="center" justify="end" gap={6}>
                              <Tooltip title="Send SMS">
                                <Button
                                  size="small"
                                  shape="circle"
                                  disabled
                                  className="flex-button"
                                  icon={<MdSms />}
                                ></Button>
                              </Tooltip>

                              <Tooltip title="Send whatsapp message">
                                <Button
                                  size="small"
                                  disabled
                                  shape="circle"
                                  className="flex-button"
                                  icon={<MdOutlineWhatsapp />}
                                ></Button>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </Tag>
                      ) : (
                        "-"
                      )}
                    </Col>
                    <Col span={2} className="center-table-column">
                      {c.emailStatus == 1 ? (
                        <Tag
                          color="green"
                          className="flex-button"
                          style={{ fontWeight: 600, fontSize: 16, padding:5 }}
                        >
                          {" "}
                          <MdEmail style={{ fontSize: 18 }} />
                          100%
                        </Tag>
                      ) : c.emailStatus == 2 ? (
                        <Tag
                          color="red"
                          style={{ fontWeight: 600, fontSize: 16, padding:5 }}
                        >
                          <MdEmail style={{ fontSize: 18 }} /> 100%
                        </Tag>
                      ) : c.emailStatus == 3 ? (
                        <Tag
                          color="orange"
                          className="flex-button"
                          style={{ fontWeight: 600, fontSize: 16, padding:5 }}
                        >
                          {" "}
                          <MdEmail style={{ fontSize: 18 }} /> 90%
                        </Tag>
                      ) : c.emailStatus == 4 ? (
                        <Tag
                          color="gray"
                          className="flex-button"
                          style={{ fontWeight: 600, fontSize: 16, padding:5 }}
                        >
                          {" "}
                          <MdEmail style={{ fontSize: 18 }} /> 50%
                        </Tag>
                      ) : (
                        <Tag
                          className="flex-button"
                          style={{ fontWeight: 600, fontSize: 16, padding:5 }}
                        >
                          {" "}
                          <MdEmail style={{ fontSize: 18 }} /> 0%{" "}
                        </Tag>
                      )}
                    </Col>
                    <Col span={1}>
                      <Flex align="center" style={{ height: "100%" }}>
                        <ContactOptions
                          contact={c}
                          setCurrent={setCurrent}
                          openEdit={() => setEditOpen(true)}
                          openDelete={() => setDeleteOpen(true)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end" className="w-100">
                  <Pagination
                    current={page}
                    pageSize={perPage}
                    showSizeChanger={false}
                    onChange={(page) => setPage(page)}
                    total={stats?.total}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default ContactList;
