import { Button, DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { MdArrowDropDown, MdClear } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

const CreatedDateFromFilter = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(DealListContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>From</Text>
      <DatePicker
        size="large"
        placeholder="Select created date start"
        className="dates-filter-select"
        value={filters?.createdFromDate}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
        onChange={(e) =>
          setFilters((prev) => ({ ...prev, createdFromDate: e }))
        }
      />
    </Flex>
  );
};

export default CreatedDateFromFilter;
