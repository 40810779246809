import { Outlet } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Typography,
  Grid,
  Avatar
} from "antd";
import { useContext, useState } from "react";
import CreateCampaignModal from "./CreateCampaignModal";
import Stats from "./CampaignStats";
import { CampaignContext } from "./CampaignContext";
import CampaignAuthorSelect from "./Filters/CampaignAuthorSelect";
import CampaignStatusSelect from "./Filters/CampaignStatusSelect";
import CampaignOrderBy from "./Filters/CampaignOrderBy";
import CampaingOrderByDirection from "./Filters/CampaignOrderByDirection";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { CampaignType } from "../../shared/enums";
import CreateFromDateFilter from "./Filters/CreatedDateFilter";
import CreatedToDateFilter from "./Filters/CreatedToDateFilter";
import CampaignTypeSelect from "./Filters/CampaignTypeSelect";
import { IoClose } from "react-icons/io5";
import ButtonIcon from "../../shared/ButtonIcon";
import { DateFormat } from "../../dateformat";
import env from '../../env.json'
import { UserOutlined } from "@ant-design/icons";
import { AuthorContext } from "../../context/AuthorsContext";

const { useBreakpoint } = Grid;

const CampaignListsLayout = () => {
  const { Title, Text } = Typography;
  const {authors} = useContext(AuthorContext)
  const { showFilters, setShowFilters, filters, setFilters } = useContext(CampaignContext);
  const [addOpen, setAddOpen] = useState(false);
  const screens = useBreakpoint();

  return (
    <>
      <CreateCampaignModal open={addOpen} setOpen={setAddOpen} />

      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <Stats total={0} type={CampaignType.SMS} />

        <Card size="small">
          <Flex
            align="center"
            justify="space-between"
            wrap="wrap"
            style={{
              ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
            }}
          >
            <Title style={{ margin: 0 }} level={4}>
              Campaigns
            </Title>

            <Flex
              wrap="wrap"
              gap={10}
              style={{
                ...(screens.xs ? { flexBasis: "100%", gap: "10px" } : {}),
              }}
            >
              <Button
                size="large"
                className="flex-button new-buttons"
                block={screens.xs}
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon size="large" icon={<MdFilterAlt />} background={"#f1f1f1"} />
                ) : (
                  <ButtonIcon size="large"
                    background={"#f1f1f1"}
                    icon={<MdFilterAltOff />}
                  />
                )}
              </Button>

              <Button
                type="primary"
                size="large"
                block={screens.xs}
                className="flex-button new-buttons"
                onClick={() => setAddOpen(true)}
              >
                New campaign
                <ButtonIcon size="large"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Flex>
          </Flex>

          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: "24px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CampaignAuthorSelect />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CampaignStatusSelect />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <CampaignTypeSelect />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <CreateFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <CreatedToDateFilter />
              </Col>
            </Row>
          )}
        </Card>
        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>
          <Space>
            <CampaignOrderBy />
            <CampaingOrderByDirection />
            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>
        <Outlet />
      </Space>
    </>
  );
};

export default CampaignListsLayout;
