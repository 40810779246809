import { Button, Flex, Form, Input, message, Modal, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const EditSelectedNotificationGroupTemplate = ({ open, setOpen, template, onEdit }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const onSubmit = async (e) => {
    setLoading(true);
    let response = await axios.put("/Notification/Notification-Template-Group", {
        id: template.id,
        name: e.name
    });

    if(response.data.success){
        onEdit({
            id:template.id,
            name: e.name
        });
        setOpen(false)
    }else{
        message.error(response.data.message)
    }
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldValue("name", template?.name);
    form.setFieldValue("description", template?.description)
  }, [template])

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Edit notification template
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form onFinish={onSubmit} form={form}>
        <Space className="w-100" size={0} direction="vertical">
          <Text>Name</Text>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Plase enter a name",
              },
            ]}
          >
            <Input placeholder="Enter name" size="large" />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditSelectedNotificationGroupTemplate;
