import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";

export const CreateFollowupContext = createContext({
    details: {},
    setDetails: () => {},

    step: 0,
    setStep: () => {},

    emailTemplate: null,
    setEmailTemplate: () => {},

    notificationTemplate: null,
    setNotificationTemplate: () => {},

    senders:[],
    setSenders: () => {}
});

export const CreateFollowupContextProvider = ({children}) => {
    const axios = useAxiosPrivate()

    const [details, setDetails] = useState();

    const [step, setStep] = useState(0);
    const [emailTemplate, setEmailTemplate] = useState();
    const [notificationTemplate, setNotificationTemplate] = useState()
    const [senders, setSenders] = useState();

    useEffect(async () => {
        let response = await axios.get(`/Sender/GetSenders`,{
            params:{
                page: 1,
                pageSize: 9999,
                orderBy:"name",
                sortDirection:1,
                from: null,
                to: null
            },
            paramsSerializer: params => {
                return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
              }
        });

        setSenders(response.data.items.map(c => ({
            value: c.id,
            label: c.nickName
        })))
    },[])
    return <CreateFollowupContext.Provider value={{
        details,
        setDetails,

        step,
        setStep,

        emailTemplate,
        setEmailTemplate,

        notificationTemplate,
        setNotificationTemplate,

        senders,
        setSenders
    }}>
        {children}
    </CreateFollowupContext.Provider>
}