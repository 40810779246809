import {
  Card,
  Typography,
  Button,
  Space,
  Flex,
  Row,
  Col,
  Avatar,
  Grid,
  Pagination,
} from "antd";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import CreateMedia from "./CreateMedia";
import { useState, useContext } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import MediaAuthorSelect from "./Filters/MediaAuthorSelect";
import MediaOrderBy from "./Filters/MediaOrderBy";
import MediaOrderByDirection from "./Filters/MediaOrderByDirection";
import env from "../../env.json";
import { DateFormat } from "../../dateformat";
import MediaItemDropDown from "./MediaItemDropDown";
import { FiPlus } from "react-icons/fi";
import NoDataFound from "../../shared/NoDataFound";
import { CreatedDateFilter } from "./Filters/CreatedDateFilter";
import { CreatedFromDateFilter } from "./Filters/CreatedFromDateFilter";
import { MediaStats } from "./MediaStats";
import { MediaContext } from "./MediaContext";
import ButtonIcon from "../../shared/ButtonIcon";
import { AuthorContext } from "../../context/AuthorsContext";
import { IoClose } from "react-icons/io5";
import { UserOutlined } from "@ant-design/icons";
import TableLoading from "../../shared/TableLoading";

const { useBreakpoint } = Grid;

const MediaList = () => {
  const { Title, Text } = Typography;
  const { authors } = useContext(AuthorContext);
  const screens = useBreakpoint();

  const {
    filters,
    setFilters,
    stats,
    page,
    setPage,
    showFilters,
    setShowFilters,
    setStats,
    documents,
    setDocuments,
    loading,
    setLoading,
  } = useContext(MediaContext);

  const [isAddOpen, setIsAddOpen] = useState(false);

  const perPage = 7;

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    if (item && item.length > 0) {
      await removeRangeDocuments();
      return;
    }

    await removeDocument(item);
  };

  const removeDocument = async (id) => {
    const response = await axiosPrivate.delete(`/Document/${id}`);
    if (response && response.data.data) {
      setDocuments(documents.filter((image) => image.id != id));
      setStats((prev) => ({
        ...prev,
        total: documents.filter((f) => f.id != id).length,
      }));
    }
  };

  const removeRangeDocuments = async () => {
    var response = await axiosPrivate.post("/Document/DeleteRange", {
      ids: selectedDocuments,
    });
    if (response && response.data.success) {
      setDocuments(documents.filter((f) => !selectedDocuments.includes(f.id)));
      setSelectedDocuments([]);
    }
  };

  const downloadDocument = async (id) => {
    const response = await axiosPrivate.get(`/Document/Download/${id}`);

    var blob = new Blob([response.data.data], { type: response.data.mimeType });
    var link = document.createElement("a");
    link.href =
      "data:" + response.data.mimeType + ";base64," + response.data.data; // window.URL.createObjectURL(blob);
    link.download = response.data.fileName;
    link.click();
  };

  const onAdd = (val) => {
    setDocuments([val, ...documents]);

    setStats((prev) => ({ ...prev, total: prev.total + 1 }));
  };

  return (
    <>
      <CreateMedia setIsOpen={setIsAddOpen} isOpen={isAddOpen} onAdd={onAdd} />
      {modalIsOpen && (
        <DeleteConfirmationModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          onDeleteConfirm={handleDeleteConfirm}
          item={itemToDelete}
        />
      )}

      <Space className="w-100" size={24} direction="vertical">
        <MediaStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              justify="space-between"
              align="center"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title style={{ margin: 0, padding: 0 }} level={4}>
                Media
              </Title>
              <Flex
                gap={6}
                justify="end"
                align="center"
                wrap="wrap"
                style={{
                  ...(screens.xs ? { width: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button new-buttons"
                  type="primary"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filter
                  {!showFilters ? (
                    <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
                  ) : (
                    <ButtonIcon
                      background={"#5a6bc5"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="flex-button new-buttons"
                  onClick={(e) => setIsAddOpen(true)}
                >
                  New media
                  <ButtonIcon
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <MediaAuthorSelect />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <CreatedFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <CreatedDateFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>
          <Space>
            <MediaOrderBy />
            <MediaOrderByDirection />
            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space direction="vertical" className="w-100">
          <Card size="small">
            <Row>
              <Col span={6}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={6}>
                <Text className="column-header">Description</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Date</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Author</Text>
              </Col>
              <Col span={2}>
                <Flex align="center" justify="end">
                  <Text className="column-header">Tool</Text>
                </Flex>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !documents || documents.length == 0 ? (
            <Card size="small">
              <NoDataFound
                onAdd={() => setIsAddOpen(true)}
                title="You have not created any media documents"
                description=" Click the New media button on the documents page to create a
                      new document"
                addText="New document"
              />
            </Card>
          ) : (
            <>
              {documents.map((c, i) => (
                <Card size="small" key={i}>
                  <Row>
                    <Col span={6} className="center-table-column">
                      <Text>{c.name}</Text>
                    </Col>
                    <Col span={6} className="center-table-column">
                      <Text>{c.description}</Text>
                    </Col>
                    <Col span={5} className="center-table-column">
                      <Text>
                        {new Date(c.createdAt * 1000).toLocaleDateString(
                          "en-us",
                          DateFormat
                        )}
                      </Text>
                    </Col>
                    <Col span={5} className="center-table-column">
                      <Flex align="center" justify="start" gap={6}>
                        <Avatar
                          src={`${env.fileUpload}${c.authorProfile}`}
                          icon={<UserOutlined style={{ color: "#5a6bc5" }} />}
                        />
                        <Text style={{ color: "#707070" }}>
                          {c.creatorUserName}
                        </Text>
                      </Flex>
                    </Col>
                    <Col span={2}>
                      <Flex align="center" justify="end">
                        <MediaItemDropDown
                          id={c.id}
                          name={c.path}
                          onDelete={(id) => handleOpenModal(id)}
                          onDownload={(id) => downloadDocument(id)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end">
                  <Pagination
                    current={page}
                    onChange={(p) => setPage(p)}
                    pageSize={perPage}
                    total={stats?.total}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default MediaList;
