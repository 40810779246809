import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const FollowupOptions = ({id, setCurrent, setDeleteOpen}) => {
  const navigate = useNavigate();

    const items = [
    {
      key: "1",
      label: "Edit selected follow up",
      icon: <AiFillEdit />,
      onClick: () => navigate(`/Followup/details/${id}`)
    },
    {
      key: "2",
      label: "Delete selected follow up",
      danger: true,
      icon: <BsFillTrashFill />,
      onClick: () => {
        setCurrent(id);
        setDeleteOpen(true)
      }
    },
  ];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          shape="circle"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal style={{ fontSize: 26, color: "#b1b1b0" }} />
          }
        />
      </Dropdown>
    </>
  );
};

export default FollowupOptions;
