import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
const NotificationTemplatresOptions = ({template, setCurrent, openEdit, openDelete}) => {
  const items = [
    {
      key: "1",
      label: "Edit selected design",
      icon:<AiFillEdit/>,
      onClick: () => {
        setCurrent(template);
        openEdit();
      }
    },
    {
      key: "2",
      label: "Delete selected design",
      danger: true,
      icon: <BsFillTrashFill/>,
      onClick: () => {
        setCurrent(template);
        openDelete();
      }
    }
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        type="text"
        shape="circle"
        className="flex-button"
        icon={<IoEllipsisHorizontal style={{ fontSize: 26, color:"#7e7e7e" }} />}
      />
    </Dropdown>
  );
};

export default NotificationTemplatresOptions;
