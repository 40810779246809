import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { MdSportsEsports } from "react-icons/md";
import SelectableTemplate from "../../../../components/SelectableTemplate";
import TapZapTemplates from "../../Email/TapZapTemplates";
import YourTemplates from "../../List/YourTemplates";
import CreateTemplateModal from "../../List/CreateTemplateModal";
import ButtonIcon from "../../../../shared/ButtonIcon";

const EmailTemplateGroupDetails = () => {
  const { id } = useParams();
  const axios = useAxiosPrivate();

  const [form] = Form.useForm();

  useEffect(async () => {
    let response = await axios.get(`/Template/Group/${id}`);

    form.setFieldValue("name", response.data.name);
    form.setFieldValue("description", response.data.description);
  }, []);

  const [loading, setLoading] = useState(false);

  const [addOpen, setAddOpen] = useState();

  const submit = async (e) => {
    setLoading(true);

    var response = await axios.put(`/Template/Group`, {
      id: id,
      name: e.name,
      description: e.description,
    });

    setLoading(false);
    if (!response.data.success) {
      message.error(response.data.message);
    }
  };
  const { Title, Text } = Typography;
  return (
    <>
      <CreateTemplateModal open={addOpen} setOpen={setAddOpen} id={id} />
      <Card>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={6}>
            <Title style={{ margin: 5 }} level={4}>
              Main details
            </Title>
            <Card style={{ boxShadow: "none" }}>
              <Form form={form} onFinish={submit}>
                <Space direction="vertical" size={0} className="w-100">
                  <Text>Name</Text>
                  <Form.Item name="name">
                    <Input size="large" placeholder="Enter name" />
                  </Form.Item>
                </Space>

                <Space direction="vertical" size={0} className="w-100">
                  <Text>Description</Text>
                  <Form.Item name="description">
                    <TextArea
                      rows={4}
                      size="large"
                      placeholder="Enter description"
                    />
                  </Form.Item>
                </Space>
                <Flex align="center" justify="end">
                  <Button loading={loading} htmlType="submit" type="primary">
                    Save changes
                  </Button>
                </Flex>
              </Form>
            </Card>
          </Col>

          <Col xs={24} md={18}>
            <Flex
              align="center"
              justify="space-between"
              style={{ marginBottom: 5 }}
            >
              <Title style={{ margin: 5 }} level={4}>
                Templates
              </Title>
              <Button
                type="primary"
                size="large"
                className="flex-button new-buttons"
                onClick={() => setAddOpen(true)}
              >
                Create Desing
                <ButtonIcon
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Flex>
            <Card size="small" style={{ boxShadow: "none" }}>
              <YourTemplates
                showFilters={false}
                groupId={id}
                setTotal={() => {}}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EmailTemplateGroupDetails;
