import { Button, Dropdown, message } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdMore } from "react-icons/io";
import DeleteCompany from "./DeleteCompany";
import { BsTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";

const CompanyOptions = ({ id, onDelete, setShowEditing, setCurrent }) => {
  const copyApiId = async () => {
    try {
      await navigator.clipboard.writeText(`${id}`);
      message.success("Company api id copied to clipboard");
    } catch (err) {
      message.error("Failed to copy");
    }
  };
  const items = [
    {
      label: "Copy api id",
      key: "2",
      icon: <FaCopy />,
      onClick: () => copyApiId(),
    },
    {
      label: "Edit company",
      key: "1",
      icon: <AiFillEdit />,
      onClick: () => {
        setShowEditing(true);
        setCurrent(id);
      },
    },
    {
      label: <DeleteCompany id={id} onDelete={onDelete} />,
      key: "3",
      danger: true,
      icon: <BsTrashFill />,
    },
  ];

  return (
    <Dropdown trigger={["click"]} menu={{ items }}>
      <Button
        shape="circle"
        type="text"
        className="flex-button"
        icon={<IoEllipsisHorizontal style={{ fontSize: 26, color:"#7e7e7e" }} />}

      ></Button>
    </Dropdown>
  );
};

export default CompanyOptions;
