import { useContext } from "react";
import { EmailTemplateGroupContext } from "../EmailTemplateGroupContext";
import { Flex, Select, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";
import { AuthorContext } from "../../../../context/AuthorsContext";

const EmailTemplateAuthorFilter = () => {
  const {authors} = useContext(AuthorContext)
  const { filters, setFilters } = useContext(EmailTemplateGroupContext);
  const { Text } = Typography;

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>Authors</Text>
        <Select
          size="large"
          maxTagCount="responsive"
          className="order-by-select"
          value={filters?.selectedAuthors}
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
          defaultValue={filters?.selectedAuthors}
          placeholder="Select campaign filter authors"
          style={{ flexGrow: 1 }}
          options={authors}
          mode="multiple"
          onChange={handleChanged}
        />
      </Flex>
    </>
  );
};

export default EmailTemplateAuthorFilter;
