import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  message,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import ButtonIcon from "../../../shared/ButtonIcon";
import { MdCancel } from "react-icons/md";

const CreateTag = ({ setShow, onAdd }) => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post("Tag", {
      name: e.name,
    });

    if (response.data.success) {
        onAdd(response.data);
        setShow(false)
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Card size="small" style={{ boxShadow: "none" }}>
      <Space direction="vertical" className="w-100">
        <Title style={{ margin: 0, padding: 0 }} level={5}>
          Add new tag
        </Title>

        <Form form={form} onFinish={submit}>
          <Space className="w-100" size={0} direction="vertical">
            <Text>Name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Plase provide a name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter tag name" />
            </Form.Item>
          </Space>

          <Flex align="center" justify="end" gap={6}>
            <Button
              onClick={() => setShow(false)}
              className="flex-button new-buttons"
            >
              Cancel
              <ButtonIcon
                icon={<MdCancel style={{ fontSize: 16 }} />}
                background={"#fff"}
              />
            </Button>

            <Button
              loading={loading}
              onClick={() => form.submit()}
              className="flex-button new-buttons"
              type="primary"
            >
              Save changes
              <ButtonIcon
                icon={<FaCheck style={{ fontSize: 14 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Form>
      </Space>
    </Card>
  );
};

export default CreateTag;
