import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Input,
  Pagination,
  Row,
  Space,
  Table,
  Avatar,
  Tag,
  Typography,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { FiPlus } from "react-icons/fi";
import QueryString from "qs";
import { BsFillTrashFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import CreateNotificationTemplateModal from "./CreateNotificationTemplateModal";
import Stat from "../../../shared/Stat";
import env from "../../../env.json";
import { UserOutlined } from "@ant-design/icons";
import { FaProjectDiagram } from "react-icons/fa";
import { DateFormat } from "../../../dateformat";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import EditNotificationTemplateModal from "./EditNotificationTemplateModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";

const NotificationTemplateDetails = () => {
  const [addOpen, setAddOpen] = useState(false);
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const [data, setData] = useState();
  const { Text, Title } = Typography;
  const [stats, setStats] = useState({
    total: 0,
    first: null,
    last: null,
    author: null,
    profile: null,
  });
  const [page, setPage] = useState(1);
  const perPage = 4;
  const [templates, setTemplates] = useState();
  const [current, setCurrent] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDelteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(async() => {
    const response = await axios.get(`/Notification/Templates/Group/${id}`);
    form.setFieldValue("name", response.data.name);
    form.setFieldValue("description", response.data.description)
  }, [])

  useEffect(async () => {
    const templatesResponse = await axios.get(`/Notification/Templates/List`, {
      params: {
        page: 1,
        pageSize: perPage,
        groupId: id,
        page: page,
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: "repeat", skipNulls: true });
      },
    });

    setTemplates(templatesResponse.data.items);
    setStats((prev) => ({
      total: templatesResponse.data.total,
      first: templatesResponse.data.first,
      last: templatesResponse.data.last,
      author: templatesResponse.data.authorName,
      profile: templatesResponse.data.authorProfile,
    }));
  }, [page]);

  const handleDataUpdate = async (e) => {
    setLoading(true)

    const response = await axios.put(`/Notification/Notification-Template-Group`, {
        id: id,
        name: e.name,
        description: e.description
    });

    if(!response.data.success){
        message.error(response.data.message);
    }

    setLoading(false)
  }
  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDelteOpen(false)}
        item={current?.id}
        onDeleteConfirm={async (item, confirmed) => {
          if (!confirmed) {
            return;
          }

          let response = await axios.delete(
            `/Notification/Notification-Template/${current.id}`
          );

          if (response.data.success) {
            setTemplates((prev) => prev.filter((c) => c.id != item));

            setStats((prev) => ({ ...prev, total: prev.total - 1 }));
          } else {
            message.error(response.data.message);
          }
        }}
      />
      <EditNotificationTemplateModal
        open={editOpen}
        setOpen={setEditOpen}
        item={current}
        onEdit={(e) =>
          setTemplates((prev) =>
            prev.map((c) =>
              c.id == e.id
                ? {
                    ...c,
                    header: e.header,
                    template: e.template,
                    url: e.url,
                  }
                : c
            )
          )
        }
      />

      <CreateNotificationTemplateModal
        onAdd={(e) => {setTemplates((prev) => [...prev, e]); setStats(prev => ({...prev, total: prev.total +1}))}}
        open={addOpen}
        setOpen={setAddOpen}
        groupId={id}
      />
      <Card>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} xxl={8}>
            <Title style={{ margin: 5 }} level={4}>
              Main details
            </Title>
            <Card style={{ boxShadow: "none" }}>
              <Form onFinish={handleDataUpdate} form={form}>
                <Space direction="vertical" className="w-100" size={0}>
                  <Text>Name</Text>
                  <Form.Item name="name">
                    <Input placeholder="Enter name" size="large" />
                  </Form.Item>
                </Space>
                <Space direction="vertical" className="w-100" size={0}>
                  <Text>Description</Text>
                  <Form.Item name="description">
                    <TextArea
                      size="large"
                      rows={5}
                      placeholder="Enter description"
                    />
                  </Form.Item>
                </Space>

                <Flex align="center" justify="end">
                  <Button htmlType="submit" loading={loading} type="primary">Save changes</Button>
                </Flex>
              </Form>
            </Card>
          </Col>
          <Col xs={24} sm={24} xxl={16}>
            <Space className="w-100" size={24} direction="vertical">
              <Row gutter={[24, 24]}>
                <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <Stat
                    showShadow={false}
                    value={stats?.total}
                    name="Total"
                    icon={<FaProjectDiagram style={{ fontSize: 22 }} />}
                    loading={false}
                  />
                </Col>
                <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <Stat
                    showShadow={false}
                    value={new Date(stats?.first).toLocaleDateString(
                      "en-US",
                      DateFormat
                    )}
                    name="First"
                    icon={<MdFirstPage style={{ fontSize: 24 }} />}
                    loading={false}
                  />
                </Col>
                <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <Stat
                    showShadow={false}
                    value={new Date(stats?.last).toLocaleDateString(
                      "en-US",
                      DateFormat
                    )}
                    name="Last"
                    icon={<MdLastPage style={{ fontSize: 24 }} />}
                    loading={false}
                  />
                </Col>
                <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <Stat
                    showShadow={false}
                    value={stats?.authorName}
                    name="Most authored"
                    icon={
                      <Avatar
                        icon={<UserOutlined />}
                        size="large"
                        src={`${env.fileUpload}${stats?.authorImage}`}
                      />
                    }
                    loading={false}
                  />
                </Col>
              </Row>
              <Card size="small" style={{ boxShadow: "none" }}>
                <Flex align="center" justify="space-between" className="w-100">
                  <Title style={{ margin: 0}} level={4}>
                    Templates
                  </Title>
                  <Button
                    className="flex-button bold-button"
                    size="large"
                    onClick={() => setAddOpen(true)}
                    type="primary"
                    icon={<FiPlus style={{ fontSize: 18 }} />}
                  >
                    New template
                  </Button>
                </Flex>
              </Card>
              {templates ? (
                <Space size={24} direction="vertical" className="w-100">
                  {templates.map((c, i) => (
                      <Card
                        className="zero-margin-padding"
                        size="small"
                        key={i}
                        style={{ boxShadow: "none" }}
                      >
                        <Table
                          style={{ marginTop: 1, borderBottom: "none" }}
                          dataSource={[c]}
                          columns={[
                            {
                              width: 100,
                              title: "Language",
                              render: (row) => (
                                <Tag size="large">{row.languageName}</Tag>
                              ),
                            },
                            {
                              width: 200,
                              title: "Header",
                              render: (row) => <Text strong>{row.header}</Text>,
                            },
                            {
                              width: 300,
                              title: "url",
                              render: (row) => <Text strong>{row.url}</Text>,
                            },
                            {
                              title: "Template",
                              render: (row) => (
                                <Text strong>{row.template}</Text>
                              ),
                            },
                            {
                              width: 100,
                              render: (row) => (
                                <Flex align="center" justify="end" gap={6}>
                                  <Button
                                    type="text"
                                    className="flex-button"
                                    onClick={() => {
                                      setCurrent(row);
                                      setEditOpen(true);
                                    }}
                                    icon={<FaPen />}
                                  ></Button>
                                  <Button
                                    type="text"
                                    className="flex-button"
                                    danger
                                    onClick={() => {
                                      setCurrent(row);
                                      setDelteOpen(true);
                                    }}
                                    icon={<BsFillTrashFill />}
                                  ></Button>
                                </Flex>
                              ),
                            },
                          ]}
                          pagination={false}
                        ></Table>
                      </Card>
                  ))}

                  <Flex align="center" justify="end">
                    <Pagination
                      onChange={(page) => setPage(page)}
                      current={page}
                      pageSize={perPage}
                      total={stats?.total}
                    />
                  </Flex>
                </Space>
              ) : (
                <Card style={{ boxShadow: "none" }}>
                  <Empty description="No templates found" />
                </Card>
              )}
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NotificationTemplateDetails;
