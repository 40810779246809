import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { CampaignContext } from "../CampaignContext";
import { MdArrowDropDown } from "react-icons/md";

const CreatedToDateFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(CampaignContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>To</Text>
      <DatePicker
        size="large"
        onChange={(e) => setFilters((prev) => ({ ...prev, to: e }))}
        defaultValue={filters?.to}
        placeholder="Select created date end"
        className="dates-filter-select"
        value={filters?.to}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default CreatedToDateFilter;
