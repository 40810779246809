import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";
import OrderBySelect from "../../../../shared/OrderBySelect";

const SmsOrderByFilter = () => {
  const { filters, setFilters } = useContext(SmsListContext);

  const options = [
    {
      value: "created",
      label: "Date created"
    },
    {
      value: "name",
      label: "Name"
    },
  ];

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      orderBy: e,
    }));
  };
  return (
    <>
     <OrderBySelect
      options={options}
      handleChanged={handleChanged}
      defaultValue={filters?.orderBy}
    />
    </>
  );
};

export default SmsOrderByFilter;
