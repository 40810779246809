import { Button, Card, Flex, Space, Typography, Grid, Col, Row, Avatar } from "antd";
import { useContext, useState } from "react";
import CreateSenderModal from "./CreateSenderModal";
import { FiPlus } from "react-icons/fi";
import SenderTable from "./SenderTable";
import SenderStats from "./SenderStats";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import SenderFilters from "./SenderFilters";
import ButtonIcon from "../../../shared/ButtonIcon";
import EmailSenderAuthorSelect from "./Filters/EmailSenderAuthorSelect";
import EmailSenderConfirmationSelect from "./Filters/EmailSenderConfirmationSelect";
import EmailSenderDefaultSelect from "./Filters/EmailSenderDefaultSelect";
import EmailSenderCreatedDateFromSelect from "./Filters/EmailSenderCreatedDateFromSelect";
import EmailSenderCreatedDateToFilter from "./Filters/EmailSenderCreatedDateToFilter";
import EmailSenderOrderBySelect from "./Filters/EmailSenderOrderBySelect";
import EmailSenderOrderDirectionSelect from "./Filters/EmailSenderOrderDirectionSelect";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../../dateformat";
import { AuthorContext } from "../../../context/AuthorsContext";
import { EmailSenderContext } from "./EmailSenderContext";
import env from '../../../env.json'
import { UserOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;
const SendersList = () => {
  const {authors} = useContext(AuthorContext);
  const perPage = 7;
  const [showFilters, setShowFilters] = useState(false);

  const { Text, Title } = Typography;
  const [total, setTotal] = useState();
  const [add, setAdd] = useState(false);
  const [data, setData] = useState();
  const screens = useBreakpoint();
  const {filters, setFilters} = useContext(EmailSenderContext)
  const handleDataAdded = (addedData) => {
    setTotal(total + 1);
    if (data.length < perPage) {
      setData([...data, addedData]);
    }
  };

  return (
    <>
      <CreateSenderModal
        isOpen={add}
        setIsOpen={setAdd}
        dataAdded={handleDataAdded}
      />

      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <SenderStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Email Senders
              </Title>

              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { flexBasis: "100%", gap: "10px" } : {}),
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  className="flex-button new-buttons"
                  onClick={() => setShowFilters(!showFilters)}
                  block={screens.xs}
                >
                  Filter
                  {!showFilters ? (
                    <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
                  ) : (
                    <ButtonIcon
                      background={"#5a6bc5"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  onClick={() => setAdd(true)}
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="flex-button new-buttons"
                >
                  New sender
                  <ButtonIcon
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: "24px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <EmailSenderAuthorSelect />
              </Col>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <EmailSenderConfirmationSelect />
              </Col>

              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <EmailSenderDefaultSelect />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <EmailSenderCreatedDateFromSelect />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <EmailSenderCreatedDateToFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
          <EmailSenderOrderBySelect />
          <EmailSenderOrderDirectionSelect />
            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <SenderTable
          data={data}
          setData={setData}
          total={total}
          setTotal={setTotal}
        />
      </Space>
    </>
  );
};

export default SendersList;
