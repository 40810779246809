import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import { DatePicker, Flex, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";

const DateFromProductFilter = () => {
  const { filters, setFilters } = useContext(ProductListContext);
  const { Text } = Typography;

  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>From</Text>
        <DatePicker
          size="large"
          placeholder="Select created date start"
          defaultValue={filters?.from}
          value={filters?.from}
          className="dates-filter-select"
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              from: e,
            }))
          }
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
        />
      </Flex>
    </>
  );
};

export default DateFromProductFilter;
