import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { CiTextAlignLeft } from "react-icons/ci";
import { HiOutlineTemplate } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const CreateTemplateModal = ({ open, setOpen, id }) => {
    const { Title, Text } = Typography
    const navigate = useNavigate();
  
    return (
      <>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          title={
            <Title style={{ margin: 0 }} level={4}>
              What template do you want to create?
            </Title>
          }
        >
          <Space direction="vertical" className="w-100" size={"large"}>
            <Text style={{ fontSize: 16 }}>
              Choose what kind of template you want to create from scratch and
              reuse it whenever you need it.
            </Text>
            <Row gutter={12}>
              <Col md={12}>
                <Button onClick={() => navigate(`/Templates/CreateSimple${id ? `/${id}` : ''}`)}
                  size="large"
                  icon={<CiTextAlignLeft />}
                  block
                  className="flex-button bold-button"
                  style={{height:50}}
                >
                  Simple & Personalized
                </Button>
              </Col>
              <Col md={12}>
                <Button onClick={() => navigate(`/Templates/Create${id ? `/${id}` : ''}`)}
                  size="large"
                  icon={<HiOutlineTemplate />}
                  block
                  className="flex-button bold-button"
                  style={{height:50}}
                >
                  Advanced & Templated
                </Button>
              </Col>
            </Row>
          </Space>
        </Modal>
      </>
    );
  };

  export default CreateTemplateModal