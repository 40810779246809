import { useContext } from "react";
import { EmailTemplateGroupContext } from "../EmailTemplateGroupContext";
import { Segmented, Select, Typography } from "antd";
import { OrderDirection } from "../../../../shared/enums";

const EmailTemplateOrderDirection = () => {

  const { filters, setFilters } = useContext(EmailTemplateGroupContext);

  return (
    <>
      <Select
        size="large"
        style={{ width: "150px" }}
        className="order-by-select"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            label: "A - Z",
          },
          {
            value: OrderDirection.Dsc,
            label: "Z - A",
          },
        ]}
      />
    </>
  );
};

export default EmailTemplateOrderDirection;
