import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect } from "react";
import { CreateFollowupContext } from "./CreateFollowupContext";
import {
  StepExecutionConditionBridge,
  StepExecutionType,
} from "../../../shared/enums";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";

const CreateFollowupStepDetails = () => {
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const navigate = useNavigate();

  const options = [
    {
      label: "User signed up",
      value: StepExecutionType.UserSignedUp,
    },
    {
      label: "User subscribed",
      value: StepExecutionType.UserSubscribed,
    },
    {
      label: "User un-subscribed",
      value: StepExecutionType.UserUnsubscribed,
    },
    {
      label: "User removed account",
      value: StepExecutionType.UserDeleted,
    },
    {
      label: "Trial exceded",
      value: StepExecutionType.TrialExceded,
    },
  ];

  const { Text, Title } = Typography;
  const {
    details,
    setDetails,
    setStep,
    senders,
    emailTemplate,
    notificationTemplate,
  } = useContext(CreateFollowupContext);

  const [form] = Form.useForm();

  const onSubmit = async (e) => {
    setDetails(e);

    let response = await axios.post("/Followup/Step", {
      name: e.name,
      description: e.description,
      followupId: id,
      templateGroupId: emailTemplate,
      notificationTemplateGroupId: notificationTemplate,
      executionCondition: e.condition,
      executeAfterDays: e.days,
      bridge: e.bridge,
      senderId: e.senderId,
    });

    if (response.data.success) {
      navigate(`/Followup/details/${id}`);
    } else {
      message.error(response.data.message);
    }

    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    form.setFieldsValue(details);
  }, []);

  const bridgeOptions = [
    {
      label: "And",
      value: StepExecutionConditionBridge.And,
    },
    {
      label: "Or",
      value: StepExecutionConditionBridge.Or,
    },
    {
      label: "Not",
      value: StepExecutionConditionBridge.Not,
    },
  ];
  return (
    <Space className="w-100" direction="vertical" size={24}>
      <Card size="small">
        <Title level={4} style={{ margin: 0 }}>
          Follow step details
        </Title>
      </Card>
      <Card size="small">
        <Form form={form} onFinish={onSubmit}>
          <Space direction="vertical" className="w-100" size={24}>
            <Space direction="vertical" style={{ width: "500px" }} size={0}>
              <Text>Name</Text>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Plase provide a name for your step",
                  },
                ]}
              >
                <Input placeholder="Enter step name" size="large" />
              </Form.Item>
            </Space>

            <Space direction="vertical" className="w-100" size={0}>
              <Text>Description</Text>
              <Form.Item name="description">
                <TextArea
                  rows={3}
                  placeholder="Enter step description"
                  size="large"
                />
              </Form.Item>
            </Space>

            <Space>
              <Space direction="vertical">
                <Text>Days</Text>
                <Form.Item
                  name="days"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber size="large" placeholder="Execution days" />
                </Form.Item>
              </Space>
              <Space direction="vertical">
                <Text>Bridge</Text>
                <Form.Item name="bridge">
                  <Select
                    options={bridgeOptions}
                    size="large"
                    style={{ width: "100px" }}
                  />
                </Form.Item>
              </Space>

              <Space direction="vertical">
                <Text>Executions condition</Text>
                <Form.Item name="condition">
                  <Select
                    style={{ width: "250px" }}
                    options={options}
                    size="large"
                    placeholder="Execution condition"
                  />
                </Form.Item>
              </Space>
            </Space>

            <Space direction="vertical">
              <Text>Sender</Text>

              <Form.Item
                name="senderId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={senders}
                  style={{ width: 250 }}
                  size="large"
                  placeholder="Enter sender"
                />
              </Form.Item>
            </Space>
            <Flex align="center" justify="end" gap={6}>
              <Button>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save all changes
              </Button>
            </Flex>
          </Space>
        </Form>
      </Card>
    </Space>
  );
};

export default CreateFollowupStepDetails;
