import { Flex, Segmented, Select, Typography } from "antd";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../../shared/enums";
import { useContext, useState } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import { MdArrowDropDown } from "react-icons/md";

const EmailSenderOrderDirectionSelect = () => {
  const { Text } = Typography
  const { filters, setFilters } = useContext(EmailSenderContext)

  return <>
    <Select
      size="large"
      default={filters?.orderDirection}
      defaultValue={filters?.orderDirection}
      className="order-by-select"
      style={{width:"150px"}}
      suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
      onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
      options={[
        {
          value: OrderDirection.Asc,
          label: "A - Z",
        },
        {
          value: OrderDirection.Dsc,
          label: "Z - A",
        },
      ]}
    /></>
}

export default EmailSenderOrderDirectionSelect