import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import env from "../../../env.json";
import Audience from "./Audience";
import EmailStats from "./EmailStats";
import MoreDetails from "./MoreDetails";
import Optimizer from "./Optimizer";
import YourTemplateCard from "../../templates/Components/YourTemplateCard";
import Loading from "./Loading";
import { useReactToPrint } from "react-to-print";
import Responses from "./Responses";
import SecondPage from "./SecondPage";
import { DateFormat } from "../../../dateformat";
import InitilasAvatar from "../../../shared/IntialsAvatar";
import { FaRegClock } from "react-icons/fa";
import SmsResponses from "./SmsResponses";
import SmsStats from "./SmsStats";
const CampaignDetails = () => {
  const componentRef = useRef();
  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const { Title, Text } = Typography;

  const { id } = useParams();
  const axios = useAxiosPrivate();
  const [campaign, setCampaign] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(
      `/Campaign/GetFullCampaignDetails?Id=${id}`
    );
    setCampaign(response.data.data);
    setLoading(false);
  }, []);

  const handlePrint = () => {
    print();
  };

  const [currentKey, setCurrentKey] = useState("2");

  const items = [
    {
      key: "2",
      label: "Data",
    },
    {
      key: "3",
      label: "Optimizer & Suggestions",
      disabled: campaign?.type == 2,
    },
    {
      key: "4",
      label: "Audience",
    },
  ];

  const content = {
    left: (
      <Flex align="center" justify="space-between">
        <Title level={4} style={{ margin: 0, padding: 0 }}>
          {campaign?.name}
        </Title>
      </Flex>
    ),
    right: (
      <Space>
        <Tag bordered={false}>{campaign?.totalContacts ?? 0} Recipients</Tag>
        <Text className="icon-flex text-light">
          <FaRegClock />
          {new Date(campaign?.createdAt).toLocaleDateString(
            "en-US",
            DateFormat
          )}
        </Text>

        <InitilasAvatar
          name={campaign?.authorName}
          icon={<UserOutlined />}
          src={`${env.fileUpload}${campaign?.authorPath}`}
        />
      </Space>
    ),
  };

  return (
    <>
      {loading && <Loading />}

      {campaign && (
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <Space
              ref={componentRef}
              style={{ width: "100%" }}
              direction="vertical"
              size={24}
            >
              {campaign?.type == 0 && <EmailStats id={campaign.id} />}

              {campaign?.type == 2 && <SmsStats id={campaign.id} />}

              <Card>
                <Tabs
                  centered
                  tabBarExtraContent={content}
                  onChange={(e) => setCurrentKey(e)}
                  defaultActiveKey={currentKey}
                  items={items}
                />

                {currentKey == "2" && (
                  <SecondPage campaign={campaign} handlePrint={handlePrint} />
                )}
                {currentKey == "3" && <Optimizer id={campaign?.template?.id} />}
                {currentKey == "4" && (
                  <>
                    {campaign?.type == 0 && <Responses id={campaign.id} />}
                    {campaign?.type == 2 && <SmsResponses id={campaign.id} />}
                  </>
                )}
              </Card>
            </Space>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <div style={{ position: "sticky", top: 85 }}>
              {campaign.type == 0 && campaign.template && (
                <YourTemplateCard
                  template={campaign.template}
                  showAuthor
                  showDescription
                  preview
                />
              )}
              {campaign.type == 2 && campaign.smsTemplate && (
                <Card>
                  <Space
                    style={{ width: "100%" }}
                    size="large"
                    direction="vertical"
                  >
                    <Space direction="vertical" size={0}>
                      <Title style={{ margin: 0 }} level={5}>
                        {campaign?.smsTemplate?.name}
                      </Title>
                      <Text className="text-light">
                        {campaign?.smsTemplate?.text}
                      </Text>
                    </Space>

                    <Flex align="center" justify="space-between">
                      <InitilasAvatar
                        name={campaign?.smsTemplate?.authorName}
                        src={`${env.fileUpload}${campaign?.smsTemplate?.authorProfile}`}
                      />
                      <Text className="text-light icon-flex">
                        <FaRegClock />
                        {new Date(
                          campaign?.smsTemplate?.createdAt
                        ).toLocaleDateString("en-US", DateFormat)}
                      </Text>
                    </Flex>
                  </Space>
                </Card>
              )}
              {!campaign.template && !campaign.smsTemplate && (
                <Card style={{ height: "400px" }}>
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: "300px" }}
                    direction="vertical"
                  >
                    <Space
                      direction="vertical"
                      size={0}
                      style={{ textAlign: "center" }}
                    >
                      <Title level={4} style={{ margin: 0 }}>
                        Missing design
                      </Title>
                      <Text className="text-light">
                        The design might have been moved or deleted
                      </Text>
                    </Space>
                  </Flex>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CampaignDetails;
