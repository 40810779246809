import { CreateNotificationTemplateContextProvider } from "./CreateNotificationTemplateContext";
import CreateNotificationTemplateGroup from "./CreateNotificationTemplateGroup";

const CreateNotificationTemplateLayout = () => {

  return (
    <CreateNotificationTemplateContextProvider>
      <CreateNotificationTemplateGroup />
    </CreateNotificationTemplateContextProvider>
  );
};
export default CreateNotificationTemplateLayout;
