import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Pagination,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import NoDataFound from "../../../shared/NoDataFound";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ButtonIcon from "../../../shared/ButtonIcon";
import TableLoading from "../../../shared/TableLoading";
import FollowupStepOptions from "./FollowupStepOptions";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import UpdateFollowupStep from "./UpdateFollowupStep";
import { AiOutlineAccountBook } from "react-icons/ai";
import { StepExecutionConditionBridge, StepExecutionType } from "../../../shared/enums";

const CreateFollowUp = () => {
  const { Text, Title } = Typography;
  const { id } = useParams();

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [steps, setSteps] = useState();
  const [current, setCurrent] = useState();
  const [loading, setLoading] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState();

  useEffect(async () => {
    setLoading(true);
    let response = await axios.get(
      `/Followup/Step/List?FollowUpId=${id}&page=1&pageSize=999`
    );

    let followupResponse = await axios.get(`/Folloup/${id}`);

    form.setFieldValue("name", followupResponse.data.name);
    form.setFieldValue("description", followupResponse.data.description);

    setSteps(response.data.items);
    setLoading(false);
  }, []);

  const handleDeleteConfirmed = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(`Followup/Step/${item.id}`);

    if (response.data.success) {
      setSteps((prev) => prev.filter((d) => d.id != item.id));
    } else {
      message.error(response.data.message);
    }
  };

  const handleEnabledUpdate = async (id, enabled) => {
    let response = await axios.put("/Followup/Step/Enable", {
      id: id,
      enabled: enabled,
    });

    if (response.data.success) {
      setSteps((prev) =>
        prev.map((c) => (c.id == id ? { ...c, enabled: enabled } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState(false);
  const submit = async (e) => {
    setDataLoading(true);

    let response = await axios.put(`/Followup`, {
      id: id,
      name: e.name,
      description: e.description
    });

    if(!response.data.success){
       message.error(response.data.message);
    }

    setDataLoading(false)
  };

  return (
    <>
      <DeleteConfirmationModal
        item={current}
        onDeleteConfirm={handleDeleteConfirmed}
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      />

      {updateOpen && (
        <UpdateFollowupStep
          open={updateOpen}
          setOpen={setUpdateOpen}
          item={current}
          onUpdated={(item) => {
            setSteps((prev) => prev.map((c) => (c.id == item.id ? item : c)));
            setUpdateOpen(false);
          }}
        />
      )}

      <Form form={form} onFinish={submit}>
        <Row gutter={[24, 24]}>
          <Col xl={6} xxl={6} lg={6} md={24}>
            <Space direction="vertical" className="w-100">
              <Card size="small">
                <Title style={{ margin: 0 }} level={4}>
                  Follow up details
                </Title>
              </Card>
              <Card size="small">
                <Form form={form} onFinish={submit}>
                  <Space className="w-100" direction="vertical">
                    <Space className="w-100" direction="vertical" size={0}>
                      <Text>Name</Text>
                      <Form.Item name="name" rules={[{
                        required:true
                      }]}>

                      <Input size="large" placeholder="Enter name" />
                      </Form.Item>
                    </Space>

                    <Space className="w-100" direction="vertical" size={0}>
                      <Text>Description</Text>
                      <Form.Item name="description">

                      <TextArea
                        rows={5}
                        size="large"
                        placeholder="Enter description"
                        />
                        </Form.Item>
                    </Space>
                    <Flex align="center" justify="end">
                      <Button
                        type="primary"
                        loading={dataLoading}
                        onClick={() => form.submit()}
                      >
                        Save changes
                      </Button>
                    </Flex>
                  </Space>
                </Form>
              </Card>
            </Space>
          </Col>

          <Col xl={18} xxl={18} lg={18} md={24}>
            <Space className="w-100" direction="vertical">
              <Card size="small">
                <Flex align="center" justify="space-between">
                  <Title style={{ margin: 5 }} level={4}>
                    Steps
                  </Title>

                  <Button
                    type="primary"
                    size="large"
                    className="flex-button new-buttons"
                    onClick={() => navigate(`/Followup/details/${id}/step`)}
                  >
                    Create a new step
                    <ButtonIcon
                      icon={<FiPlus style={{ fontSize: 20 }} />}
                      background={"#5a6bc5"}
                    />
                  </Button>
                </Flex>
              </Card>

              <Card size="small">
                <Row>
                  <Col span={6}>
                    <Text className="column-header">Name</Text>
                  </Col>
                  <Col span={7}>
                    <Text className="column-header">Description</Text>
                  </Col>
                  <Col span={5}>
                    <Text className="column-header">Enabled</Text>
                  </Col>
                  <Col span={5}>
                    <Text className="column-header">Condition</Text>
                  </Col>
                  <Col span={1}>
                    <Text className="column-header">Tool</Text>
                  </Col>
                </Row>
              </Card>

              {loading ? (
                <TableLoading />
              ) : !steps || steps.length == 0 ? (
                <Card>
                  <NoDataFound
                    addText="New step"
                    onAdd={() => navigate("/followup/create/step")}
                    description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
                    title="You have not created any email campaigns yet"
                  />
                </Card>
              ) : (
                <>
                  {steps.map((c, i) => (
                    <Card key={i} size="small">
                      <Row>
                        <Col span={6} className="center-table-column">
                          <Text>{c.name}</Text>
                        </Col>
                        <Col span={7} className="center-table-column">
                          <Text>{c.description}</Text>
                        </Col>
                        <Col span={5} className="center-table-column">
                          <Switch
                            onChange={(e) => handleEnabledUpdate(c.id, e)}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={c.enabled}
                          />
                        </Col>
                        <Col span={5} className="center-table-column">
                          <Tag>{c.executeAfterDays} days</Tag>
                          {c.bridge == StepExecutionConditionBridge.And ? <Tag>And</Tag> : 
                            c.bridge == StepExecutionConditionBridge.Or ? <Tag>Or</Tag> : c.bridge == StepExecutionConditionBridge.Not ? <Tag>Not</Tag> : ""}

                            {c?.executionCondition == StepExecutionType.UserSignedUp ? <Tag>Sign up</Tag> 
                          : c?.executionCondition == StepExecutionType.UserSubscribed ? <Tag>Subscribe</Tag>
                          : c?.executionCondition == StepExecutionType.UserUnsubscribed ? <Tag>Unsubscribing</Tag> 
                          : c?.executionCondition == StepExecutionType.UserDeleted ? <Tag>Delete</Tag> : 
                          c?.executionCondition == StepExecutionType.TrialExceded ? <Tag>Trial exceded</Tag> : 
                          "" }
                        </Col>
                        <Col span={1} className="center-table-column">
                          <FollowupStepOptions
                            onUpdate={(d) => {
                              setCurrent(d);
                              setUpdateOpen(true);
                            }}
                            onDelete={(d) => {
                              setCurrent(d);
                              setDeleteOpen(true);
                            }}
                            id={c}
                          />
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateFollowUp;
