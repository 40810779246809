import Login from "./components/Authentication/Login";
import Home from "./pages/home/Home";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import Unauthorized from "./components/Authentication/Unauthorized";
import RequireAuth from "./components/Authentication/RequireAuth";
import PersistLogin from "./components/Authentication/PersistLogin";
import { Routes, Route } from "react-router-dom";
import CreateTemplate from "./pages/templates/Create/CreateTemplate";
import ListTemplates from "./pages/templates/List/ListTemplates";
import EditTemplate from "./pages/templates/Edit/EditTemplate";
import ListCampaigns from "./pages/campaigns/ListCampaigns";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import UserList from "./pages/users/UserManagement/UserList";
import CurrentUser from "./pages/users/UserManagement/CurrentUser";
import Security from "./pages/users/Auth/Security";
import MediaList from "./pages/media/MediaList";
import AudienceDetails from "./pages/audience/CRUD/AudienceDetails";
import CampaignDetails from "./pages/campaigns/Details/Details";
import SendersList from "./pages/users/Senders/SendersList";
import CreateSimpleTemplate from "./pages/templates/Create/CreateSimpleTemplate";
import EditSimpleEmail from "./pages/templates/Edit/EditSimpleEmail";
import ResetPassword from "./components/Authentication/ResetPassword";
import WhatsappSenderList from "./pages/users/Senders/whatsapp/WhatsAppSenderList";
import WhatsAppTemplateList from "./pages/templates/Whatsapp/WhatsappTemplateList";
import NewWhatsAppSender from "./pages/users/Senders/whatsapp/NewWhatsAppSender";
import CreateWhatsAppTemplate from "./pages/templates/Whatsapp/CreateWhatsAppTemplate";
import SignatureList from "./pages/templates/Signatures/SignatureList";
import DealsLayout from "./pages/deals/DealsLayout";
import DealDetails from "./pages/deals/DealDetails/DealDetails";
import DealsManagement from "./pages/deals/DealsManagement/DealsManagement";
import PoliciesWrapper from "./pages/policies/PoliciesWrapper";
import { Policy } from "./shared/enums";
import ProductList from "./pages/products/ProductList";
import CompanyList from "./pages/companies/CompanyList";
import SmsTemplates from "./pages/templates/Sms/SmsTemplates";
import SmsSenderList from "./pages/users/Senders/sms/SmsSenderList";
import NumberPriceList from "./pages/users/Senders/sms/NumberPriceList";
import NumberPaymentDone from "./pages/payments/PaymentDone";
import Payments from "./pages/payments/Payments";
import AudiencePaymentDone from "./pages/payments/AudiencePaymentDone";
import SubscriptionList from "./pages/subscriptions/SubscriptionList";
import ManagePrices from "./pages/prices/ManagePrices";
import ManageCoupons from "./pages/prices/ManageCoupons";
import CouponDetails from "./pages/prices/CouponDetails";
import CampaignLayout from "./pages/campaigns/CampaignLayout";
import CampaignListsLayout from "./pages/campaigns/CampaignListsLayout";
import CreateCampaign from "./pages/campaigns/CreateUpdate/CreateCampaign";
import MediaLayout from "./pages/media/MediaLayout";
import EmaiLSenderLayout from "./pages/users/Senders/EmailSenderLayout";
import ProductLayout from "./pages/products/ProductLayout";
import CompanyLayout from "./pages/companies/CompanyLayout";
import TaskLayout from "./pages/tasks/TaskLayout";
import TaskList from "./pages/tasks/TaskList";
import SmsLayout from "./pages/templates/Sms/SmsLayout";
import ApplicationLayout from "./components/ApplicationLayout";
import FullHeightApplicationLayout from "./components/FullHeightApplicationLayout";
import ApiProxyLayout from "./pages/apiproxy/ApiProxyLayout";
import RegisterLayout from "./components/Authentication/Register/UserSteps/RegisterLayout";
import FirstStep from "./components/Authentication/Register/UserSteps/FirstStep";
import SecondStep from './components/Authentication/Register/UserSteps/SecondStep'
import CompanyStepLayout from "./components/Authentication/Register/CompanySteps/CompanyStepLayout";
import RequireConfirmEmail from "./components/Authentication/RequireConfirmEmail";
import RequireLevel2Data from "./components/Authentication/RequireLevel2Data";
import FollowupLayout from "./pages/followup/FollowUpLayout";
import FollowupList from "./pages/followup/FollowupList";
import CreateFollowUp from "./pages/followup/create/CreateFollowup";
import CreateFollowupLayout from "./pages/followup/create/CreateFollowupLayout";
import NotificationTemplatesLayout from "./pages/templates/Notifications/NotificationTemplatesLayout";
import NotificationTemplatesList from "./pages/templates/Notifications/NotificationTemplatesList";
import CreateNotificationTemplateLayout from "./pages/templates/Notifications/Create/CreateNotificationTemplateLayout";
import NotificationTemplateDetails from "./pages/templates/Notifications/NotificationTemplateDetails";
import EmailTemplateGroupLayout from "./pages/templates/Emails/EmailTemplateGroupLayout";
import EmailTemplateGroupList from "./pages/templates/Emails/EmailTemplateGroupList";
import EmailTemplateGroupDetails from "./pages/templates/Emails/Details/EmailTemplateGroup";
import CreateFollowUpStepLayout from "./pages/followup/create/CreateFollowupStepLayout";
import ContactDetails from "./pages/audience/Details/ContantDetails";
import ContactLayout from "./pages/contacts/ContactLayout";
import ContactList from "./pages/contacts/ContactList";
import ImportContactLayout from "./pages/contacts/Import/ImportContactLayout";
import CreateTemplateLayout from "./pages/newemailtemplates/Create/CreateTemplateLayout";
import TemplateListLayout from "./pages/newemailtemplates/TemplateListLayout";
import TemplateList from "./pages/newemailtemplates/TemplateList";
import EmptyApplicationLayout from "./components/EmptyApplicationLayout";
import ImportTemplate from "./pages/newemailtemplates/ImportTemplate";

const ROLES = {
  ApplicationOwner: "ApplicationOwner",
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Moderator: "Moderator",
  Basic: "Basic",
};

function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<Layout />}>

            <Route path="login" element={<Login />} />
            <Route path="register" element={<RegisterLayout />} >
              <Route path="" element={<FirstStep />} />
            </Route>

            

            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="ResetPassword" element={<ResetPassword />} />

            <Route
              path="policy"
              element={<PoliciesWrapper view={Policy.LICENCE} />}
            />
            <Route
              path="policy/license"
              element={<PoliciesWrapper view={Policy.LICENCE} />}
            />
            <Route
              path="policy/terms-of-usage"
              element={<PoliciesWrapper view={Policy.TERMS} />}
            />
            <Route
              path="policy/prviacy-policy"
              element={<PoliciesWrapper view={Policy.PRIVACY} />}
            />
            <Route
              path="policy/affiliates"
              element={<PoliciesWrapper view={Policy.AFFILIATES} />}
            />

            <Route element={<PersistLogin />}>
            <Route path="confirm-email" element={<SecondStep />} />
            <Route path="create" element={<CompanyStepLayout />}>
            </Route>
                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.SuperAdmin, ROLES.Basic]} />
                  }
                >
              <Route element={<RequireConfirmEmail />}>
              <Route element={<RequireLevel2Data/>}>

                  <Route element={<EmptyApplicationLayout/>}>
                    <Route element={<ImportTemplate/>} path="/templates/email/import/:id"></Route>
                  </Route>
                  <Route element={<FullHeightApplicationLayout />}>
                  <Route
                        path="/Templates/CreateSimple/:id"
                        element={<CreateSimpleTemplate></CreateSimpleTemplate>}
                      />
                       <Route
                        path="/Templates/CreateSimple"
                        element={<CreateSimpleTemplate></CreateSimpleTemplate>}
                      />
                      <Route
                        path="/Templates/EditSimpleEmail/:id"
                        element={<EditSimpleEmail></EditSimpleEmail>}
                      />
                       <Route
                      path="/Templates/Create/:id"
                      element={<CreateTemplate></CreateTemplate>}
                    />
                     <Route
                      path="/Templates/Create"
                      element={<CreateTemplate></CreateTemplate>}
                    />
                    <Route
                      path="/Templates/Edit/:id"
                      element={<EditTemplate></EditTemplate>}
                    />

                   
                  </Route>

                  <Route element={<ApplicationLayout />}>
                    <Route
                      path="/Users"
                      allowedRoles={[ROLES.SuperAdmin]}
                      element={<UserList />}
                    />


                    <Route
                      element={
                        <RequireAuth allowedRoles={[ROLES.ApplicationOwner]} />
                      }
                    >
                      <Route
                        path="/Manage/Prices"
                        allowedRoles={[ROLES.ApplicationOwner]}
                        element={<ManagePrices />}
                      />
                    </Route>

                    <Route
                      path="/Manage/Coupons"
                      allowedRoles={[ROLES.ApplicationOwner]}
                      element={<ManageCoupons />}
                    />
                    <Route
                      path="/Manage/Coupon/:id"
                      element={<CouponDetails />}
                      allowedRoles={[ROLES.ApplicationOwner]}
                    />

                    <Route
                      element={
                        <RequireAuth allowedRoles={[ROLES.Basic, ROLES.SuperAdmin]} />
                      }
                    >

                      <Route path="/senders" element={<EmaiLSenderLayout />}>
                        <Route path="" element={<SendersList />} />
                      </Route>
                      <Route
                        path="/senders/whatsapp"
                        element={<WhatsappSenderList />}
                      />
                      <Route path="/senders/sms" element={<SmsSenderList />} />

                      <Route path="/" element={<Home />} />

                      <Route path="/audience" element={<ContactLayout />}>
                        <Route path="/audience" element={<ContactList />} />
                        <Route
                          path="/audience/details/:id"
                          element={<AudienceDetails />}
                        />
                        <Route path="/audience/import" element={<ImportContactLayout/>}>
                        
                        </Route>
                      <Route path="/audience/contact/:id" element={<ContactDetails/>}/>
                      </Route>

                      <Route path="/Media" element={<MediaLayout />}>
                        <Route path="" element={<MediaList />} />
                      </Route>

                      <Route path="/Subscriptions" element={<SubscriptionList />} />
                      <Route
                        path="/Templates/Create/Whatsapp"
                        element={<CreateWhatsAppTemplate />}
                      />
                      <Route
                        path="/Templates/Edit/Whatsapp/:id"
                        element={<CreateWhatsAppTemplate />}
                      />

                      <Route
                        path="/Templates/Whatsapp"
                        element={<WhatsAppTemplateList />}
                      />
                      <Route
                        path="/Templates/NewWhatsAppSender"
                        element={<NewWhatsAppSender />}
                      ></Route>


                      <Route  path="/Templates/Email" element={<EmailTemplateGroupLayout/>}>
                        <Route path="Details/:id" element={<EmailTemplateGroupDetails/>}/>
                        <Route path="Create" element={<CreateTemplateLayout/>}/>
                        <Route path="" element={<TemplateListLayout/>}>
                        <Route path="" element={<TemplateList/>}/>
                        </Route>
                      </Route> 

                      <Route path="/Templates/Sms" element={<SmsLayout />} >
                        <Route path="" element={<SmsTemplates />}></Route>
                      </Route>

                      <Route path="/Templates/Notification" element={<NotificationTemplatesLayout/>}>
                        <Route path="create" element={<CreateNotificationTemplateLayout/>}/>
                        <Route path="details/:id" element={<NotificationTemplateDetails/>}/>
                        <Route path="" element={<NotificationTemplatesList/>}/> 

                      </Route>
                      

                      <Route
                        path="/Templates/SignatureList"
                        element={<SignatureList />}
                      />

                      <Route
                        path="/Templates/Yours"
                        element={<ListTemplates type={0}></ListTemplates>}
                      />
                      <Route
                        path="/Templates/Tapzap"
                        element={<ListTemplates type={1}></ListTemplates>}
                      />

                      <Route path="/price/list/:ios" element={<NumberPriceList />} />
                      <Route
                        path="/price/list/:ios/:areaCode"
                        element={<NumberPriceList />}
                      />

                      <Route
                        path="/number/payment/done/:ios"
                        element={<NumberPaymentDone />}
                      />
                      <Route
                        path="/number/payment/done/:ios/:areaCode"
                        element={<NumberPaymentDone />}
                      />
                      <Route
                        path="/audience/payment/done/:type/:amount/:price"
                        element={<AudiencePaymentDone />}
                      />

                      <Route
                        path="/balance/:type/:amount/:price"
                        element={<Payments />}
                      />

                      <Route path="/Campaigns" element={<CampaignLayout />}>

                        <Route path="" element={<CampaignListsLayout />}>
                          <Route path=""
                            element={<ListCampaigns></ListCampaigns>}
                          />
                        </Route>

                        <Route
                          path="Create/:type"
                          element={<CreateCampaign></CreateCampaign>}
                        />
                        <Route
                          path="Edit/:id/:type"
                          element={<CreateCampaign></CreateCampaign>}
                        />


                        <Route path="Details/:id" element={<CampaignDetails />} />
                      </Route>

                      <Route path="Followup" element={<FollowupLayout/>}>
                        <Route path="details/:id" element={<CreateFollowupLayout/>}>
                          <Route path="step" element={<CreateFollowUpStepLayout/>}></Route>
                          <Route path="" element={<CreateFollowUp/>}></Route>
                        </Route>
                        <Route path="" element={<FollowupList/>}></Route>
                      </Route>
                      
                      <Route path="/Deals" element={<DealsLayout />}>
                        <Route path={"Management"} element={<DealsManagement />} />
                        <Route path={"List"}></Route>
                        <Route path="Details/:id" element={<DealDetails />}></Route>

                        <Route path="Tasks" element={<TaskLayout />}>
                          <Route path="" element={<TaskList />} />
                        </Route>

                        <Route path="Products" element={<ProductLayout />}>
                          <Route path="" element={<ProductList />} />
                        </Route>

                        <Route path="Companies" element={<CompanyLayout />}>

                          <Route path="" element={<CompanyList />}></Route>
                        </Route>
                      </Route>
                      <Route path="/Users/profile" element={<CurrentUser />} />

                      <Route path="/Users/Security" element={<Security />} />

                      <Route path="/settings" element={<ApiProxyLayout />}>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              </Route>
            </Route>

            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
    </>
  );
}

export default App;
