import { Button, Drawer, Flex, Form, Input, message, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateCommentModal = ({ open, setOpen, id, onAdded}) => {
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const axios = useAxiosPrivate();

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post('/Email-Comment', {
        emailId: id,
        comment: e.comment
    })

    if(response.data.success){
        onAdded(response.data)
        form.resetFields();
        setOpen(false); 
    }else{  
        message.error(response.data.message)
    }

    setLoading(false);
  };

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title="Create comment">
        <Flex
          style={{ height: "100%" }}
          vertical
          align="start"
          justify="space-between"
        >
          <Form className="w-100" form={form} onFinish={submit}>
            <Space className="w-100" size={0} direction="vertical">
              <Text>Comment</Text>
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 420,
                  },
                  {
                    min: 3,
                  },
                ]}
              >
                <TextArea
                    placeholder="Enter comment"
                  size="large"
                  rows={4}
                  count={{
                    show: true,
                    max: 420,
                  }}
                ></TextArea>
              </Form.Item>
            </Space>
          </Form>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button
              className="flex-button new-buttons"
              onClick={() => setOpen(false)}
            >
              Cancel
              <ButtonIcon
                icon={<MdCancel style={{ fontSize: 16 }} />}
                background={"#fff"}
              />
            </Button>
            <Button
              loading={loading}
              onClick={() => form.submit()}
              className="flex-button new-buttons"
              type="primary"
            >
              Save changes
              <ButtonIcon
                icon={<FaCheck style={{ fontSize: 14 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default CreateCommentModal;
