import { Card, Flex, Form, Input, Space, Typography } from "antd";
import SmartText from "../../../../components/SmartText";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";

const CreateNotificationTemplate = ({ language, languageId, languageName, form}) => {
  const { Text, Title } = Typography;

  const [header, setHeader] = useState();
  const [message, setMessage] = useState();

  return (<Card style={{boxShadow:"none", marginTop:-16, borderTop:"none", borderTopRightRadius:0, borderTopLeftRadius:0}}>

    <Space className="w-100" direction="vertical">
      <Title style={{margin:5}} level={4}>{languageName} desing</Title>
      <Space direction="vertical" className="w-100" size={0}>
        <Text>Header</Text>
        <Form.Item
          name={["templates", language, "header"]}
          rules={[
            {
              required: true,
              message: "Plase enter the header",
            },
          ]}
        >
          <Input
            size="large"
            onChange={(e) => setHeader(e.target.value)}
            placeholder="Enter template header"
            suffix={<SmartText text={header} setText={(e) => {
              setHeader(e);
              form.setFieldsValue({
                templates: {
                  [language]: {
                    header: e,
                  },
                },
              });
            }}/>}
          />
        </Form.Item>
      </Space>

      <Space direction="vertical" className="w-100" size={0}>
        <Text>Url</Text>
        <Form.Item name={["templates", language, "url"]}>
          <Input size="large" placeholder="Enter url" />
        </Form.Item>
      </Space>

      <Space direction="vertical" className="w-100" size={6}>
        <Flex align="center" justify="space-between">
          <Text>Message</Text>
          <SmartText text={message} setText={(e) => {
            setMessage(e);
            form.setFieldsValue({
              templates: {
                [language]: {
                  template: e,
                },
              },
            });
          }}/>
        </Flex>
        <Form.Item
          name={["templates", language, "template"]}
          rules={[
            {
              required: true,
              message: "Plase enter your template",
            },
          ]}
        >
          <TextArea onChange={(e) => setMessage(e.target.value)} rows={10} size="large" placeholder="Enter template" />
        </Form.Item>
      </Space>

      <Form.Item
        name={["templates", language, "language"]}
        initialValue={languageId}
      >
        <Input type="hidden"/>
      </Form.Item>
    </Space>
  </Card>

  );
};

export default CreateNotificationTemplate;
