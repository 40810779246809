import { Button, Card, Flex, Space, Tabs, Typography } from "antd";
import "./CreateTemplate.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CreateYourOwn from "./CreateYourOwn";
import MyTemplates from "./MyTemplates";
import Layouts from "./Layouts";
import Gallery from "./Gallery";
import CampaignTemplates from "./CampaignTemplates";
import PreviewTemplateModal from "../PreviewTemplateModal";
import { useState } from "react";

const CreateTemplateLayout = () => {
  const { Title } = Typography;

  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentPreview, setCurrentPreivew] = useState();

  const handleOpenPreview = (id, type) => {
    setCurrentPreivew({ id, type });
    setPreviewOpen(true);
  };
  return (
    <>
      <PreviewTemplateModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={currentPreview?.id}
        type={currentPreview?.type}
      />
      <Space direction="vertical" className="w-100" size={24}>
        <Card size="small">
          <Flex align="center" justify="start" gap={6}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>
            <Title className="zero-margin-padding" level={4}>
              Desing your email
            </Title>
          </Flex>
        </Card>

        <Card size="small">
          <Flex justify="center">
            <Tabs
              centered
              className="w-100"
              size="large"
              items={[
                {
                  key: 1,
                  label: "Layouts",
                  children: (
                    <Flex justify="center">
                      {" "}
                      <Layouts openPreview={handleOpenPreview} />
                    </Flex>
                  ),
                },
                {
                  key: 2,
                  label: "Template gallery",
                  children: (
                    <Flex justify="center">
                      <Gallery openPreview={handleOpenPreview} />
                    </Flex>
                  ),
                },
                {
                  key: 3,
                  label: "My templates",
                  children: (
                    <Flex justify="center">
                      <MyTemplates openPreview={handleOpenPreview} />
                    </Flex>
                  ),
                },
                {
                  key: 4,
                  label: "Campaigns",
                  children: (
                    <Flex justify="center">
                      {" "}
                      <CampaignTemplates openPreview={handleOpenPreview} />
                    </Flex>
                  ),
                },
                {
                  key: 5,
                  label: "Create your own",
                  children: (
                    <Flex justify="center">
                      <CreateYourOwn />
                    </Flex>
                  ),
                },
              ]}
            ></Tabs>
          </Flex>
        </Card>
      </Space>
    </>
  );
};

export default CreateTemplateLayout;
