import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { FaEye, FaShareAlt } from "react-icons/fa";
import { IoDuplicateSharp, IoEllipsisHorizontal } from "react-icons/io5";
import "./EmailTemplateOptions.css"

const EmailTemplateOptions = ({ id, simple, onShare, onDelete, handleUpdate, openPreview, duplicate }) => {
  const items = [
    {
      key: "1",
      label: <p className="template-options-text">Edit selected design</p>,
      icon: <AiFillEdit className="template-options-icon" />,
      onClick: () => handleUpdate(id, simple)
    },
    {
      key: "3",
      label: <p className="template-options-text">Duplicate this design</p>,
      icon: <IoDuplicateSharp className="template-options-icon" />,
      onClick: () => duplicate(id)
    },
    {
        key: "5",
        label: <p className="template-options-text">Preview selected design</p>,
        icon: <FaEye className="template-options-icon" />,
        onClick: () => openPreview(id, simple)
      },
    {
      key: "4",
      onClick: () => onShare(id),
      label: <p className="template-options-text">Share this design</p>,
      icon: <FaShareAlt className="template-options-icon" />,
    },
    {
      key: "2",
      label: <p className="template-options-text">Delete selected design</p>,
      danger: true,
      onClick: () => onDelete(id),
      icon: <BsFillTrashFill />,
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          shape="circle"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal style={{ fontSize: 26, color: "#b1b1b0" }} />
          }
        ></Button>
      </Dropdown>
    </>
  );
};

export default EmailTemplateOptions;
