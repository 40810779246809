import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SearchContext } from "../../../context/SearchContext";
import { OrderDirection } from "../../../shared/enums";
import QueryString from "qs";
import { AuthorContext } from "../../../context/AuthorsContext";


export const NotificationTemplatesContext = createContext({
  filters: {
    selectedAuthors: [],

    from: null,
    to: null,

    orderBy: "",
    orderDirection: "",
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    authorName: null,
    authorProfile: null,
  },
  setStats: () => {},

  groups: [],
  setGroups: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},
});

export const NotificationTemplatesContextProvider = ({ children }) => {
  const {you} = useContext(AuthorContext)
  const axios = useAxiosPrivate();
  const {debouncedValue} = useContext(SearchContext)
  const [filters, setFilters] = useState(() => ({
    orderDirection: OrderDirection.Dsc,
    orderBy: "Created",
    selectedAuthors: [you]
  }));
  const [stats, setStats] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState();

  const perPage = 7;

  useEffect(async () => {
    setLoading(true)
    const response = await axios.get("/Notification/Templates/Group/List", {params: {
      search:debouncedValue,
      orderBy: filters?.orderBy,
      orderDirection: filters?.orderDirection,
      authors: filters?.selectedAuthors,
      from: filters?.from ? new Date(filters?.from) : null,
      to: filters?.to ? new Date(filters?.to) : null,
      page: page,
      pageSize:perPage
    },  paramsSerializer: params => {
      return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
    }});
    setGroups(response.data.items)

    setStats(prev => ({
      total: response.data.total,
      first: response.data.first,
      last: response.data.last,
      authorName: response.data.authorName,
      authorProfile: response.data.authorProfile
    }))

    setLoading(false)
  }, [debouncedValue, filters, page])

  return (
    <NotificationTemplatesContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        page,
        setPage,

        loading,
        setLoading,

        groups,
        setGroups
      }}
    >
      {children}
    </NotificationTemplatesContext.Provider>
  );
};
