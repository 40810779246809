import { EmailTemplateGroupContextProvider } from "../../templates/Emails/EmailTemplateGroupContext";
import { NotificationTemplatesContextProvider } from "../../templates/Notifications/NotificationTemplatesContext";
import { CreateFollowupContextProvider } from "./CreateFollowupContext";
import CreateNewStep from "./CreateNewStep";

const CreateFollowUpStepLayout = () => {
  return (
    <>
      <NotificationTemplatesContextProvider>
        <EmailTemplateGroupContextProvider>
          <CreateFollowupContextProvider>
            <CreateNewStep />
          </CreateFollowupContextProvider>
        </EmailTemplateGroupContextProvider>
      </NotificationTemplatesContextProvider>
    </>
  );
};

export default CreateFollowUpStepLayout;
