import { Flex, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { BsCalendarDateFill } from "react-icons/bs";
import { MdTitle } from "react-icons/md";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";
import OrderBySelect from "../../../../shared/OrderBySelect";

const NotificationTemplatesOrderByFilter = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(NotificationTemplatesContext);

  const options = [
    {
      value: "created",
      label: "Template created date"
    },
    {
      value: "name",
      label: "Template name"
    },
  ];

  const handleChanged = (e) => {
    setFilters((prev) => ({ ...prev, orderBy: e }));
  };
  return (
    <OrderBySelect
      options={options}
      handleChanged={handleChanged}
      defaultValue={filters?.orderBy}
    />
  );
};

export default NotificationTemplatesOrderByFilter;
