import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SmartText from "../../../components/SmartText";
import TextArea from "antd/es/input/TextArea";

const EditNotificationTemplateModal = ({ open, setOpen, item, onEdit}) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const [header, setHeader] = useState("");
  const [messageTemplate, setMessageTemplate] = useState()

  useEffect(() => {
    setHeader(item?.header)
    form.setFieldValue("header", item?.header);
    form.setFieldValue("url", item?.url);
    form.setFieldValue("template", item?.template);
  }, [item]);

  const submit = async (e) => {
    setLoading(true);

    let response = await axios.put(`/Notification/Notification-Template`, {
      id: item.id,
      template: e.template,
      header: e.header,
      url: e.url,
    });

    if (response.data.success) {
      onEdit({
        id: item.id,
        ...e
      })
        setOpen(false);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Edit notification template
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Space className="w-100" direction="vertical" size={0}>
          <Text>Header</Text>
          <Form.Item
            name={"header"}
            rules={[
              {
                required: true,
                message: "Plase enter the header",
              },
            ]}
          >
            <Input
              size="large"
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              placeholder="Enter template header"
              suffix={<SmartText text={header} setText={(e) => {
                setHeader(e)
                form.setFieldValue("header", e)
              }}/>}
            />
          </Form.Item>
        </Space>
        <Space direction="vertical" className="w-100" size={0}>
          <Text>Url</Text>
          <Form.Item name={"url"}>
            <Input size="large" placeholder="Enter url" />
          </Form.Item>
        </Space>
        <Space direction="vertical" className="w-100" size={6}>
          <Flex align="center" justify="space-between">
            <Text>Message</Text>
            <SmartText text={messageTemplate} setText={(e) => {
              setMessageTemplate(e);
              form.setFieldValue("template", e)
            }}/>
          </Flex>
          <Form.Item
            name={"template"}
            rules={[
              {
                required: true,
                message: "Plase enter your template",
              },
            ]}
          >
            <TextArea onChange={(e) => setMessageTemplate(e.target.value)} rows={4} size="large" placeholder="Enter template" />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditNotificationTemplateModal;
