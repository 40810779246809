import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { CardStatus, DealCardItems } from "../../shared/enums";
import IconSelectListItem from "../../shared/IconSelectListItem";
import { PiBuildingsFill } from "react-icons/pi";
import QueryString from "qs";

export const DealListContext = createContext({
  filters: {
    shown: false,
    authors: [],
    selectedAuthors: [],
    cardItems: {
      [DealCardItems.Contact]: true,
      [DealCardItems.Amount]: true,
      [DealCardItems.CreatedDate]: true,
      [DealCardItems.Products]: true,
      [DealCardItems.Author]: true,
      [DealCardItems.Companies]: false,
      [DealCardItems.ClosedDate]: false,
    },
    companies: [],
    selectedCompanies: [],
    products: [],
    selectedProducts: [],
    statuses: [],
    selectedStatuses:[],
    createdFromDate: null,
    createdToDate: null,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    new: 0,
    qualifying: 0,
    demoScheduled: 0,
    pendingCommitment: 0,
    inNegotiation: 0,
    won: 0,
    lost: 0,
  },
  setStats: () => {},

  refreshStats: () => {},
});

export const DealListContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();

  const [filters, setFilters] = useState({
    shown: false,
    authors: [],
    selectedAuthors: [],
    cardItems: {
      [DealCardItems.Contact]: true,
      [DealCardItems.Amount]: true,
      [DealCardItems.CreatedDate]: true,
      [DealCardItems.Products]: true,
      [DealCardItems.Author]: true,
      [DealCardItems.Companies]: false,
      [DealCardItems.ClosedDate]: false,
    },
    companies: [],
    selectedCompanies: [],
    products: [],
    selectedProducts: [],
    statuses: [],
    selectedStatuses:[],
    createdFromDate: null,
    createdToDate: null,
  });

  const [stats, setStats] = useState();

  const refreshStats = async () => {
    const statsResponse = await axios.post("/Deal/stats", {
      from: filters.createdFromDate,
      to: filters.createdToDate,
      productIds: filters.selectedProducts,
      companyIds: filters.selectedCompanies
    });

    setStats((prev) => ({
      total: statsResponse.data.total,
      new: statsResponse.data.new,
      qualifying: statsResponse.data.qualifying,
      demoScheduled: statsResponse.data.demoScheduled,
      pendingCommitment: statsResponse.data.pendingCommitment,
      inNegotiation: statsResponse.data.inNegotiation,
      won: statsResponse.data.won,
      lost: statsResponse.data.lost,
    }));
  }
  
  useEffect(async () => {
    const authorResponse = await axios.get(`Users/GetAuthors`);
    const companiesResponse = await axios.get(`/Companies/List`, {
      params:{
        page: 1,
        pageSize: 999999,
        from: null,
        to: null
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
        });
      },
    });
    const productsResponse = await axios.post("/Product/list", {
      page: 1,
      pageSize:999999
    });
    
    await refreshStats();

    setFilters((prev) => ({
      ...prev,
      cardItems: {
        [DealCardItems.Contact]: true,
        [DealCardItems.Amount]: true,
        [DealCardItems.CreatedDate]: true,
        [DealCardItems.Products]: true,
        [DealCardItems.Author]: true,
        [DealCardItems.Companies]: false,
        [DealCardItems.ClosedDate]: false,
      },
      selectedStatuses: [
        {
          title: "New",
          value: CardStatus.New,
          id: CardStatus.New,
        },
        {
          title: "Qualifying",
          value: CardStatus.Qualifying,
          id: CardStatus.Qualifying,
        },
        {
          title: "Demo Scheduled",
          value: CardStatus.DemoScheduled,
          id: CardStatus.DemoScheduled,
        },
        {
          title: "Won",
          value: CardStatus.Won,
          id: CardStatus.Won,
        },
        {
          title: "Closed",
          value: CardStatus.Closed,
          id: CardStatus.Closed,
        },
      ],
      statuses:[
        {
          title: "New",
          value: CardStatus.New,
          id: CardStatus.New,
        },
        {
          title: "Qualifying",
          value: CardStatus.Qualifying,
          id: CardStatus.Qualifying,
        },
        {
          title: "Demo Scheduled",
          value: CardStatus.DemoScheduled,
          id: CardStatus.DemoScheduled,
        },
        {
          title: "Pending Commitment",
          value: CardStatus.PendingCommitment,
          id: CardStatus.PendingCommitment,
        },
        {
          title: "In Negotiation",
          value: CardStatus.InNegotiation,
          id: CardStatus.InNegotiation,
        },
        {
          title: "Won",
          value: CardStatus.Won,
          id: CardStatus.Won,
        },
        {
          title: "Closed",
          value: CardStatus.Closed,
          id: CardStatus.Closed,
        },
      ],
      selectedAuthors: [authorResponse.data.yourId],
      authors: authorResponse.data.data.map((c) => ({
        value: c.id,
        label: (
          <AuthorSelectListItem
            profile={c.profileImagePath}
            firstname={c.firstname}
            lastname={c.lastname}
          />
        ),
      })),
      products: productsResponse.data.items.map((c) => ({
        value: c.id,
        label: <IconSelectListItem text={c.name} />,
      })),
      companies: companiesResponse.data.items.map((c) => ({
        value: c.id,
        label: <IconSelectListItem text={c.name} icon={<PiBuildingsFill />} />,
      })),
    }));
  }, []);

  useEffect(async () => {
    refreshStats();
  }, [filters])
  
  return (
    <DealListContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,
        
        refreshStats
      }}
    >
      {children}
    </DealListContext.Provider>
  );
};
