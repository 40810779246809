import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Select,
  Flex,
  Form,
  Input,
  Typography,
  Modal,
} from "antd";
import CreateSimpleEmail from "./CreateSimpleEmail";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FaCheck, FaRegSave } from "react-icons/fa";
import { MdCancel, MdOutlineClose } from "react-icons/md";
import { LocationContext } from "../../../context/LocationContext";
import { IoCaretBackSharp } from "react-icons/io5";
import { stateFromHTML } from "draft-js-import-html";

const CreateSimpleTemplate = () => {
  const { prevLocation } = useContext(LocationContext);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const regex = /^\/Campaigns\/Edit\/\d+\/\d*$/;

    if (regex.test(prevLocation)) {
      setShowButton(true);
    }
  }, []);

  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { Title, Text } = Typography;
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const getHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  };

  const handleSave = async (e) => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Html", "");
    formData.append("Name", e.name);
    formData.append("Style", "");
    formData.append("IsSimple", true);
    formData.append("SimpleHtml", getHTML());
    if(id){
      formData.append("GroupId", id);
    }
    formData.append("LanguageId", e.language);

    var response = await axiosPrivate.post("/Template", formData);

    if (!response || !response.data.success) {
      return;
    }

    setLoading(false);

    if(showButton){
      navigate(-1)
    }else{
      navigate(`/Templates/Email`);
    }
  };

  useEffect(() => {

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchLanguages = async () => {
      const languagesResponse = await axiosPrivate.get("/Language", {
        signal:signal
      });
      setLanguages(
        languagesResponse.data.data.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      );
    }

    const fetchTemplate =async () => {
      const response = await axiosPrivate.get(`/Template/${id}`)
      const contentState = stateFromHTML(response.data.data.html);
      setEditorState(EditorState.createWithContent(contentState));
    }
    
    fetchTemplate();
    fetchLanguages();

    return () => controller.abort();
  }, []);

  const [saveOpen, setSaveOpen] = useState(false);
  return (
    <>
      <Form onFinish={handleSave} form={form}>
        <SaveModal
          loading={loading}
          open={saveOpen}
          form={form}
          setOpen={setSaveOpen}
          languages={languages}
        />
      </Form>

      <CreateSimpleEmail
        editorState={editorState}
        setEditorState={setEditorState}
      />

      <div
        style={{
          height: "60px",
          width: "100%",
        }}
      >
        <Flex align="center" justify="space-between"  style={{
              background: "#fff",
              height: "60px",
              borderTop: `1px solid #cbd5e0`,
            }}>
          {showButton ? (
            <Button
              className="flex-button new-buttons"
              size="large"
              style={{ marginLeft: 10 }}
              onClick={() => navigate(-1)}
            >
              Back to campaign
              <ButtonIcon
                background={"#eaeaf8"}
                icon={<IoCaretBackSharp style={{ fontSize: 18 }} />}
              />
            </Button>
          ) : <div></div>}
          <Flex
           
            align="center"
            justify="end"
            gap={6}
          >
            <Button
              className="flex-button new-buttons"
              size="large"
              onClick={() => navigate(-1)}
            >
              Cancel
              <ButtonIcon
                icon={<MdOutlineClose style={{ fontSize: 18 }} />}
                background={"#eaeaf8"}
              />
            </Button>
            <Button
              size="large"
              type="primary"
              className="flex-button new-buttons"
              onClick={() => setSaveOpen(true)}
              style={{ marginRight: 10 }}
            >
              Continue
              <ButtonIcon
                icon={<FaRegSave style={{ fontSize: 18 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

const SaveModal = ({ open, setOpen, languages, form, loading }) => {
  const { Text, Title } = Typography;

  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={3}>
          Save simple desing
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
            className="flex-button new-buttons"
            onClick={() => setOpen(false)}
          >
            Cancel{" "}
            <ButtonIcon
              icon={<MdCancel style={{ fontSize: 16 }} />}
              background={"#fff"}
            />
          </Button>
          <Button
            type="primary"
            className="flex-button new-buttons"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes{" "}
            <ButtonIcon
              icon={<FaCheck style={{ fontSize: 14 }} />}
              background={"#5a6bc5"}
            />
          </Button>
        </Flex>
      }
    >
      <Text>Name</Text>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "A name is required" },
          {
            max: 50,
            message: "Name should contain at most 20 characters",
          },
        ]}
      >
        <Input
          size="large"
          count={{
            show: true,
            max: 50,
          }}
          placeholder="Enter a name for your design"
          allowClear
        />
      </Form.Item>

      <Text>Languages</Text>
      <Form.Item
        name="language"
        rules={[{ required: true, message: "A language is required" }]}
      >
        <Select
          style={{ width: "100%" }}
          options={languages}
          size="large"
          placeholder="Select template language"
        />
      </Form.Item>
    </Modal>
  );
};
export default CreateSimpleTemplate;
