import {
  Button,
  Card,
  Flex,
  Space,
  Table,
  Typography,
  Grid,
  Row,
  Col,
  Avatar,
  Pagination,
} from "antd";
import { useContext, useEffect, useState } from "react";
import AddSmsTemplate from "./AddSmsTemplate";
import TableLoading from "../../../shared/TableLoading";
import SmsTemplateOptions from "./SmsTemplateOptions";
import { DateFormat } from "../../../dateformat";
import EditSmsTemplate from "./EditSmsTemplate";
import { FiPlus } from "react-icons/fi";
import SmsStats from "./SmsStats";
import NoDataFound from "../../../shared/NoDataFound";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import SmsAuthorFilter from "./Filters/SmsAuthorFilter";
import SmsFromDateFilter from "./Filters/SmsFromDateFilter";
import SmsToDateFilter from "./Filters/SmsToDateFilter";
import SmsOrderByFilter from "./Filters/SmsOrderByFilter";
import SmsOrderDirectionFilter from "./Filters/SmsOrderDirectionFilter";
import { SmsListContext } from "./SmsListContext";
import ButtonIcon from "../../../shared/ButtonIcon";
import { IoClose } from "react-icons/io5";
import { UserOutlined } from "@ant-design/icons";
import { AuthorContext } from "../../../context/AuthorsContext";
import env from "../../../env.json";
import { localeData } from "moment";

const { useBreakpoint } = Grid;

const SmsTemplates = () => {
  const {
    filters,
    setFilters,
    templates,
    setTemplates,
    loading,
    stats,
    setStats,
    page,
    setPage
  } = useContext(SmsListContext);

  const { authors } = useContext(AuthorContext);
  const { Title, Text } = Typography;

  const [showFilters, setShowFilters] = useState(false);

  const screens = useBreakpoint();

  const perPage = 7;
  const [showAdd, setShowAdd] = useState(() => false);

  const [showUpdate, setShowUpdate] = useState(() => false);
  const [current, setCurrent] = useState();

  const onAdd = (e) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setTemplates((prev) => [
      ...prev,
      {
        name: e.name,
        author: e.authorName,
        createdAt: e.createdAt,
        profile: e.authorProfile,
        text: e.text,
        id: e.id,
      },
    ]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setTemplates((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (e) => {
    setTemplates((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              name: e.name,
              author: e.authorName,
              createdAt: e.createdAt,
              profile: e.authorProfile,
              text: e.text,
              id: e.id,
            }
          : c
      )
    );
  };
  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <SmsStats />
        <Card
          className="no-body-card end-to-end-header zero-margin-padding"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Sms designs
              </Title>

              <Flex
                wrap="wrap"
                gap={10}
                style={{
                  ...(screens.xs ? { flexBasis: "100%", gap: "10px" } : {}),
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  block={screens.xs}
                  className="flex-button new-buttons"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filter
                  {!showFilters ? (
                    <ButtonIcon size="large" icon={<MdFilterAlt />} background={"#5a6bc5"} />
                  ) : (
                    <ButtonIcon
                      background={"#5a6bc5"}
                      size="large"
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="flex-button new-buttons"
                  onClick={() => setShowAdd(true)}
                >
                  New design
                  <ButtonIcon size="large"
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: "24px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <SmsAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <SmsFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <SmsToDateFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <SmsOrderByFilter />
            <SmsOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={5}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={10}>
                <Text className="column-header">Text</Text>
              </Col>
              <Col span={7}>
                <Text className="column-header">Date</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Tool</Text>
              </Col>
            </Row>
          </Card>
       

        {loading ? (
          <TableLoading />
        ) : !templates || templates?.length == 0 ? (
          <NoDataFound
            addText="New sms template"
            onAdd={() => setShowAdd(true)}
            description="Click the New Desing button on the Desings/Sms page to create a new sms desing"
            title="You have not created any sms desings yet"
          />
        ) : (
          <>
            {templates.map((c, i) => (
              <Card key={i} size="small">
                <Row gutter={[6, 6]}>
                  <Col className="center-table-column" span={5}>
                    <Text>{c.name}</Text>
                  </Col>
                  <Col className="center-table-column" span={10}>
                    <Text>{c.text}</Text>
                  </Col>
                  <Col className="center-table-column" span={7}>
                    <Text>
                      {new Date(c.createdAt).toLocaleDateString(
                        "en-US",
                        DateFormat
                      )}
                    </Text>
                  </Col>
                  <Col span={2}>
                    <Flex align="center" justify="end">
                      <SmsTemplateOptions
                        id={c.id}
                        onDelete={onDelete}
                        setCurrent={setCurrent}
                        setShowUpdate={setShowUpdate}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}
<Card size="small">
<Flex align="center" justify="end">

            <Pagination 
              total={stats?.total} 
              current={page}
              pageSize={perPage}
              onChange={(p) => setPage(p)}/>
              </Flex>
              </Card>
          </>
        )}
      </Space>
      </Space>
      <AddSmsTemplate open={showAdd} setOpen={setShowAdd} onSubmit={onAdd} />

      {showUpdate && (
        <EditSmsTemplate
          open={showUpdate}
          setOpen={setShowUpdate}
          onUpdate={onUpdate}
          id={current}
        />
      )}
    </>
  );
};
export default SmsTemplates;
