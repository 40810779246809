import { useContext } from "react";
import { AudienceListContext } from "../AudienceListContext";
import OrderBySelect from "../../../shared/OrderBySelect";

const AudienceOrderByFilter = () => {
  const { filters, setFilters } = useContext(AudienceListContext);

  const options = [
    {
      value: "created",
      label: "Date created",
    },
    {
      value: "name",
      label: "Name",
    },
  ];

  const handleChanged = (e) => {
    setFilters((prev) => ({ ...prev, orderBy: e }));
  };

  return (
    <>
      <OrderBySelect
        options={options}
        defaultValue={filters?.orderBy}
        handleChanged={(e) => handleChanged(e)}
      />
    </>
  );
};

export default AudienceOrderByFilter;
