import { Segmented, Select, Typography } from "antd";
import { useContext } from "react";
import { AudienceListContext } from "../AudienceListContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../shared/enums";

export const AudienceOrderDirectionFilter = () => {
    const { Text } = Typography;

    const { filters, setFilters } = useContext(AudienceListContext)

    return <>
   
        <Select
         size="large"
         style={{ width: "150px" }}
         className="order-by-select"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        value={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            label: "A - Z",
          },
          {
            value: OrderDirection.Dsc,
            label: "Z - A",
          },
        ]}
      />
    </>
}