import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Card from "antd/es/card/Card";
import {
  Button,
  Select,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Typography,
  Modal,
} from "antd";
import CreateSimpleEmail from "../Create/CreateSimpleEmail";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";
import { MdCancel, MdOutlineClose } from "react-icons/md";
import { FaCheck, FaRegSave } from "react-icons/fa";
import ButtonIcon from "../../../shared/ButtonIcon";
import { LocationContext } from "../../../context/LocationContext";
import { IoCaretBackSharp } from "react-icons/io5";

const EditSimpleEmail = () => {
  const { id } = useParams();

  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const { prevLocation } = useContext(LocationContext);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const regex = /^\/Campaigns\/Edit\/\d+\/\d*$/;

    if (regex.test(prevLocation)) {
      setShowButton(true);
    }
  }, []);

  const getHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  };

  const handleSave = async (e) => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Id", id);
    formData.append("Html", "");
    formData.append("Name", e.name);
    formData.append("Style", "");
    formData.append("IsSimple", true);
    formData.append("SimpleHtml", getHTML());

    var response = await axiosPrivate.put("/Template", formData);

    if (!response || !response.data.success) {
      return;
    }

    setLoading(false);
    navigate(-1);
  };

  useEffect(async () => {
    const response = await axiosPrivate.get(`/Template/${id}`);

    const contentState = stateFromHTML(response.data.data.html);
    setEditorState(EditorState.createWithContent(contentState));

    form.setFieldsValue({
      name: response.data.data.name,
      description: response.data.data.description,
    });
  }, []);

  const [editOpen, setEditOpen] = useState(false);
  return (
    <>
      <Form onFinish={handleSave} form={form}>
        <UpdateModal
          open={editOpen}
          setOpen={setEditOpen}
          form={form}
          loading={loading}
        />
      </Form>

      {editorState && (
        <CreateSimpleEmail
          style={{ flex: 1 }}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      )}

      <div
        style={{
          height: "60px",
          width: "100%",
        }}
      >
        <Flex
          style={{
            background: "#fff",
            height: "60px",
            borderTop: `1px solid #cbd5e0`,
          }}
          align="center"
          justify="space-between"
          gap={6}
        >
          {showButton ? (
            <Button
              className="flex-button new-buttons"
              size="large"
              style={{ marginLeft: 10 }}
              onClick={() => navigate(-1)}
            >
              Back to campaign
              <ButtonIcon
                background={"#eaeaf8"}
                icon={<IoCaretBackSharp style={{ fontSize: 18 }} />}
              />
            </Button>
          ) : (
            <div></div>
          )}

          <Flex align="center" justify="end" gap={6}>
            <Button
              className="flex-button new-buttons"
              size="large"
              onClick={() => navigate(-1)}
            >
              Cancel
              <ButtonIcon
                icon={<MdOutlineClose style={{ fontSize: 18 }} />}
                background={"#eaeaf8"}
              />
            </Button>
            <Button
              size="large"
              type="primary"
              className="flex-button new-buttons"
              style={{
                marginRight: 10,
              }}
              onClick={() => setEditOpen(true)}
            >
              Save changes
              <ButtonIcon
                icon={<FaRegSave style={{ fontSize: 18 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

const UpdateModal = ({ open, setOpen, form, loading }) => {
  const { Text, Title } = Typography;
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Save template changes
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
            className="flex-button new-buttons"
            onClick={() => setOpen(false)}
          >
            Cancel{" "}
            <ButtonIcon
              icon={<MdCancel style={{ fontSize: 16 }} />}
              background={"#fff"}
            />
          </Button>
          <Button
            type="primary"
            className="flex-button new-buttons"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes{" "}
            <ButtonIcon
              icon={<FaCheck style={{ fontSize: 14 }} />}
              background={"#5a6bc5"}
            />
          </Button>
        </Flex>
      }
    >
      <Text>Name</Text>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "A name is required" },
          {
            max: 50,
            message: "Name should contain at most 20 characters",
          },
        ]}
      >
        <Input
          count={{
            show: true,
            max: 50,
          }}
          size="large"
          placeholder="Enter a name for your design"
          allowClear
        />
      </Form.Item>
    </Modal>
  );
};

export default EditSimpleEmail;
