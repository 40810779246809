import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Avatar,
  Button,
  Card,
  Empty,
  Flex,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import { DateFormat } from "../../../dateformat";
import TableLoading from "../../../shared/TableLoading";
import { BsInfoCircleFill, BsTrash3Fill } from "react-icons/bs";
import { IoMdCloudDownload, IoMdLock, IoMdMore } from "react-icons/io";
import { FaCartShopping, FaInfo } from "react-icons/fa6";
import { SubscribeContext } from "../../../context/SubscribeContext";
import useAuth from "../../../hooks/useAuth";
import { RiCoupon2Fill } from "react-icons/ri";
import EmailQualityModal from "./EmailQualityModal";
import EmailOptions from "./EmailOptions";
import { FaUser } from "react-icons/fa";
import ButtonIcon from "../../../shared/ButtonIcon";

const ESEmailList = ({ countries, industries, id }) => {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const perPage = 7;
  const { auth } = useAuth();

  const axios = useAxiosPrivate();

  const [downloading, setDownloading] = useState(() => false);

  useEffect(async () => {
    if (!industries || !countries) {
      return;
    }
    setLoading(true);
    const response = await axios.post(`/TargetAudience/GetESEmailsPaged`, {
      page: page,
      pageSize: perPage,
      industries: industries,
      countries: countries,
    });

    setTotal(response.data.count);
    setData(response.data.data);
    setLoading(false);
  }, [page, countries, industries]);

  const columns = [
    {
      title: "Email",
      render: (data, row, index) => <Flex align="center" justify="start" gap={6}><Avatar size="large" icon={<FaUser/> } src={row.icon}/> 
      <Text strong>{row.email}</Text>
      </Flex>
    },
    {
      title: "Business Name",
      render: (row, data) => (
        <Text
          strong
          type="link"
          onClick={() => window.open(row.baseUrl, "_blank")}
        >
          
          {row.businessName}
        </Text>
      ),
    },
    {
      title: "Phone",
      render: (row) => <Text strong>{row.phoneNumber ?? "-"}</Text>,
    },
    {
      title: "Industry",
      render: (row) => <Text strong>{row.industryName}</Text>,
    },
    {
      render: (row) => (
        <Flex align="center" justify="end">
          <EmailOptions
            setCurrent={setCurrent}
            setOpen={() => setQualityOpen(true)}
            id={row.id}
            email={row.email}
            onDelete={onDelete}
          />
        </Flex>
      ),
    },
  ];

  const onDelete = (id) => {
    setData((prev) => prev.filter((c) => c.id != id));
    setTotal((prev) => prev - 1);
  };

  const download = async () => {
    try {
      setDownloading(true);
      const response = await axios.get(`/TargetAudience/csv/tapzap/${id}`, {
        responseType: "blob",
      });

      if (response.data) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv; charset=utf-8" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "filename.csv");
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        message.error("Failed to download CSV: No data returned");
      }
    } catch (error) {
      console.error("Download error:", error);
      message.error("Failed to download CSV");
    } finally {
      setDownloading(false);
    }
  };

  const [current, setCurrent] = useState();

  const [qualityOpen, setQualityOpen] = useState(() => false);

  return (
    <>
      {qualityOpen && (
        <EmailQualityModal
          email={current}
          open={qualityOpen}
          setOpen={setQualityOpen}
        />
      )}
      <Card size="small">

      <Flex align="center" justify="space-between">
              
                <Title level={4} style={{ margin: 5 }}>
                  Tapzap contact ({total ?? 0})
                </Title>

              <Button
                onClick={() => download()}
                loading={downloading}
                type="primary"
                size="large"
                className="flex-button new-buttons"
                style={{ borderRadius: 15 }}
              >
                Download csv

                <ButtonIcon
                          icon={<IoMdCloudDownload style={{ fontSize: 20 }} />}
                          background={"#5a6bc5"}
                        />
              </Button>
            </Flex>

      </Card>
       
      <Space style={{ width: "100%" }} direction="vertical">
        <Card className="zero-margin-padding" style={{boxShadow:"none"}}>
          {auth.subType == 1 && <BluryTable />}
          {auth.subType != 1 && (
            <>
            {loading ? <TableLoading/> :
            <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: page,
              pageSize: perPage,
              total: total,
              showSizeChanger: false,
            }}
            onChange={(pagination) => {
              setPage(pagination.current);
            }}
            ></Table>
          }
              </>
          )}
        </Card>
      </Space>
    </>
  );
};

const BluryTable = () => {
  const { Text, Title } = Typography;

  const { setShow, show, setShowCoupon, showCoupon } =
    useContext(SubscribeContext);

  const columns = [
    {
      title: "Email",
      render: (data, row, index) => <Text strong>{row.email}</Text>,
    },
    {
      title: "Created at",
      render: (row, data) => (
        <Text className="text-light">
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
  ];

  const items = [
    {
      email: "john_travolta@example.com",
      createdAt: new Date().getTime,
    },
    {
      email: "john@example.com",
      createdAt: new Date().getTime,
    },
    {
      email: "j@example.com",
      createdAt: new Date().getTime,
    },
    {
      email: "john@exadsadasdasdsample.com",
      createdAt: new Date().getTime,
    },
    {
      email: "jodsadasdshn@example.com",
      createdAt: new Date().getTime,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "1",
        }}
      >
        <Space direction="vertical" size={0} align="center">
          <IoMdLock style={{ fontSize: 80 }} />
          <Title style={{ margin: 0, padding: 0 }}>
            No active audience subscription
          </Title>
          <Text>
            To use tapzap audiences you must be subscribed to any of the
            available plans
          </Text>

          <Flex
            align="center"
            justify="center"
            gap={12}
            size={10}
            style={{ marginTop: 25 }}
          >
            <Button
              onClick={() => setShow(true)}
              size="large"
              type="primary"
              className="flex-button bold-button"
              icon={<FaCartShopping />}
              style={{ width: "200px" }}
            >
              Subscribe
            </Button>
            <Button
              onClick={() => setShowCoupon(true)}
              className="flex-button"
              icon={<RiCoupon2Fill />}
              size="large"
            >
              Apply coupon
            </Button>
          </Flex>
        </Space>
      </div>
      <div style={{ filter: "blur(10px)" }}>
        <Table columns={columns} dataSource={items} />
      </div>
    </div>
  );
};

export default ESEmailList;
