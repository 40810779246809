import { useContext } from "react";
import { AudienceListContext } from "../AudienceListContext";
import { DatePicker, Flex, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";

const AudienceFromDateFilter = () => {
    const {filters ,setFilters} = useContext(AudienceListContext)

    const {Text} = Typography;

    return <>
        <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>From</Text>
        <DatePicker size="large"
          placeholder="Select created date start"
          value={filters.from}
          className="dates-filter-select"
            defaultValue={filters?.from}
            onChange={(e) => setFilters(prev=>({
                ...prev,
                from: e
            }))}
            suffixIcon={
                <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
              } />
            </Flex>
    </>
}

export default AudienceFromDateFilter