import { Select, Typography } from "antd";
import { useState } from "react";

const UserAuthorizationFilter = ({ onChanged }) => {
  const {Text} = Typography
  
  const [options, setOptions] = useState([
    { value: "superadmin", label: "SuperAdmin" },
    { value: "admin", label: "Admin" },
    { value: "moderator", label: "Moderator" },
    { value: "basic", label: "Basic" },
  ]);
  
  const handleSelectedUsersChanged = (e) => {
    onChanged(e);
  };

  return (
    <>
      <Text>Authorizations</Text>

      <Select
        style={{ width: "100%" }}
        options={options}
        mode="multiple"
        className="little-transparent-input"
        maxTagCount="responsive"
        size="large"
        onChange={handleSelectedUsersChanged}
        defaultValue={[options[0], options[1], options[2], options[3]]}
      />
    </>
  );
};

export default UserAuthorizationFilter;
