import { Outlet } from "react-router-dom";
import { TemplateContextProvider } from "./TemplateContext";

const TemplateListLayout = () => {
  return (
    <TemplateContextProvider>
      <Outlet />
    </TemplateContextProvider>
  );
};

export default TemplateListLayout;
