import {
  Card,
  Col,
  Flex,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Statistic,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import { IoMdInformationCircle } from "react-icons/io";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MdMarkEmailRead, MdOutlineCallMade } from "react-icons/md";
import { BiMessageAltCheck, BiSolidErrorAlt } from "react-icons/bi";
import { useParams, useSearchParams } from "react-router-dom";
import { Bar, Line, Radar } from "react-chartjs-2";

const formatter = (value) => <CountUp end={value} separator="," />;
const SmsStats = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(() => false);

  const [data, setData] = useState(() => ({
    requests: 0,
    successCount: 0,
    error: 0,
  }));

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(`/Stats/responses/sms/${id}`);
    setData((prev) => ({
      request: response?.data?.requests,
      successCount: response?.data?.successCount,
      error: response?.data?.error,
    }));
    setLoading(false);
  }, []);
  return (
    <>
      <Title level={3}>Statistic</Title>
      <Space style={{ width: "100%" }} direction="vertical">
        <Row gutter={12}>
          <Stat
            description=""
            value={data?.request}
            name="Requests"
            loading={loading}
            color="#0079FF"
            icon={<MdOutlineCallMade style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            description=""
            value={data?.successCount}
            name="Success"
            loading={loading}
            icon={<BiMessageAltCheck style={{fontSize:20, color:"#422afb"}}/>}
            color="#00DFA2"
          />
          <Stat
            description=""
            value={data?.error}
            name="Errors"
            loading={loading}
            color="#FE6244"
            icon={<BiSolidErrorAlt style={{fontSize:20, color:"#422afb"}}/>}
          />
        </Row>

        <SmsResponsesCharts />
      </Space>
    </>
  );
};

const SmsResponsesCharts = () => {
  const { id } = useParams();
  const [chartType, setChartType] = useState(() => 0);
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(() => false);
  const [data, setData] = useState();

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(`/Stats/reach/${id}/sms`);

    const labels = response.data.data?.map((item) =>
      new Date(item.date).toLocaleDateString()
    );
    const distinctDatesSet = new Set(labels);

    const distinctDatesArray = Array.from(distinctDatesSet);

    setData({
      labels: distinctDatesArray,
      datasets: [
        {
          tension: 0.4,
          label: "Success",
          data: response?.data?.data
            ?.filter((c) => c?.status == "Success")
            .map((item) => item?.count),
          backgroundColor: "#00DFA2",
          borderColor: "#00DFA2",
          borderWidth: 2,
        },
        {
          tension: 0.4,
          label: "Errors",
          data: response?.data?.data
            ?.filter((c) => c?.status == "Error")
            .map((item) => item?.count),
          backgroundColor: "#FE6244",
          borderColor: "#FE6244",
          borderWidth: 2,
        },
      ],
    });

    setLoading(false);
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {loading && (
        <Skeleton.Input active style={{ height: "600px" }} className="w-100" />
      )}

      {!loading && (
        <>
          <>
            {data && (
              <div style={{ position: "relative" }}>
                <Select
                  size="small"
                  onChange={(e) => setChartType(e)}
                  defaultValue="Lines"
                  style={{
                    width: 120,
                    position: "absolute",
                    right: "3%",
                  }}
                  options={[
                    {
                      value: 0,
                      label: "Lines",
                    },
                    {
                      value: 1,
                      label: "Bars",
                    },
                    {
                      value: 2,
                      label: "Radar",
                    },
                  ]}
                />

                {chartType == 0 && (
                  <Line
                    data={data}
                    options={options}
                    style={{
                      padding: "0px !important",
                      margin: "0px !important",
                      width: "100%",
                    }}
                  />
                )}

                {chartType == 1 && (
                  <Bar
                    data={data}
                    style={{
                      padding: "0px !important",
                      margin: "0px !important",
                      width: "100%",
                    }}
                  />
                )}
                {chartType == 2 && (
                  <Radar
                    data={data}
                    style={{
                      padding: "0px !important",
                      margin: "0px !important",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            )}
          </>
        </>
      )}
    </>
  );
};

const Stat = ({ value, name, color, description, icon, loading }) => {
  const {Text} = Typography
  return (
      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
        <Card
          className="shadowless"
          size="small"
          style={{ marginTop: 12, boxShadow: "none" }}
        >
          <Spin spinning={loading}>
            <Flex align="center" justify="start" gap={6}>
              <Flex
                align="center"
                justify="center"
                style={{
                  width: "50px",
                  height: "50px",
                  background: "#f4f7fe",
                  borderRadius: "50px",
                }}
              >
                {icon}
              </Flex>

              <Space direction="vertical" size={0}>
                <Text className="text-light">{name}</Text>
                <Statistic
                  formatter={formatter}
                  style={{ marginTop: "-5px" }}
                  value={value}
                  precision={0}
                  valueStyle={{ fontWeight: 600 }}
                />
              </Space>
            </Flex>
          </Spin>
        </Card>
      </Col>
  );
};
export default SmsStats;
