import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Pagination,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import EmailTemplateAuthorFilter from "../../templates/Emails/Filters/EmailTemplateAuthorsFilter";
import EmailTemplateGroupFromDateFilter from "../../templates/Emails/Filters/EmailTemplateGroupFromDateFilter";
import EmailTemplateGroupToDateFilter from "../../templates/Emails/Filters/EmailTemplateGroupToDateFilter";
import EmailTemplateOrderByFilter from "../../templates/Emails/Filters/EmailTemplateOrderByFilter";
import EmailTemplateOrderDirection from "../../templates/Emails/Filters/EmailTemplateOrderDirection";
import ButtonIcon from "../../../shared/ButtonIcon";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { EmailTemplateGroupContext } from "../../templates/Emails/EmailTemplateGroupContext";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../../dateformat";
import env from "../../../env.json";
import { UserOutlined } from "@ant-design/icons";
import NoDataFound from "../../../shared/NoDataFound";
import { CreateFollowupContext } from "./CreateFollowupContext";

const SelectEmailTemplate = () => {
  const { emailTemplate, setEmailTemplate, setStep } = useContext(CreateFollowupContext);

  const [form] = Form.useForm();

  const { Title, Text } = Typography;
  const { filters, setFilters, data, setData, stats, setStats, page, setPage } =
    useContext(EmailTemplateGroupContext);

  const [showFilters, setShowFilters] = useState(false);

  const onSubmit = (e) => {
    setEmailTemplate(e.emailTemplateId);
    setStep(prev => prev + 1)
  };

  useEffect(() => {
    form.setFieldValue("emailTemplateId", emailTemplate);
  }, []);

  return (
    <Space direction="vertical" className="w-100" size={24}>
      <Card size="small">
        <Flex align="center" justify="space-between">
          <Title level={4} style={{ margin: 0 }}>
            Select email template
          </Title>

          <Button
            type="primary"
            size="large"
            className="flex-button new-buttons"
            onClick={() => setShowFilters((prev) => !prev)}
          >
            Filter
            {!showFilters ? (
              <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
            ) : (
              <ButtonIcon background={"#5a6bc5"} icon={<MdFilterAltOff />} />
            )}
          </Button>
        </Flex>

        {showFilters && (
          <Row gutter={[24, 24]} style={{ margin: 24 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <EmailTemplateAuthorFilter />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <EmailTemplateGroupFromDateFilter />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <EmailTemplateGroupToDateFilter />
            </Col>
          </Row>
        )}
      </Card>

      <Flex
        wrap="wrap"
        align="center"
        justify="space-between"
        className="w-100"
        gap={24}
      >
        <Flex align="center" justify="start" gap={6} wrap="wrap">
          <div></div>
          {filters?.selectedAuthors?.map((c) => {
            let user = filters?.authors?.find((d) => d.value == c);
            if (!user) {
              return;
            }

            return (
              <div className="filter-group-item">
                <Avatar
                  icon={<UserOutlined />}
                  src={`${env.fileUpload}${user.profile}`}
                />
                {user.name}

                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      selectedAuthors: prev.selectedAuthors.filter(
                        (e) => e != c
                      ),
                    }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            );
          })}
          {filters?.from && (
            <div className="filter-group-item">
              {`${new Date(filters?.from).toLocaleDateString(
                "en-us",
                DateFormat
              )}`}{" "}
              <Button
                shape="circle"
                type="text"
                onClick={() => setFilters((prev) => ({ ...prev, from: null }))}
                icon={<IoClose style={{ fontSize: 18 }} />}
                className="flex-button"
              ></Button>
            </div>
          )}
          {filters?.to && (
            <div className="filter-group-item">
              {`${new Date(filters?.to).toLocaleDateString(
                "en-us",
                DateFormat
              )}`}{" "}
              <Button
                shape="circle"
                type="text"
                onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                icon={<IoClose style={{ fontSize: 18 }} />}
                className="flex-button"
              ></Button>
            </div>
          )}
        </Flex>

        <Space>
          <EmailTemplateOrderByFilter />

          <EmailTemplateOrderDirection />

          <Button shape="circle" size="large" type="primary">
            GO
          </Button>
        </Space>
      </Flex>

      <Space direction="vertical" className="w-100">
        <Card size="small">
          <Row>
            <Col span={1}>
              <Text className="column-header"></Text>
            </Col>
            <Col span={5}>
              <Text className="column-header">Name</Text>
            </Col>
            <Col span={8}>
              <Text className="column-header">Description</Text>
            </Col>
            <Col span={10}>
              <Text className="column-header">Languages</Text>
            </Col>
          </Row>
        </Card>

        {!data || data?.length == 0 ? (
          <Card size="small">
            <NoDataFound
              addText="New email desing"
              description="Click the New Desing button on the Designs/Email page to create a new email desing"
              title="You have not created any email designs yet"
            />
          </Card>
        ) : (
          <>
            <Form form={form} onFinish={onSubmit}>
                <Form.Item name="emailTemplateId">
              <Radio.Group
                style={{ width: "100%" }}
              >
                <Space className="w-100" direction="vertical">
                  {data.map((c, i) => (
                    <Card key={i} size="small">
                      <Row>
                        <Col span={1}>
                          <Text className="column-header">
                            <Radio value={c.id} />
                          </Text>
                        </Col>
                        <Col span={7}>
                          <Text
                            style={{
                              color: "#707070",
                            }}
                          >
                            {c.name}
                          </Text>
                        </Col>
                        <Col span={8}>
                          <Text>{c.description}</Text>
                        </Col>
                        <Col span={8}>
                          <Flex align="center" justify="start">
                            {c.languages?.slice(0, 2)?.map((d) => (
                              <Tag>{d}</Tag>
                            ))}

                            {c.languages?.length > 2 ? (
                              <Tag>+ {c.languages.length - 2} more</Tag>
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Space>
              </Radio.Group>
              </Form.Item>
            </Form>
            <Flex className="w-100" align="center" justify="space-between">
              <Pagination
                current={page}
                pageSize={7}
                onChange={(page) => setPage(page)}
                total={stats?.total}
              />

              <Button onClick={() => form.submit()} type="primary">
                Continue to notification templates
              </Button>
            </Flex>
          </>
        )}
      </Space>
    </Space>
  );
};

export default SelectEmailTemplate;
