import { Button, Flex, Select, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { ResponsiveMasonry } from "react-responsive-masonry";
import IconSelectListItem from "../../../shared/IconSelectListItem";
import { DealListContext } from "../DealListContext";
import { IoMdCheckmark } from "react-icons/io";
import { MdArrowDropDown, MdClear } from "react-icons/md";

const ProductFilter = () => {
  const { Text } = Typography;

  const {filters ,setFilters} = useContext(DealListContext)


  const handleOnChanged = (e) => {
    setFilters(prev => ({...prev, selectedProducts: e}))
  }
  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{color:"#a5b6d5"}}>Products</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        placeholder="Select products"
        className="order-by-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        style={{ flexGrow:1 }}
        onChange={handleOnChanged}
        defaultValue={filters?.selectedProducts}
        options={filters?.products}
        mode="multiple"
      />
    </Flex>
  );
};
export default ProductFilter;
