import { Button, Dropdown } from "antd";
import e from "cors";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const EmailTemplateGroupOptions = ({ id, onDelete }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "Edit selected design",
      icon: <AiFillEdit />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        navigate(`/Templates/Email/Details/${id}`)
        }
    },
    {
      key: "2",
      label: "Delete selected design",
      danger: true,
      icon: <BsFillTrashFill />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        onDelete(id)
      }

    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} onClick={(e) => e.stopPropagation()}>
      <Button
        type="text"
        shape="circle"
        onClick={(e) => e.stopPropagation()}
        icon={<IoMdMore style={{ fontSize: 20 }} />}
      />
    </Dropdown>
  );
};

export default EmailTemplateGroupOptions;
