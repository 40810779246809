import {
  Button,
  Dropdown,
  Badge,
  Space,
  Flex,
  Typography,
  Menu,
  Tooltip,
  Empty,
  Input,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import env from "../../../env.json";
import bell from '../../../assets/icons/bell.png'
import InitilasAvatar from "../../../shared/IntialsAvatar";
import { FaBell } from "react-icons/fa";
const Notifications = ({size}) => {
  const axios = useAxiosPrivate();
  const { Text, Title } = Typography;
  const [items, setItems] = useState([]);
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();

  useEffect(() => {
    axios
      .post("/Notification/ReadPagedNotification", {
        page: page,
        pageSize: pageSize,
      })
      .then((response) => {
        setTotal(response.data.count);
        setItems(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);

  const fetch = () => {
    axios
      .post("/Notification/ReadPagedNotification", {
        page: page,
        pageSize: pageSize,
      })
      .then((response) => {
        setTotal(response.data.count);
        setItems(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  };

  const handleNotificationClick = async (id) => {
    const response = await axios.post("/Notification/ReadNotification", {
      id: id,
    });

    if (response.data.success) {
      fetch();
    }
  };


  const menu = (
    <div style={{ padding: '10px' }}>
      {/* You can add any custom elements here */}
      <Input placeholder="Search..." style={{ marginBottom: '10px' }} />
      <Button type="primary" block>
        Custom Button
      </Button>
      <Divider />
      <div>
        <a href="#action1">Action 1</a>
      </div>
      <div>
        <a href="#action2">Action 2</a>
      </div>
      <div>
        <a href="#action3">Action 3</a>
      </div>
    </div>
  );


  const renderNotificationsMenu = () => (
    <Menu style={{ width: "350px" }}>
      <Flex
        align="center"
        justify="space-between"
        style={{ margin: "10px 5px 0px 5px" }}
      >
        <Title level={5} style={{ margin: 0 }}>
          All notifications
        </Title>

        <Button type="link" style={{paddingRight:0}}>Mark all read</Button>
      </Flex>
      {items.length == 0 && <Empty description="All notifications read" />}
      {items.length > 0 &&
        items.map((notification) => (
          <Menu.Item
            key={notification.id}
            onClick={(e) => handleNotificationClick(notification.id)}
            style={{
              background: "rgba(255,255,255,.5)",
              marginTop:5
            }}
          >
            <Flex align="center" justify="start" gap={10} >
              <Tooltip title={notification.fromName}>
                <Badge dot={!notification.read}>
                  <InitilasAvatar name={notification.fromName} 
                    size="large"
                    src={`${env.fileUpload}${notification.fromImage}`}
                  />
                </Badge>
              </Tooltip>
              <Space direction="vertical" style={{ width: "100%" }} size={0}>
                <Flex
                  align="center"
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <Title level={5} style={{ margin: 0 }}>
                    {notification.title}
                  </Title>

                  <Text>
                    {new Date(notification.createdAt).toLocaleDateString()}
                  </Text>
                </Flex>
                <Text className="text-light">{notification.content}</Text>
              </Space>
            </Flex>
          </Menu.Item>
        ))}
      <div style={{ textAlign: "center" }}>
        {total > items.length && (
          <Button
            type="link"
            size="large"
            onClick={() => setPage((prev) => prev + 1)}
          >
            See more
          </Button>
        )}
      </div>
    </Menu>
  );

  return (
    <Dropdown dropdownRender={() => renderNotificationsMenu()} trigger={["click"]}>
      <Badge count={total} size="large">
        <Button
          shape="circle"
          type="text"
          size="large"
          style={{color:"#607282"}}
          className="flex-button"
          icon={<img src={bell} width={22}/>}
        />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
