import { Button, Flex, Select, Typography } from "antd";
import { useContext, useState } from "react";
import { DealCardItems } from "../../../shared/enums";
import CreateCampaignModal from "../../campaigns/CreateCampaignModal";
import { DealListContext } from "../DealListContext";
import { IoMdAddCircle, IoMdCheckmark } from "react-icons/io";
import { TbSelectAll } from "react-icons/tb";
import { MdArrowDropDown, MdClear } from "react-icons/md";

const CardItemsFilter = () => {
  const { Text } = Typography;

  const options = [
    {
      value: DealCardItems.Contact,
      label: "Contact"
    },
    {
      value: DealCardItems.Amount,
      label: "Amount"
    },
    {
      value: DealCardItems.CreatedDate,
      label:"Created date"
    },
    {
      value:DealCardItems.Products,
      label:"Products"
    },
    {
      value:DealCardItems.Author,
      label:"Owners"
    },
    {
      value: DealCardItems.Companies,
      label: "Companies"
    },
    {
      value: DealCardItems.ClosedDate,
      label: "Closed date"
    }
  ]

  const {filters, setFilters} = useContext(DealListContext);
  
  const handleOnChanged = (selectedItems) => {
    const updatedCardItems = {};
    options.forEach(option => {
      updatedCardItems[option.value] = selectedItems.includes(option.value);
    });
    
    setFilters(prevFilters => ({
      ...prevFilters,
      cardItems: updatedCardItems
    }));
  };

  const selectedItems = Object.keys(filters.cardItems).map(c => parseInt(c)).filter(key => filters.cardItems[key]);
  
  return (
      <Select
        size="large"
        onChange={handleOnChanged}
        options={options}
        defaultValue={selectedItems}
        value={selectedItems}
        maxTagCount="responsive"
        placeholder="Select what the card displays"
        className="order-by-select-darker"
        mode="multiple"
        style={{width:300}}
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}}  />}
        />
  );
};

export default CardItemsFilter;
