import { useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailEditor } from "react-email-editor";
import { Button, Flex, Form, Input, Modal, Select, Typography } from "antd";
import { FaRegSave } from "react-icons/fa";
import ButtonIcon from "../../../shared/ButtonIcon";
import { MdOutlineClose } from "react-icons/md";
import { LocationContext } from "../../../context/LocationContext";
import { IoCaretBackSharp } from "react-icons/io5";

const CreateTemplate = () => {
  const { prevLocation } = useContext(LocationContext);
  const axiosPrivate = useAxiosPrivate();

  const [showButton, setShowButton] = useState(false);
  const [templateStyle, setTemplateStyle] = useState("");
  const { groupId, id } = useParams();

  useEffect(async () => {
    const regex = /^\/Campaigns\/Edit\/\d+\/\d*$/;

    if (regex.test(prevLocation)) {
      setShowButton(true);
    }

    const response = await axiosPrivate.get(`/Template/${id}`);
    setTemplateStyle(response.data.data.style);
  }, []);

  const [form] = Form.useForm();

  const emailEditorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const handleReady = async () => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    emailEditorRef.current.editor.loadDesign(JSON.parse(templateStyle));
  };

  const handleSave = async (e) => {
    setLoading(true);
    await emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Html", html);
      formData.append("Name", e.name);
      formData.append("Style", JSON.stringify(design));
      formData.append("CategoryId", e.category);

      if (groupId) {
        formData.append("groupId", groupId);
      }
      formData.append("languageId", e.languageId);

      var response = await axiosPrivate.post("/Template", formData);

      if (!response || !response.data.success) {
        return;
      }

      setLoading(false);
      if (showButton) {
        navigate(-1);
      } else {
        navigate(`/Templates/Email`);
      }
    });
  };
  const [saveOpen, setSaveOpen] = useState(false);
  return (
    <>
      <CreateTemplateModal
        open={saveOpen}
        form={form}
        setOpen={setSaveOpen}
        adding={loading}
        onAdd={handleSave}
      />
      {id ? (
        <>
          {templateStyle && (
            <EmailEditor
              ref={emailEditorRef}
              design={templateStyle}
              onReady={handleReady}
              style={{ flex: 1 }}
            />
          )}
        </>
      ) : (
        <EmailEditor
          ref={emailEditorRef}
          onReady={handleReady}
          style={{ flex: 1 }}
        />
      )}

      <div
        style={{
          height: "60px",
          width: "100%",
        }}
      >
        <Flex
          style={{
            background: "#fff",
            height: "60px",
            borderTop: `1px solid #cbd5e0`,
          }}
          align="center"
          justify="space-between"
          gap={6}
        >
          {showButton ? (
            <Button
              className="flex-button new-buttons"
              size="large"
              style={{ marginLeft: 10 }}
              onClick={() => navigate(-1)}
            >
              Back to campaign
              <ButtonIcon
                background={"#eaeaf8"}
                icon={<IoCaretBackSharp style={{ fontSize: 18 }} />}
              />
            </Button>
          ) : (
            <div></div>
          )}

          <Flex align="center" justify="end" gap={6}>
            <Button
              className="flex-button new-buttons"
              size="large"
              onClick={() => navigate(-1)}
            >
              Cancel
              <ButtonIcon
                icon={<MdOutlineClose style={{ fontSize: 18 }} />}
                background={"#eaeaf8"}
              />
            </Button>
            <Button
              size="large"
              type="primary"
              className="flex-button new-buttons"
              style={{
                marginRight: 10,
              }}
              onClick={() => setSaveOpen(true)}
            >
              Save changes
              <ButtonIcon
                icon={<FaRegSave style={{ fontSize: 18 }} />}
                background={"#5a6bc5"}
              />
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

const CreateTemplateModal = ({
  open,
  setOpen,
  adding,
  onAdd,
  form,
  options,
}) => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [languages, setLanguages] = useState();
  useEffect(async () => {
    var response = await axios.get("/Language");

    setLanguages(
      response.data.data.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );
  }, []);

  return (
    <Modal
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button loading={adding} type="primary" onClick={() => form.submit()}>
            {" "}
            Save changes
          </Button>
        </Flex>
      }
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Save template changes
        </Title>
      }
    >
      <Form onFinish={onAdd} form={form}>
        <Text>Name</Text>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "A name is required" },
            {
              max: 50,
              message: "Name should be at most 50 characters long",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter a name for your design"
            allowClear
            count={{
              show: true,
              max: 50,
            }}
          />
        </Form.Item>

        <Text>Language</Text>

        <Form.Item
          name="languageId"
          rules={[{ required: true, message: "A language is required" }]}
        >
          <Select
            size="large"
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={languages}
            placeholder="Select a language for your template"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTemplate;
