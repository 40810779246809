import { Card, Col, Row, Space } from "antd";
import React from "react";
import DealDetailsActivites from "./DealDetailsActivites";
import DealDetailsAside from "../DealDetailsAside/DealDetailsAside";
import DealNote from "./DealDetailsNote";
import DealDetailsFiles from "./DealDetailsFiles";
import useDealsDetailsCtx from "../../../../hooks/useDealsDetailsCtx";
import CardStatusBreadCrumbs from "../../CardStatusBreadCrumbs/CardStatusBreadCrumbs";
import DealDetailsHistory from "./DealDetailsHistory";
import DealDetailsSteps from "./DealDetailsSteps/DealDetailsSteps";

const DealDetailsMain = () => {
  const { dealDetails, activites } = useDealsDetailsCtx();

  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <DealDetailsAside />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          
            <Space className="w-100" direction="vertical" size="large">
              <CardStatusBreadCrumbs />
              <DealDetailsActivites />
              <DealDetailsSteps/>
              <DealDetailsHistory />
            </Space>
          
        </Col>
      </Row>
    </Card>
  );
};

export default DealDetailsMain;
