import { Popconfirm, message } from "antd";
import { useContext, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { CardStatus } from "../../../shared/enums";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import { DealListContext } from "../DealListContext";
//test
const DeleteDeal = ({ card, setCards, setTotalRevenues }) => {
  
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const {stats, setStats}  = useContext(DealListContext)

  const handleDelete = async (e) => {
   
    setLoading(true);

    const response = await axios.delete(`/Deal/${card.current.card.id}`);

    if (response.data.success) {
      
      if(card.current.card.status == CardStatus.New){
        setStats(prev => ({
            ...prev,
            total: prev.total - 1,
            new: prev.new - 1
        }))
    }else if(card.current.card.status ==  CardStatus.Qualifying){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            qualifying: prev.qualifying -1
        }))
    }
    else if(card.current.card.status == CardStatus.DemoScheduled){
        setStats(prev => ({
            ...prev, 
            total: prev.total -1,
            demoScheduled: prev.demoScheduled -1
        }))
    }else if(card.current.card.status == CardStatus.PendingCommitment){
        setStats(prev => ({
            ...prev,
            total: prev.total-1,
            pendingCommitment: prev.pendingCommitment -1
        }));
    }else if(card.current.card.status == CardStatus.InNegotiation){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            inNegotiation: prev.inNegotiation -1
        }));
    }else if(card.current.card.status == CardStatus.Won){
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            won: prev.won -1
        }));
    }else{
        setStats(prev => ({
            ...prev,
            total: prev.total -1,
            lost: prev.lost -1
        }));
    }
      setCards(prev => prev.filter(c => c.id != card.current.card.id))
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      <Popconfirm
        style={{
          backdropFilter: "blur(10px)",
          background: "rgba(255,255,255,.2) !important",
        }}
        title="Delete this deal"
        description="Are you sure to delete this deal?"
        okText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
        loading={loading}
      >
        Delete this deal
      </Popconfirm>
    </>
  );
};

export default DeleteDeal;
