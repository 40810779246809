import { Button, Dropdown } from "antd"
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5"

const ContactOptions = ({contact, setCurrent, openEdit, openDelete}) => {
    const items = [
        {
          key: "4",
          label: "Edit selected contact",
          icon:<AiFillEdit/>,
          onClick: () => {
            setCurrent(contact);
            openEdit();
          }
        },
        {
          key: "5",
          label: "Delete selected contact",
          danger: true,
          icon: <BsFillTrashFill/>,
          onClick: () => {
            setCurrent(contact);
            openDelete();
          }
        }
      ];
    return <Dropdown trigger={["click"]} menu={{ items }}>
    <Button
      type="text"
      shape="circle"
      className="flex-button"
      icon={<IoEllipsisHorizontal style={{ fontSize: 26, color:"#7e7e7e" }} />}
    />
  </Dropdown>
}

export default ContactOptions