import { Button, Card, message, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";
import { ImportContactContext } from "./ImportContactContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyStats from "../../companies/CompanyStats";
import { MdArrowDropDown } from "react-icons/md";
import SaveImportModal from "./SaveImportModal";

const MapListStep = ({ active }) => {
  const { Title, Text } = Typography;
  const { tags } = useContext(AudienceContext);
  const navigate = useNavigate();

  const {
    tagsStep,
    setTagsStep,
    fileUploadStep,
    dataMapStep,
    fileContainsHeader,
    doNotImportInvalid
  } = useContext(ImportContactContext);

  const [loading, setLoading] = useState(() => false);
  const axios = useAxiosPrivate();

  const submit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("File", fileUploadStep.file);

    if (tagsStep && tagsStep.length > 0) {
      tagsStep.forEach((tag) => {
        formData.append("Tags", tag);
      });
    }

    formData.append("Maps", JSON.stringify(dataMapStep));

    formData.append("HasHeader", fileContainsHeader);
    formData.append("Clean", doNotImportInvalid)
    
    const response = await axios.post("Contacts/Import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data.success) {
      navigate("/audience");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  
  const [saveOpen,setSaveOpen] = useState(false);

  return (
    <>
    <SaveImportModal loading={loading} onConfirm={() => submit()} open={saveOpen} setOpen={setSaveOpen}/>
      <Card
        size="small"
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <Space direction="vertical" size={0} className="w-100">
          <Title level={4} style={{ margin: 0 }}>
            3. Select a tag
          </Title>
        </Space>
        {active ? (
          <>
            <Select
              size="large"
              mode="multiple"
              placeholder="Select tags"
              className="order-by-select"
              style={{ minWidth: "300px", maxWidth: "600px" }}
              onChange={(e) => setTagsStep(e)}
              options={tags?.map((c) => ({
                value: c.id,
                label: c.name,
              }))}
              suffixIcon={
                <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
              }
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                gap: 6,
              }}
            >
              <Text>
                <Text strong> {tagsStep?.length ?? 0} tags</Text> selected
              </Text>
              <Button
                loading={loading}
                size="large"
                type="primary"
                onClick={() => setSaveOpen(true)}
              >
                Confirm your tags
              </Button>
            </div>
          </>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};

export default MapListStep;
