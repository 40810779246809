import { Descriptions } from "antd";
import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

export const CreateNotificationTemplateContext = createContext({
    languages: [],
    setLanguages: () => {},

    selectedLanguages: [],
    setSelectedLanguages: () => {}
   
});

export const CreateNotificationTemplateContextProvider = ({children}) =>{
    const [languages, setLanguages] = useState();

    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const axios = useAxiosPrivate();

    useEffect(async () => {
        const response = await axios.get("/Language");

        setLanguages(response?.data?.data?.map(c => ({
            label: c.name,
            value: c.id
        })))
    },[])
    return <CreateNotificationTemplateContext.Provider value={{
        languages,
        selectedLanguages,
        setSelectedLanguages
    }}>
        {children}
    </CreateNotificationTemplateContext.Provider>
}