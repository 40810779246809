import React, { useState } from 'react'
import Header from '../Header'
import Kanban from '../Kanban/Kanban'
import { DealsTabs } from '../../../shared/enums'
import List from '../List/List'

const DealsManagement = () => {
  const [activeTab,setActiveTab] = useState(1)
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} setShowDrawer={setShowAdd}/>
  {activeTab === DealsTabs.Kanban && <Kanban showAdd={showAdd} setShowAdd={setShowAdd}/>}
  {activeTab === DealsTabs.List && <List showAdd={showAdd} setShowAdd={setShowAdd}/>}
    </>
  )
}

export default DealsManagement