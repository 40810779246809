import { Outlet } from "react-router-dom";
import { ConfigProvider } from "antd";
import { LocationContextProvider } from "../context/LocationContext";
const lighttheme = {
  token: {
    colorPrimary: "#7586e0",
    colorError: "#e74a3b",
    colorLink: "#422afb",
    colorSuccess: "#1cc88a",
    colorTextBase: "#1b254b",
    colorText: "#1b254b",
    borderRadiusXS: 10,
    borderRadiusSM: 10,
    borderRadiusOuter: 10,
    borderRadiusLG: 10,
    footerBg: "white",
    colorBgContainer: "#fff",
  },
  components: {
    Pagination: {
      itemActiveBg: "#422afb",
      itemSize: 42,
    },
    Layout: {
      siderBg: "#fff",
      bodyBg: "#f5f6fd",
    },
    Collapse: {
      headerBg: "#fff",
    },
    Menu: {
      itemSelectedBg: "transparent",
      itemColor: "#8f9bba",
      itemSelectedColor: "#2d3748",
      itemHoverBg: "transparent",
      iconSize: 18,
      collapsedWidth: 0,
      itemMarginBlock: 0,
    },
  },
};

const Layout = () => {

  return (
    <ConfigProvider theme={lighttheme}>
      <main className="App">
        <LocationContextProvider>
          <Outlet />
        </LocationContextProvider>
      </main>
    </ConfigProvider>
  );
};

export default Layout;
