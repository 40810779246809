import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import { AuthorContext } from "../../context/AuthorsContext";
import { SearchContext } from "../../context/SearchContext";
import QueryString from "qs";

export const MediaContext = createContext({
  filters: {
    selectedAuthors: [],
    search: null,
    from: null,
    to: null,
    types: null,
    orderBy: null,
    orderDirection: null
  },
  setFilters: () => {},
  showFilters: false,
  setShowFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    mostUsed: null,
  },
  setStats: () => {},

  documents: [],
  setDocuments: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},  
});

export const MeidaContextProvider = ({ children }) => {
  const perPage = 7;
  const [page, setPage] = useState(1)
  const [loading, setLoading]= useState(false);
  const { debouncedValue } = useContext(SearchContext);
  const axios = useAxiosPrivate();
  const {you} = useContext(AuthorContext)
  const [documents, setDocuments] = useState()

  const [filters, setFilters] = useState(() => ({
    orderBy:"Created",
    orderDirection: OrderDirection.Dsc,
    selectedAuthors: [you],
  }));

  const [showFilters, setShowFilters] = useState(false);
  const [stats, setStats] = useState();

  useEffect(async () => {

    setLoading(true);

    const response = await axios.get(
      `/Document/GetAll`,
      {
        params:{

          pageSize: perPage,
          page: page,
          authors: filters?.selectedAuthors,
          orderBy: filters?.orderBy,
          orderDirection: filters?.orderDirection,
          search: debouncedValue,
          from: filters?.from ? new Date(filters.from) : null,
          to: filters?.to ? new Date(filters.to) : null,
        },
        paramsSerializer: params => {
          return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true});
        }
      }

    );

      setDocuments(
        response.data.data?.map((doc) => {
          return { ...doc, key: doc.id };
        })
      );

      setStats((prev) => ({ ...prev, total: response.data.count }));
      setLoading(false);
    
    setStats((prev) => ({
      ...prev,
      total: response.data.total,
      first: response.data.first ? new Date(response.data.first * 1000) : null,
      last: response.data.last ? new Date(response.data.last * 1000) : null,
      mostUsed: {
        name: response.data.authorName,
        profile: response.data.authorProfile,
      },
    }));
  }, [page, filters, debouncedValue]);



  return (
    <MediaContext.Provider
      value={{
        filters,
        setFilters,

        documents,
        setDocuments,

        showFilters,
        setShowFilters,

        stats,
        setStats,

        page,
        setPage,

        loading,
        setLoading
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
