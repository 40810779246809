import { Button, Flex, Form, Grid, Input, message, Space, Typography } from "antd";
import logo from "../../../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/useAuth";
import axios from "../../../../api/axios";
const { useBreakpoint } = Grid;
const REGISTER_URL = "/Account/register";
const LOGIN_URL = "/Account/authenticate";

const FirstStep = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const { setAuth, setUser } = useAuth();
  const screens = useBreakpoint();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");


  const from = location.state?.from?.pathname || "/";

  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const company = queryParams.get("company");

  useEffect(() => {
    form.setFieldValue("email", email)
  },[])

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          password: e.password,
          email: e.email,
          companyName: e.company,
          tenantId: company,
          code: code
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (!response || !response.data.success) {
        message.error(response.data.message);
        return;
      }

      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({
            email: e.email,
            password: e.password,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (!response || !response.data.success) {
          message.error(response.data.message);
          return;
        }

        const accessToken = response?.data?.data?.jwToken;
        const email = response?.data?.data?.email;
        const refreshToken = response?.data?.data?.refreshToken;
        const roles = response?.data?.data?.roles;

        const refToken = {
          refreshToken: refreshToken,
          email: email,
        };

        setRefreshToken(JSON.stringify(refToken));

        setAuth({ email: email, roles: roles, accessToken: accessToken, emailConfirmed: response?.data?.data?.isVerified, step: response.data.data.step });
        navigate(from, { replace: true });
      } catch (err) {
        var errorMessage = "";
        if (!err?.response) {
          errorMessage = "No Server Response";
        } else if (err.response?.status === 400) {
          errorMessage = "Missing Username or Password";
        } else if (err.response?.status === 401) {
          errorMessage = "Unauthorized";
        } else {
          errorMessage = "Login Failed";
        }

        message.error(errorMessage);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      var errorMessage = "";

      if (!err?.response) {
        errorMessage = "No Server Response";
      } else if (err.response?.status === 409) {
        errorMessage = "Username Taken";
      } else {
        errorMessage = "Registration Failed";
      }

      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "500px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>
              Create your account. No credit card needed.
            </Title>
            <Text style={{ fontSize: 16 }}>
              Send your first emails in a few minutes. Already have an account?{" "}
              <Text
                className="underline-link"
                onClick={() => navigate("/login")}
                type="link"
              >
                Login
              </Text>
            </Text>
          </Space>

          <Form form={form} onFinish={handleSubmit}>
            <Space className="w-100" direction="vertical" size={0}>
              <Text>Email</Text>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Provide your email" }]}
              >
                <Input
                  size="large"
                  placeholder="Enter your email address"
                  allowClear
                  disabled={email}
                />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>Password</Text>
              <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message :"Password is required"
                    },
                  {
                    message: "Enter a valid password",
                    pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your password"
                  allowClear
                />
              </Form.Item>
            </Space>
          </Form>

          <Space className="w-100" direction="vertical">
            <Button size="large" block type="primary" loading={loading} onClick={() => form.submit()}>
              Create an account
            </Button>
            <Text style={{ fontSize: 16 }}>
            By signing up, you are creating a Tapzap account, and you agree to Tapzap's{" "}
              <Text
                onClick={() => navigate("/policy/terms-of-usage")}
                className="underline-link"
                type="link"
              >
                Terms of Use
              </Text>{" "}
              and{" "}
              <Text
                onClick={() => navigate("/policy/prviacy-policy")}
                className="underline-link"
                type="link"
              >
                Privacy Policy
              </Text>
            </Text>
          </Space>
        </Space>
      </Flex>
    </>
  );
};

export default FirstStep;
