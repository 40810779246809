import { Button, Card, Col, Flex, message, Modal, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./ShareTemplateModal.css";
import { EmailTemplateTypes } from "../../shared/enums";

const ShareTemplateModal = ({ id, open, setOpen }) => {
  const axios = useAxiosPrivate();
  const { Text, Title } = Typography;
  const [data, setData] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      let response = await axios.post(
        `/Templates/Email/Share`,{
          id: id
        },
        {
          signal: signal,
        }
      );
      setData(response.data);
    };

    fetch();
    return () => controller.abort();
  }, [id]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://app.tapzap.ai/templates/email/import/${data?.url}`)
      .then(() => {
        message.success("Link copied to clipboard")
      })
      .catch(err => {
        console.error("Could not copy text: ", err);
      });
  };
  
  return (
    <Modal
      className="share-modal"
      footer={false}
      width={1000}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Share the design
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
    >
      <Row gutter={[24, 24]}>
        <Col span={9}>
          <Card size="small" className="no-shadow share-preview-wrapper">
          
          <div className="share-modal-preview" >
                <div dangerouslySetInnerHTML={{ __html: data?.html }} className="share-modal-preview-item">

                </div>
                </div>
          </Card>
          <Text>1. {data?.name}</Text>
        </Col>
        <Col span={15}>
          <Flex align="center" justify="start" vertical className="h-100">
           <Space direction="vertical" size="large" className="w-100">

            <Text className="share-text">
              Copy the following link to share your template. Other Tapzap users
              can import it just by pasting the link in their browser.
            </Text>

            <Space direction="vertical" className="w-100">
              <Card
                bodyStyle={{
                    padding: 8,
                    margin: 0,
                }}
                size="small"
                className="no-shadow w-100"
                >
                <Text className="link-text">
                  https://app.tapzap.ai/templates/email/import/{data?.url}
                </Text>
              </Card>
              <Button onClick={copyToClipboard} size="large" type="primary">
                Copy link
              </Button>
            </Space>
                  </Space>
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShareTemplateModal;
