import { Button, Flex, Form, Input, message, Space, Typography } from "antd";
import logo from "../../../../assets/images/logo.png";
import { createMemoryRouter, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import "react-phone-input-2/lib/bootstrap.css";
import { useContext, useEffect, useState } from "react";
import { CompanyStepsContext } from "./CompanyStepsContext";
import useAuth from "../../../../hooks/useAuth";
const SixthStep = () => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();
  const {setAuth} = useAuth();
  const [loading, setLoading] = useState(false)
  const { currentStep, setCurrentStep, phone, setPhone, basics, company, organisation } =
    useContext(CompanyStepsContext);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("phone", phone);
  }, []);

  const onSubmit = async (e) => {
    setLoading(true)
    setPhone(e.phone)

    let response = await axios.post('/Account/Assign-User-Data', {
      firstname : basics.firstName,
      lastname : basics.lastName,
      companyName : basics.companyName,
      website : basics.companyWebsite,
      companyHasWebsite :  basics.companyHasWebsite,
      address : company.address,
      zipCode : company.zip,
      city : company.city,
      country : company.country,
      teamMemberNo : organisation.noTeam,
      contactsNo : organisation.noAudiences,
      onlineStore : organisation.onlineStore,
      recieveMessages : organisation.recieveUpdates,
      phoneNumber : e.phone,
      
    });

    if(response.data.success){
      setAuth(prev => ({...prev, step: 3}))
      navigate("/campaigns")
    }else{
      message.error(response.data.message)
    } 

    setLoading(false)
  };

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "400px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>Validate your phone number</Title>
            <Text style={{ fontSize: 16 }}>
              We need your phone number in order to set up a two-factor
              authentication, and secure your account.
            </Text>
          </Space>

          <Form form={form} onFinish={onSubmit}>
            <Space className="w-100" direction="vertical" size={0}>
              <Text>Phone number</Text>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
              >
                <PhoneInput inputStyle={{width:"100%"}} containerStyle={{width:'100%'}} country={"us"} />
              </Form.Item>
            </Space>
          </Form>

          <Flex className="w-100" justify="end" gap={6}>
            <Button
              onClick={() => setCurrentStep((prev) => prev - 1)}
              size="large"
              type="link"
            >
              Back
            </Button>
            <Button loading={loading} size="large" type="primary" onClick={() => form.submit()}>
              Continue
            </Button>
          </Flex>
        </Space>
      </Flex>
    </>
  );
};

export default SixthStep;
