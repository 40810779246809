import { Typography } from "antd";
import { useContext } from "react";
import { EmailTemplateGroupContext } from "../EmailTemplateGroupContext";
import OrderBySelect from "../../../../shared/OrderBySelect";

const EmailTemplateOrderByFilter = () => {
    const {filters, setFilters} = useContext(EmailTemplateGroupContext)

    const options = [
        {
          value: "created",
          label: "Date created"
        },
        {
          value: "name",
          label: "Template name"
        },
      ];

      const handleChanged = (e) => {
        setFilters(prev => ({...prev, orderBy:e}))
      }
    return   <>
   
    <OrderBySelect
      options={options}
      defaultValue={filters?.orderBy}
      handleChanged={(e) => handleChanged(e)}
    />
  </>
}

export default EmailTemplateOrderByFilter;