import { Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";
import { AuthorContext } from "../../../../context/AuthorsContext";
import { MdArrowDropDown } from "react-icons/md";

const SmsAuthorFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(SmsListContext);
  const { authors } = useContext(AuthorContext);
  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>Authors</Text>
        <Select
          size="large"
          maxTagCount="responsive"
          className="order-by-select"
          defaultValue={filters?.selectedAuthors}
          value={filters?.selectedAuthors}
          placeholder="Select campaign filter authors"
          options={authors}
          mode="multiple"
          style={{ flexGrow: 1 }}
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
          onChange={handleChanged}
        />
      </Flex>
    </>
  );
};

export default SmsAuthorFilter;
