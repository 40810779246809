import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Steps,
  Typography,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const { Step } = Steps;
const CreateProxyModal = ({ open, setOpen, onAdded }) => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const steps = [
    {
      title: "Names",
      content: (
        <Form form={form} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text>From email</Text>
                <Form.Item
                  name="fromEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the from email",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter from email"
                    allowClear
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={24}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text>Hello name</Text>
                <Form.Item
                  name="helloName"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the hello name",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter hello name"
                    allowClear
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "Proxy",
      content: (
        <Form form={form} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col span={18}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text>Host</Text>

                <Form.Item
                  name="proxyHost"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the host",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter host" allowClear />
                </Form.Item>
              </Space>
            </Col>
            <Col span={6}>
              <Space
                className="w-100"
                direction="vertical"
                size={0}
                rules={[
                  {
                    required: true,
                    message: "Please provide the port",
                  },
                ]}
              >
                <Text>Port</Text>

                <Form.Item name="proxyPort">
                  <InputNumber
                    className="w-100"
                    size="large"
                    placeholder="Enter port"
                    allowClear
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={12}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text>Username</Text>
                <Form.Item
                  name="proxyUserName"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the username",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter username" allowClear />
                </Form.Item>
              </Space>
            </Col>
            <Col span={12}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text>Password</Text>

                <Form.Item
                  name="proxyPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please provide the password",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter password" allowClear />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      title: "SMTP",
      content: (
        <Form form={form} layout="vertical">
          <Text>Port</Text>
          <Row>
            <Col span={6}>
              <Form.Item
                name="smtpPort"
                rules={[
                  {
                    required: true,
                    message: "Please provide the port",
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  size="large"
                  placeholder="Enter port"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
        //form.resetFields();
      })
      .catch((info) => {
      });
  };

  const prev = () => {
    setCurrent(current - 1);
    form.setFieldsValue(formData);
  };

  const handleFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        const finalData = { ...formData, ...values };

        setLoading(true)

        let response = await axios.post("/CleanUpSettings", {
          fromEmail: finalData.fromEmail,
          helloName: finalData.helloName,
          proxyHost: finalData.proxyHost,
          proxyPort: finalData.proxyPort,
          proxyUserName: finalData.proxyUserName,
          proxyPassword: finalData.proxyPassword,
          smtpPort: finalData.smtpPort,
        });

        if (response.data.success) {
          setOpen(false);
          onAdded(response.data);
        }

        setLoading(false)
      })
      .catch((info) => {
      });
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Create proxy settings
        </Title>
      }
      footer={
        <Flex align="center" justify="end">
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button loading={loading} type="primary" onClick={() => handleFinish()}>
              Save changes
            </Button>
          )}
        </Flex>
      }
    >
      <Space className="w-100" direction="vertical" size={24}>
        <Text style={{ fontSize: 16 }}>
          TapZap's proxy settings let you verify emails securely and privately,
          bypassing IP blocks. Configure the proxy details to suit your needs.
        </Text>

        <Space className="w-100" direction="vertical">
          <Card style={{ boxShadow: "none" }} size="small">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </Card>
          <Card size="small" style={{ boxShadow: "none" }}>
            <div>{steps[current].content}</div>
          </Card>
        </Space>
      </Space>
    </Modal>
  );
};
export default CreateProxyModal;
