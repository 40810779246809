import { useContext, useState } from "react";
import { Flex, Select, Typography } from "antd";
import { CampaignContext } from "../CampaignContext";
import { CampaignType } from "../../../shared/enums";
import { MdArrowDropDown, MdEmail, MdSms } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

const CampaignTypeSelect = ({ onChanged }) => {
  const { filters, setFilters } = useContext(CampaignContext);

  const { Text } = Typography;

  const [options, setOptions] = useState([
    {
      value: CampaignType.EMAIL,
      label: (
        <Flex style={{ height: "100%" }} align="center" justify="start" gap={6}>
          <MdEmail />
          Email
        </Flex>
      ),
    },
    {
      value: CampaignType.WHATSAPP,
      label: (
        <Flex style={{ height: "100%" }} align="center" justify="start" gap={6}>
          <RiWhatsappFill />
          Whatsapp
        </Flex>
      ),
    },
    {
      value: CampaignType.SMS,
      label: (
        <Flex style={{ height: "100%" }} align="center" justify="start" gap={6}>
          <MdSms />
          Sms
        </Flex>
      ),
    },
  ]);

  const handleChanged = (e) => {
    setFilters((prev) => ({ ...prev, type: e }));
  };
  return (
    <>
      <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>Type</Text>
        <Select
          size="large"
          className="order-by-select"
          options={options}
          mode="multiple"
          style={{ flexGrow: 1 }}
          placeholder="Select campaign type"
          maxTagCount="responsive"
          defaultValue={filters?.type}
          onChange={(e) => handleChanged(e)}
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
        />
      </Flex>
    </>
  );
};
export default CampaignTypeSelect;
