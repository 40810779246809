import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsTrash3Fill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";

const FollowupStepOptions = ({id, onDelete, onUpdate}) => {
    const items = [
        {
            icon: <AiFillEdit />,
            label:"Edit selected step",
            onClick: () => {
                onUpdate(id)
            }
        },
        {
            label: "Remove selected step",
            danger: true,
            icon: <BsTrash3Fill />,
            onClick: () => onDelete(id)
        }
      ];
    return <>
    <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          shape="circle"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal style={{ fontSize: 26, color: "#b1b1b0" }} />
          }
        />
      </Dropdown>
    </>
}

export default FollowupStepOptions;