import SelectableTemplate from "../../../components/SelectableTemplate";
import {
  Card,
  Flex,
  Typography,
  Button,
  Row,
  Col,
  Steps,
  Space,
  message,
  FloatButton,
} from "antd";
import { AiOutlineRollback } from "react-icons/ai";
import CampaignStarterData from "./CampaignStarterData";
import Send from "../Send/Send";
import AudienceList from "../../audience/List/AudienceList";
import { useContext, useEffect } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import SelectableWhatsappTemplates from "../../templates/Whatsapp/SelectableWhatsappTemplates";
import SelectableSmsTemplate from "../../templates/Sms/SelectableSmsTemplate";
import SendTime from "./SendTime";
import ButtonIcon from "../../../shared/ButtonIcon";
import { FiPlus } from "react-icons/fi";
import CreateTemplateModal from "../../templates/List/CreateTemplateModal";

const CampaignSteps = () => {
  const { type } = useParams();

  const axios = useAxiosPrivate();
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [sendTimeOpen, setSendTimeOpen] = useState(false);
  const { currentStep, setCurrentStep, data, setData } = useContext(
    CreateCampaignContext
  );

  const [showFilters, setShowFilters] = useState(false);
  const stepItems = [
    {
      title: "My campaign",
      description: "Basic campaign information",
    },
    {
      title: "Select audience",
      disabled: !data.id,
      description: "Set your desired audience",
    },
    {
      title: type == 0 ? "Design email" : "Design your message",
      disabled: !data.id,
      description: "Select your template",
    },
    {
      title: "Send time",
      disabled: !data.id,
      description: "Schedule your campaign",
    },
    {
      title: "Send",
      disabled: !data.id,
      description: "Give a final look",
    },
  ];

  const saveAudience = async () => {
    const response = await axios.post("/Campaign/AssignAudience", {
      ids: data.audienceIds,
      campaignId: data.id,
    });

    if (response.data.success) {
      setCurrentStep(currentStep + 1);
    }
  };

  const saveTemplate = async () => {
    const response = await axios.post("/Campaign/AssignTemplate", {
      templateId: data.templateId,
      campaignId: data.id,
    });

    if (response.data.success) {
      setCurrentStep(currentStep + 1);
    }
  };

  useEffect(() => {
    if (currentStep == 3) {
      setSendTimeOpen(true);
    }
  }, [currentStep]);

  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false);

  return (
    <>
      <CreateTemplateModal
        open={createTemplateModalOpen}
        setOpen={setCreateTemplateModalOpen}
      />
      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
        <Card style={{ position: "sticky", top: 90 }}>
          <Steps
            size="large"
            progressDot
            direction="vertical"
            current={currentStep}
            onChange={(e) => setCurrentStep(e)}
            items={stepItems}
          />
        </Card>
      </Col>

      <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
        {(currentStep == 0 || currentStep == 2 || currentStep == 5) && (
          <Card
            title={
              <Flex align="center" justify="space-between">
                <Title level={4} style={{ margin: 0 }}>
                  {stepItems[currentStep]?.title}
                </Title>

                {currentStep == 2 && (
                  <Flex align="center" justify="end" gap={6}>
                    <Button
                      size="large"
                      className="flex-button new-buttons"
                      type="primary"
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      Filter
                      {!showFilters ? (
                        <ButtonIcon
                          icon={<MdFilterAlt />}
                          background={"#5a6bc5"}
                        />
                      ) : (
                        <ButtonIcon
                          background={"#5a6bc5"}
                          icon={<MdFilterAltOff />}
                        />
                      )}
                    </Button>

                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setCreateTemplateModalOpen(true)}
                      className="flex-button new-buttons"
                    >
                      New Desing
                      <ButtonIcon
                        icon={<FiPlus style={{ fontSize: 20 }} />}
                        background={"#5a6bc5"}
                      />
                    </Button>
                  </Flex>
                )}
              </Flex>
            }
          >
            {currentStep == 0 && <CampaignStarterData />}

            {currentStep == 2 && (
              <>
                {type == 0 && (
                  <SelectableTemplate
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                    disabled={!data.canEdit}
                    selected={data.templateId}
                    setSelected={(selected) => {
                      if (data.canEdit) {
                        setData({ ...data, templateId: selected });
                      }
                    }}
                  />
                )}
                {type == 1 && <SelectableWhatsappTemplates />}
                {type == 2 && <SelectableSmsTemplate />}

                <FloatButton
                  disabled={!data.canEdit}
                  onClick={saveTemplate}
                  className="flex-button"
                  type="primary"
                  shape="square"
                  description={
                    <Flex
                      align="center"
                      justify="start"
                      gap={5}
                      style={{ padding: 10 }}
                    >
                      <GrNext />
                      <Text style={{ color: "white" }}>
                        Continue to schedule your{" "}
                        {type == 0 ? "email" : "message"}
                      </Text>
                    </Flex>
                  }
                />
              </>
            )}
          </Card>
        )}
        {currentStep == 1 && (
          <>
            <AudienceList
              selectable={true}
              selected={data.audienceIds}
              disabled={!data.canEdit}
              title="Select audiences"
              setSelected={(selected) =>
                setData({ ...data, audienceIds: selected })
              }
            />

            <FloatButton
              onClick={saveAudience}
              disabled={!data.canEdit}
              className="flex-button"
              type="primary"
              shape="square"
              description={
                <Flex
                  align="center"
                  justify="start"
                  gap={5}
                  style={{ padding: 10 }}
                >
                  <GrNext />
                  <Text style={{ color: "white" }}>
                    Continue to designing your {type == 0 ? "email" : "message"}
                  </Text>
                </Flex>
              }
            />
          </>
        )}

        {currentStep == 3 && (
          <SendTime isOpen={sendTimeOpen} setIsOpen={setSendTimeOpen} />
        )}
        {currentStep == 4 && <Send />}
      </Col>
    </>
  );
};

export default CampaignSteps;
