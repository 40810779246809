import { Children, createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const LocationContext = createContext({
  prevLocation: "",
  setPrevLocation: () => {},
});

export const LocationContextProvider = ({ children }) => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location.pathname);

  useEffect(() => {
    setPrevLocation(location.pathname);
  }, [location]);

  return (
    <LocationContext.Provider
      value={{
        prevLocation: prevLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
