import { Card, Col, Input, Row, Skeleton } from "antd";

const AudienceLoading = () => {
  return (
    <Row gutter={[24, 24]}>
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
      <AudienceLoadingCard />
    </Row>
  );
};

const AudienceLoadingCard = () => {
  return (
    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
      <Skeleton.Input style={{ height: 100 }} className="w-100" active />
    </Col>
  );
};

export default AudienceLoading;
