import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Pagination,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import ButtonIcon from "../../../shared/ButtonIcon";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import NotificationTemplatesAuthorFilter from "../../templates/Notifications/Filters/NotificationTemplatesAuthorFilter";
import NotificationTemplatesFromDateFilter from "../../templates/Notifications/Filters/NotificationTemplatesFromDateFilter";
import NotificationTemplatesToDateFilter from "../../templates/Notifications/Filters/NotificationTemplatesToDateFilter";
import { NotificationTemplatesContext } from "../../templates/Notifications/NotificationTemplatesContext";
import TableLoading from "../../../shared/TableLoading";
import { DateFormat } from "../../../dateformat";
import { CreateFollowupContext } from "./CreateFollowupContext";
import NoDataFound from "../../../shared/NoDataFound";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { resolvePath, useNavigate, useParams } from "react-router-dom";

const SelectNotificationTemplate = () => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  
  const {
    notificationTemplate,
    setNotificationTemplate,
    details,
    emailTemplate,
    setStep
  } = useContext(CreateFollowupContext);
  const perPage = 7;
  const [showFilters, setShowFilters] = useState(false);
  const { loading, stats, page, setPage, groups } = useContext(
    NotificationTemplatesContext
  );

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue("notificationTemplateId", notificationTemplate);
  }, []);

  const onSubmit = async (e) => {
    setSubmitLoading(true);
    setNotificationTemplate(e.notificationTemplateId);
    setStep(prev => prev + 1)
    setSubmitLoading(false);
  };
  return (
    <Space direction="vertical" className="w-100" size={24}>
      <Card size="small">
        <Flex align="center" justify="space-between">
          <Title level={4} style={{ margin: 0 }}>
            Select notification template
          </Title>

          <Button
            type="primary"
            size="large"
            className="flex-button new-buttons"
            onClick={() => setShowFilters((prev) => !prev)}
          >
            Filter
            {!showFilters ? (
              <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
            ) : (
              <ButtonIcon background={"#5a6bc5"} icon={<MdFilterAltOff />} />
            )}
          </Button>
        </Flex>

        {showFilters && (
          <div style={{ margin: 24 }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NotificationTemplatesAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <NotificationTemplatesFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <NotificationTemplatesToDateFilter />
              </Col>
            </Row>
          </div>
        )}
      </Card>

      {loading && <TableLoading />}
      {!loading && (
        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={1}></Col>
              <Col span={5}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={8}>
                <Text className="column-header">Description</Text>
              </Col>
              <Col span={7}>
                <Text className="column-header">Language</Text>
              </Col>

              <Col span={3}>
                <Text className="column-header">Date</Text>
              </Col>
            </Row>
          </Card>

          <Form form={form} onFinish={onSubmit}>
            <Form.Item name="notificationTemplateId">
              <Radio.Group style={{ width: "100%" }}>
                <Space className="w-100" direction="vertical">
                  {groups && groups?.length > 0 ? (
                    <>
                      {groups?.map((c, ind) => (
                        <Card size="small" key={ind}>
                          <Row gutter={[6, 6]}>
                            <Col span={1}>
                              <Radio value={c.id} />
                            </Col>
                            <Col span={5}>
                              <Text type="link">{c.name}</Text>
                            </Col>
                            <Col span={8}>
                              <Text>{c.description}</Text>
                            </Col>
                            <Col span={7}>
                              <Flex align="center" justify="start">
                                {c.languages?.slice(0, 2)?.map((c) => (
                                  <Tag>{c}</Tag>
                                ))}

                                {c.languages?.length > 2 ? (
                                  <Tag>+ {c.languages.length - 2} more</Tag>
                                ) : (
                                  ""
                                )}
                              </Flex>
                            </Col>

                            <Col span={3}>
                              <Text>
                                {new Date(c?.createdAt).toLocaleDateString(
                                  "en-US",
                                  DateFormat
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      ))}

                      <Flex align="center" justify="space-between">
                        <Pagination
                          current={page}
                          pageSize={perPage}
                          onChange={(page) => setPage(page)}
                          total={stats?.total}
                        />

                        <Button
                          loading={submitLoading}
                          type="primary"
                          onClick={() => form.submit()}
                        >
                          Continue to details
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <Card size="small">
                      <NoDataFound
                        addText="New notification design"
                        description="Click the New Desing button on the Designs/Notification page to create a new notification desing"
                        title="You have not created any notification desing yet"
                      />
                    </Card>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Space>
      )}
    </Space>
  );
};

export default SelectNotificationTemplate;
