import { Flex, Select, Skeleton, Typography } from "antd";
import { useContext } from "react";
import { CampaignContext } from "../CampaignContext";
import { MdArrowDropDown } from "react-icons/md";
import { AuthorContext } from "../../../context/AuthorsContext";

const CampaignAuthorSelect = () => {
  const {filters, setFilters, authorLoading } = useContext(CampaignContext)
  const {authors} = useContext(AuthorContext)
  const { Text } = Typography;

  const handleChanged = (e) => {
    setFilters(prev => ({
      ...prev,
      selectedAuthors: e
    }))
  }
  return (
    <Flex align="center" justify="start" gap={6}>
       <Text style={{color:"#a5b6d5"}}>Authors</Text>
      {authorLoading ? (
        <Skeleton.Input active className="w-100" size="large" />
      ) : (
        <Select
          size="large"
          maxTagCount="responsive"
          className="order-by-select"
          value={filters?.selectedAuthors}
          defaultValue={filters?.selectedAuthors}
          suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
          placeholder="Select campaign filter authors"
          options={authors}
          style={{flexGrow:1}}
          mode="multiple"
          onChange={handleChanged}
        />
      )}
    </Flex>
  );
};

export default CampaignAuthorSelect;
