import { useContext } from "react";
import { Select } from "antd";
import { MediaContext } from "../MediaContext";
import { OrderDirection } from "../../../shared/enums";
import { MdArrowDropDown } from "react-icons/md";

const MediaOrderByDirection = () => {
  const { filters, setFilters } = useContext(MediaContext);

  return (
    <>
  
      <Select
        size="large"
        style={{ width: "150px" }}
        className="order-by-select"
        default={filters?.orderDirection}
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            label: "A - Z",
          },
          {
            value: OrderDirection.Dsc,
            label: "Z - A",
          },
        ]}
      />
    </>
  );
};

export default MediaOrderByDirection;
