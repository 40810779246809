import { createContext, useContext, useEffect, useState } from "react";
import { SearchContext } from "../../../context/SearchContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../../shared/enums";
import QueryString from "qs";
import { AuthorContext } from "../../../context/AuthorsContext";

export const EmailTemplateGroupContext = createContext({
  filters: {
    selectedAuthors: [],

    from: null,
    to: null,

    orderBy: null,
    orderDirection: null,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    authorProfile: null,
    authorName: null,
  },
  setStats: () => {},

  data: [],
  setData: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},
});

export const EmailTemplateGroupContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const {you} = useContext(AuthorContext)

  const [filters, setFilters] = useState(() => ({
    orderDirection: OrderDirection.Dsc,
    orderBy: "Created",
    selectedAuthors: [you]
  }));

  const [stats, setStats] = useState();

  const [page, setPage] = useState(1);
  const perPage = 7;

  const [data, setData] = useState();

  const { debouncedValue } = useContext(SearchContext);

  const axios = useAxiosPrivate();

  useEffect(async () => {
    setLoading(true)
    const response = await axios.get(`/Template/Group/List`, {
      params: {
        search: debouncedValue,
        from: filters?.from ? new Date(filters.from) : null,
        to: filters?.to ? new Date(filters.to) : null,
        authors: filters?.selectedAuthors, 
        orderBy: filters?.orderBy,
        sortDirection: filters?.orderDirection,
        page: page,
        pageSize: perPage
      },
      paramsSerializer: params => {
        return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true});
      }
    })

    setData(response.data.items)
    setStats(prev => ({
      total: response.data.total,
      first: response.data.first,
      last: response.data.last,
      authorName: response.data.author,
      authorImage: response.data.authorProfile
    }))

    setLoading(false)
  }, [filters, page, debouncedValue]);

  return (
    <EmailTemplateGroupContext.Provider
      value={{
        loading,
        filters,
        stats,
        page,
        data,
        setLoading,
        setFilters,
        setStats,
        setPage,
        setData,
      }}
    >
      {children}
    </EmailTemplateGroupContext.Provider>
  );
};
