import {
  Row,
  Typography,
  Steps,
  Card,
  Col
} from "antd";
import { useContext } from "react";
import SelectNotificationTemplate from "./SelectNotificationTemplate";
import SelectEmailTemplate from "./SelectEmailTemplate";
import {
  CreateFollowupContext,
} from "./CreateFollowupContext";
import CreateFollowupStepDetails from "./CreateFollowupStepDetails";

const CreateNewStep = () => {

  const { step, setStep } = useContext(CreateFollowupContext);

  const steps = [
    {
      title: "Email Templates",
      key: 1,
      description: "Assign email templates for your step",
    },
    {
      title: "Notification Templates",
      key: 2,
      description: "Assign notification templates for your step",
    },
    {
      title: "Details",
      key: 3,
      description: "Provide details such as name and description for your step",
    }
  ];

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={5}>
          <Card size="small">
            <Steps
              onChange={(e) => setStep(e)}
              direction="vertical"
              size="large"
              current={step}
              items={steps}
            />
          </Card>
        </Col>
        <Col span={19}>
          {step == 2 && <CreateFollowupStepDetails />}
          {step == 0 && <SelectEmailTemplate />}
          {step == 1 && <SelectNotificationTemplate />}
        </Col>
      </Row>
    </>
  );
};

export default CreateNewStep;
