import env from "../../../env.json";
import { useState, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Card,
  Flex,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import TemplateImage from "./TemplateImage";
import { useNavigate } from "react-router-dom";
import {DateFormat} from '../../../dateformat'
import InitilasAvatar from "../../../shared/IntialsAvatar";
import { HiDocumentDuplicate } from "react-icons/hi";

const YourTemplateCard = ({
  template,
  handleDelete,
  isForSelect,
  setSelected,
  isSelected,
  disabled,
  setTemplates,
  setTotal,
  showAuthor = true,
  showDescription = true,
  preview = false,
}) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { Title, Text } = Typography;
  const [currentTemplate, setCurrentTemplate] = useState(template);
  const handlePulicizeTemplate = async () => {
    const response = await axiosPrivate.post(`/Template/MarkPublic`, {
      templateId: template.id,
    });

    if (response && response.data.success) {
      message.success("Nice, action completed successfully!");
    } else {
      message.error(response.data.message);
    }
    setCurrentTemplate({
      ...currentTemplate,
      hasChild: !currentTemplate.hasChild,
    });
  };

  const deleteTemplate = () => {
    handleDelete(currentTemplate.id);
  };

  const dublicate = async (id) => {
    const response = await axiosPrivate.post(`/Template/Duplicate/${id}`);

    if(response.data.success){
      setTotal(prev => prev + 1)
      setTemplates(prev => [response.data.data, ...prev])
    }else{
      message.error(response.data.message);
    }
  }

  return (
    <Card className="dublicate-button-card"
      cover={
        template.isSimple ? null : (
          <TemplateImage
            src={`${env.fileUpload}${template.templatePreviewPath}`}
          />
        )
      }
    >
      <Flex align="center" justify="start" gap={2}>
        <Tag className={"black-color-tag"} color={"#e3e3e3"} >
              {new Date(currentTemplate.createdAt).toLocaleDateString("en-US", DateFormat)}
            </Tag>

          
      </Flex>

      <Space style={{ width: "100%", marginTop:12 }} direction="vertical" size="large">
        <div>
          <Title
            level={4}
            style={{
              margin: 0,
            }}
            type="link"
            onClick={() =>  {
                if(template.isSimple){
                  navigate(`/Templates/EditSimpleEmail/${currentTemplate.id}`)
                }else {
                  navigate(`/Templates/Edit/${currentTemplate.id}`)
                }
              }}
          >
            {currentTemplate.name}
          </Title>

          {showDescription && (
            <Text>{currentTemplate.description}</Text>
          )}
        </div>
        {showAuthor && (
          <Flex align="center" justify="space-between" wrap="wrap" gap={6}>
          
              <InitilasAvatar size="small" large
              name={currentTemplate.creatorFullName} 
              src={env.fileUpload + currentTemplate.userProfileImage}
              icon={<UserOutlined />}
              />

{!preview && (
            <Space>
              {(!template.isSimple)&&<Tooltip title="Duplicate">
                <Button size="small" className="flex-button duplicate-button" icon={<HiDocumentDuplicate /> } shape="circle" onClick={() => dublicate(template.id)}></Button>
                </Tooltip>}
              {
                (!template.isSimple)&&
             
                <Button
                  size="small"
                  className="flex-button"
                  onClick={handlePulicizeTemplate}
                 
                  >
                    {currentTemplate.hasChild ? "Public" : "Private"}
                  </Button>
                }

                <Button 
                  size="small"
                  onClick={deleteTemplate}
                  className="flex-button" 
                  danger
                  type="text"
                >Delete</Button>
            </Space>
          )}
          </Flex>
        )}

        {isForSelect && (
          <Button
            block
            disabled={disabled}
            onClick={() => (isForSelect ? setSelected(template.id) : null)}
            type={isSelected ? "primary" : "default"}
          >
            {isSelected ? "Selected" : "Select"}
          </Button>
        )}


        
      </Space>
    </Card>
  );
};

export default YourTemplateCard;
