import { Flex, Select, Space, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";

const ContactTagsFilter = () => {
    
    const { filters, setFilters, tags } = useContext(AudienceContext);
    const { Text } = Typography;

    const handleChanged = (e) => {
      setFilters((prev) => ({
        ...prev,
        tags: e,
      }));
    }

    return <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>Tags</Text>

      <Select
        size="large"
        maxTagCount="responsive"
        className="order-by-select"
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
        placeholder="Select campaign filter authors"
        style={{ flexGrow: 1 }}
        mode="multiple"
        options={tags?.map(c => ({
            label: c.name,
            value: c.id
        }))}
        filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        defaultValue={filters?.tags}
        value={filters?.tags}
        onChange={handleChanged}
      />
      </Flex>
}

export default ContactTagsFilter;