import { Flex, Select, Space, Typography } from "antd";
import { useContext } from "react";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";
import { MdArrowDropDown } from "react-icons/md";
import { AuthorContext } from "../../../../context/AuthorsContext";

const NotificationTemplatesAuthorFilter = () => {
  const { filters, setFilters } = useContext(NotificationTemplatesContext);
  const { Text } = Typography;
  const { authors } = useContext(AuthorContext);
  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>Authors</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        className="order-by-select"
        defaultValue={filters?.selectedAuthors}
        value={filters?.selectedAuthors}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
        placeholder="Select campaign filter authors"
        style={{ flexGrow: 1 }}
        options={authors}
        mode="multiple"
        onChange={handleChanged}
      />
    </Flex>
  );
};

export default NotificationTemplatesAuthorFilter;
