import { useContext } from "react";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import { Avatar, Col, Row, Space } from "antd";
import Stat from "../../../shared/Stat";
import { BiSolidNotification } from "react-icons/bi";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { UserOutlined } from "@ant-design/icons";
import { DateFormat } from "../../../dateformat";
import env from "../../../env.json";

const NotificationTemplatesStats = () => {
  const { stats } = useContext(NotificationTemplatesContext);

  return (
    <Row gutter={[24, 24]}>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.total}
          name="Total"
          icon={
            <BiSolidNotification style={{ fontSize: 22, color: "#273270" }} />
          }
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={new Date(stats?.first * 1000).toLocaleDateString(
            "en-US",
            DateFormat
          )}
          name="First"
          icon={<MdFirstPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={
            stats?.last
              ? new Date(stats?.last * 1000).toLocaleDateString(
                  "en-US",
                  DateFormat
                )
              : "-"
          }
          name="Last"
          icon={<MdLastPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.authorName}
          name="Most authored"
          icon={
            <Avatar
              icon={<UserOutlined />}
              size="large"
              src={`${env.fileUpload}${stats?.authorProfile}`}
            />
          }
          loading={false}
        />
      </Col>
    </Row>
  );
};

export default NotificationTemplatesStats;
