import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";
import OrderBySelect from "../../../../shared/OrderBySelect";

const EmailSenderOrderBySelect = () => {

  const { filters, setFilters } = useContext(EmailSenderContext);

  const options = [
    {
      value: "created",
      label:"Date created"
    },
    {
      value: "name",
      label: "Name"
    },
    {
      value: "email",
      label: "Email"
    },
    {
      value: "nickname",
      label: "Nickname"
    },
  ];

  return (
    <>
      <OrderBySelect
        options={options}
        handleChanged={(e) => setFilters((prev) => ({ ...prev, orderBy: e }))}
        defaultValue={filters?.orderBy}
      />
    </>
  );
};

export default EmailSenderOrderBySelect;
