import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Flex,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { DateFormat } from "../../../dateformat";
import { BiSearchAlt2 } from "react-icons/bi";
import useDebounce from "../../../hooks/useDebounce";
import TableLoading from '../../../shared/TableLoading'
import { TbListDetails } from "react-icons/tb";

const Responses = ({ id }) => {
  const [current, setCurrent] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search);
  const [status, setStatus] = useState();

  const { Text, Title } = Typography;
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const pageSize = 7;
  const [total, setTotal] = useState();
  const axios = useAxiosPrivate(); 
  const [loading, setLoading] = useState(true)

  const [isOpen, setIsOpen] = useState(false);

  const getColor = (status) => {
    if (status == "click") {
      return "green";
    } else if (status == "open") {
      return "lime";
    } else if (status == "delivered") {
      return "yellow";
    } else if (status == "spamreport") {
      return "red";
  }else if(status == "processed"){
    return "blue"
  } else{
    return "red"
  }
  };
  useEffect(async () => {
    setLoading(true)
    const response = await axios.post("/Campaign/GetCampaignResponses", {
      page: page,
      pageSize: pageSize,
      id: id,
      search: search,
      status: status
    });
    setTotal(response.data.count);
    setData(response.data.data);
    setLoading(false)
  }, [page, debouncedValue, status]);

  const columns = [
    {
      title: "Contact",
      render: (row, data) => <Space direction="vertical" size={0}>
          <Text strong>{row.email}</Text>
        </Space>
    },
    {
      title:"Date",
      width:150,
      render:(data, row) => <Text strong>{new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}</Text>
    },
    {
      title:"Status",
      render: (data, row) =>  <Tag  bordered={false} className="audience-tag" style={{textTransform: "capitalize"}} color={getColor(row.status)}>
      {row.status}
    </Tag>
    },
    {
      width: 50,
      render: (row, data) => (
        
          <Tooltip title="View response details">
          <Button
            icon={<TbListDetails />}
            className="flex-button"
            shape="circle"
            type="text"
            onClick={() => {
              setCurrent({
                email: row.email,
                id: id,
              });
              setIsOpen(true);
            }}
            ></Button>
            </Tooltip>
      ),
    },
  ];
  return (
    <>
      {isOpen && (
        <ResponseModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          current={current}
        />
      )}
      <Flex align="center" justify="space-between">
 
      <Title level={3}>All responses</Title>

      <Space wrap>
      <Input
      placeholder="Search..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      addonAfter={<BiSearchAlt2/>}
    />

    <Select maxTagCount="responsive" onChange={(e) => setStatus(e)} mode="multiple" style={{width:"200px"}} options={[
      {
        label: "Processed",
        value:"processed"
      },
      {
        label:"Dropped",
        value:"dropped"
      },
      {
        label:"Click",
        value:"click"
      },
      {
        label:"Delivered",
        value:"delivered"
      },
      {
        label:"Bounce",
        value:"bounce"
      },
      {
        label:"Spam report",
        value:"spamreport"
      },
      {
        label:"Deferred",
        value:"deferred"
      },
      {
        label:"Open",
        value:"open"
      }
    ]} placeholder="Select status..."/>
      </Space>
      </Flex>
      {(!loading)&&
      <Table
      columns={columns}
      dataSource={data}
      className="header-less"
      pagination={{
        showTotal: (total, range) =>
        `${range[0]}-${range[1]} of ${total} responses`,
        current: page,
        pageSize: pageSize,
        total: total,
        showSizeChanger: false,
        onChange: (p, s) => {
          setPage(p);
        },
      }}
      ></Table>
    }
    {loading&&<TableLoading/>}
    </>
  );
};

const ResponseModal = ({ isOpen, setIsOpen, current }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [data, setData] = useState();

  const getColor = (status) => {
    if (status == "click") {
      return "green";
    } else if (status == "open") {
      return "lime";
    } else if (status == "delivered") {
      return "yellow";
    } else if (status == "spamreport") {
      return "red";
    }
  };

  const getDescription = (status) => {
    if (status == "click") {
      return "User clicked somewhere inside the email";
    } else if (status == "open") {
      return "User opened your email";
    } else if (status == "delivered") {
      return "Email was delivered to user";
    } else if (status == "spamreport") {
      return "User reported email as spam";
    }else if(status == "processed"){
        return "Email was processed by server"
    }
  };

  useEffect(async () => {
    const response = await axios.post("/Campaign/GetCampaignUserResponse", {
      id: current.id,
      email: current.email,
    });

    setData(
      response.data.data.responses.map((c) => ({
        label: (
          <Text>
            {new Date(c.at).toLocaleDateString("en-US", DateFormat)}
          </Text>
        ),
        children: (
          <Space direction="vertical" size={0} style={{width:"100%"}}>
            <Text strong style={{ textTransform: "capitalize" }}>
              {c.status}
            </Text>
            <Text>{getDescription(c.status)}</Text>
          </Space>
        ),
        color: getColor(c.status),
      }))
    );
  }, []);

  return (
    <Modal
      centered
      title={
        <Title style={{ margin: 0 }} level={4}>
          Response timeline
        </Title>
      }
      style={{
        maxHeight:"350px !important"
      }}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={
        <Flex align="center" justify="end">
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </Flex>
      }
    >
        <div style={{maxHeight:300, overflowY:'auto', overflowX:"hidden"}}>

        <Timeline mode="alternate" items={data} />
      </div>
    </Modal>
  );
};

export default Responses;
