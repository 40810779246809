import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Card, Space, Table, Tag, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import { DateFormat } from "../../../dateformat";
import AvatarGroup from "../Kanban/AvatarGroup";
import ListOptions from "./ListOptions";
import TableLoading from "../../../shared/TableLoading";
import WonDealModal from "../WonDealModal";
import LostDealModal from "../LostDealModal";
import { CardStatus } from "../../../shared/enums";
import CreateDealDrawer from "../CreateDealDrawer/CreateDealDrawer";
import useDealsManagementCtx from "../../../hooks/useDealsManagementCtx";
import { DealListContext } from "../DealListContext";
import {SearchContext} from '../../../context/SearchContext'

const List = ({showAdd,setShowAdd}) => {
  const { setLoading, setIsDrawerOpen } = useDealsManagementCtx();
  const { setStats} = useContext(DealListContext);

  const {filters} = useContext(DealListContext)
  const {debouncedValue} = useContext(SearchContext)

  const { Text } = Typography;
  const navigate = useNavigate();

  const [wonModal, setWonModal] = useState(() => false);
  const [lostModal, setLostModal] = useState(() => false);
  const [current, setCurrent] = useState();

  const axios = useAxiosPrivate();
  const [deals, setDeals] = useState();
  const [listLoading, setListLoading] = useState(() => false);
  const [page, setPage] = useState(() => 1);
  const perPage = 10;
  const [count, setCount] = useState(() => 0);

  useEffect(async () => {
    setListLoading(true);
    const response = await axios.post(`/Deal/List`, {
      page: 1,
      perPage: perPage,
      from: filters.createdFromDate,
      to: filters.createdToDate,
      productIds: filters.selectedProducts,
      companyIds: filters.selectedCompanies,
      dealStages:filters.selectedStatuses.map(c => c.id),
      name: debouncedValue
    });

    setCount(response.data.count);
    setDeals(response.data.data);
    setListLoading(false);
  }, [filters, debouncedValue]);

  useEffect(async () => {
    setListLoading(true);
    const response = await axios.post(`/Deal/List`, {
      page: page,
      perPage: perPage,
      from: filters.createdFromDate,
      to: filters.createdToDate,
      productIds: filters.selectedProducts,
      companyIds: filters.selectedCompanies,
      dealStages:filters.selectedStatuses.map(c => c.id),
      name: debouncedValue
    });

    setCount(response.data.count);
    setDeals(response.data.data);
    setListLoading(false);
  }, [page]);

  const getStage = (stage) => {
    if (stage == 1) {
      return (
        <Tag bordered={false} color="gray">
          New
        </Tag>
      );
    } else if (stage == 2) {
      return (
        <Tag bordered={false} color="blue">
          Qualifying
        </Tag>
      );
    } else if (stage == 3) {
      return (
        <Tag bordered={false} color="cyan">
          Demo Scheduled
        </Tag>
      );
    } else if (stage == 4) {
      return (
        <Tag bordered={false} color="lime">
          Pending Commitment
        </Tag>
      );
    } else if (stage == 5) {
      return (
        <Tag bordered={false} color="geekblue">
          In Negotiation
        </Tag>
      );
    } else if (stage == 6) {
      return (
        <Tag bordered={false} color="green">
          Won
        </Tag>
      );
    } else {
      return (
        <Tag color="red" bordered={false}>
          Lost
        </Tag>
      );
    }
  };

  const columns = [
    {
      title: "Name",
      render: (row) => (
        <Text
          strong
          type="link"
          onClick={() => navigate(`/Deals/Details/${row.id}`)}
        >
          {row.title}
        </Text>
      ),
    },
    {
      title: "Stage",
      responsive:['sm'],
      render: (row) => getStage(row.stage),
      responsive: ['sm'],
    },
    {
      responsive: ['sm'],
      title: "Products",
      responsive:['sm'],
      render: (row) => (
        <>
          {row.products?.length == 0 && "-"}
          {row.products?.length > 0 && (
            <Text strong type="link">
              {row.products?.length <= 1
                ? row.products[0].name
                : `${row.products[0].name}, `}
            </Text>
          )}
          {row.products?.length > 1 ? (
            <Text type="link" strong>
              +{row.products?.length - 1} more...
            </Text>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      responsive: ['sm'],
      title: "close date",
      responsive:['sm'],
      render: (row) => (
        <>
          <Text strong>
            {new Date(row.closeDate).toLocaleDateString("en-US", DateFormat)}
          </Text>
        </>
      ),
    },
    {
      responsive: ['sm'],
      title: "Owners",
      responsive:['md'],
      render: (row) => (
        <>
          <AvatarGroup size="large" items={row.owners} />
        </>
      ),
    },
    {
      responsive: ['sm'],
      title: "Amount",
      responsive:['md'],
      render: (row) => (
        <Text strong>
          {row?.amount?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Text>
      ),
    },
    {
      title: "",
      width: 50,
      render: (row) => (
        <ListOptions
          id={row.id}
          deal={row}
          onDelete={handleDelete}
          setWonModal={setWonModal}
          setLostModal={setLostModal}
          setCurrent={setCurrent}
        />
      ),
    },
  ];

  const handleDelete = (id) => {
    setCount((prev) => prev - 1);

    setDeals((prev) => prev.filter((c) => c.id != id));
  };

  const onSetToWon = async (e) => {
    const response = await axios.post("/Deal/UpdateDealStatus", {
      id: current,
      newStage: CardStatus.Won,
      closeDate: e.closeDate,
      totalRevenue: e.revenue,
      reason: e.closedReason,
    });

    if (response.data.success) {
      const deal = deals.find(c => c.id == current);
      setStats(prev => ({
        ...prev,
        new: deal.stage == CardStatus.New ? prev.new - 1 : prev.new, 
        qualifying: deal.stage == CardStatus.Qualifying ? prev.qualifying - 1 : prev.qualifying,
        demoScheduled: deal.stage == CardStatus.DemoScheduled ? prev.demoScheduled - 1 : prev.demoScheduled,
        pendingCommitment: deal.stage == CardStatus.PendingCommitment ? prev.pendingCommitment - 1 : prev.pendingCommitment,
        inNegotiation: deal.stage == CardStatus.InNegotiation ? prev.inNegotiation - 1 : prev.inNegotiation,
        won: prev.won + 1,
        lost: deal.stage == CardStatus.Closed ? prev.lost - 1 : prev.lost,
      }))
      
      setDeals((prev) =>
        prev.map((c) => (c.id == current ? { ...c, stage: CardStatus.Won } : c))
    );
    setWonModal(false);
    } else {
      message.error(response.data.message);
    }
  };
  
  const onSetToLost = async (e) => {
    const response = await axios.post("/Deal/UpdateDealStatus", {
      id: current,
      newStage: CardStatus.Closed,
      lostReason: e.reason,
      additionalComments: e.additionalComments,
    });

    if (response.data.success) {
      const deal = deals.find(c => c.id == current);

  setStats(prev => ({
    ...prev,
    new: deal.stage == CardStatus.New ? prev.new - 1 : prev.new, 
    qualifying: deal.stage == CardStatus.Qualifying ? prev.qualifying - 1 : prev.qualifying,
    demoScheduled: deal.stage == CardStatus.DemoScheduled ? prev.demoScheduled - 1 : prev.demoScheduled,
    pendingCommitment: deal.stage == CardStatus.PendingCommitment ? prev.pendingCommitment - 1 : prev.pendingCommitment,
    inNegotiation: deal.stage == CardStatus.InNegotiation ? prev.inNegotiation - 1 : prev.inNegotiation,
    won: deal.stage == CardStatus.Won ? prev.won - 1 : prev.won,
    lost: prev.lost + 1,
  }))
  
    setDeals((prev) =>
      prev.map((c) =>
        c.id == current ? { ...c, stage: CardStatus.Closed } : c
  )

);
  setLostModal(false);
    } else {
      message.error(response.data.message);
    }
  };

  const onSubmit = async (e) => {
    setLoading(true);
    const response = await axios.post("/Deal/CreateDeal", {
      emailId: e.contacts,
      companyIds: e.companies,
      stage: e.status,
      ownerIds: e.assignedUser,
      amount: e.amount,
      closeDate: e.closeDate,
      title: e.title,
      productIds: e.products,
    });

    if (response.data.success) {
      setCount(prev => prev + 1);
      setDeals((prev) => [
        ...prev,
        {
          title: response.data.title,
          stage: response.data.stage,
          products: response.data.products,
          closeDate: response.data.closeDate,
          owners: response.data.owners,
          amount: response.data.amount,
          id: response.data.id,
        },
      ]);

      setShowAdd(false);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      <WonDealModal
        open={wonModal}
        onClose={() => setWonModal(false)}
        onSubmit={onSetToWon}
      />
      <LostDealModal
        open={lostModal}
        onClose={() => setLostModal(false)}
        onSubmit={onSetToLost}
      />

      <Space direction="vertical" className="w-100" style={{ marginTop: 24 }}>
        <Card className="zero-margin-padding">
          {listLoading && <TableLoading />}
          {!listLoading && (
            <Table
              style={{ marginTop: 1 }}
              columns={columns}
              pagination={{
                current: page,
                pageSize: perPage,
                total: count,
                onChange: (p, s) => {
                  setPage(p);
                },
              }}
              scroll={{ x: true }}
              dataSource={deals}
            />
          )}
        </Card>
      </Space>

      <CreateDealDrawer onSubmit={onSubmit} isDrawerOpen={showAdd} setIsDrawerOpen={setShowAdd}/>
    </>
  );
};

export default List;
