import { useContext} from "react";
import { Flex, Select, Typography } from "antd";
import { MediaContext } from "../MediaContext";
import { AuthorContext } from "../../../context/AuthorsContext";
import { MdArrowDropDown } from "react-icons/md";

const MediaAuthorSelect = () => {
  const { Text } = Typography;
  const {filters, setFilters} = useContext(MediaContext)
  const {authors} = useContext(AuthorContext)
  const handleAuthorChanged = (e) => {
    setFilters(prev => ({
      ...prev,
      selectedAuthors: e
    }));
  };

  return (
    <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>Authors</Text>
      {filters && (
        <Select
        size="large"
        maxTagCount="responsive"
        className="order-by-select"
          mode="multiple"
          allowClear
          suffixIcon={
            <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
          }
          value={filters?.selectedAuthors}
          options={authors}
          style={{ flexGrow: 1 }}
          defaultValue={filters?.selectedAuthors}
          onChange={handleAuthorChanged}
        />
      )}
    </Flex>
  );
};

export default MediaAuthorSelect;
