import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import TemplateVisibilitySelect from "../pages/templates/Filters/TemplateVisibilitySelect";
import TemplateDateFilter from "../pages/templates/Filters/TemplateDateFilter";
import TemplateUserSelect from "../pages/templates/Filters/TemplateUserSelect";
import OrderTemplatesDirection from "../pages/templates/Filters/OrderTemplatesDirection";
import OrderTemplatesSelect from "../pages/templates/Filters/OrderTemplatesSelect";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import TemplateCategories from "../pages/templates/Filters/TemplateCategories";
import YourTemplateCard from "../pages/templates/Components/YourTemplateCard";
import { Button, Empty, Row, Col, Space } from "antd";
import TemplateSkeletonCard from "../pages/templates/Components/TemplateSkeletonCard";
const SelectableTemplate = ({
  setSelected,
  selected,
  disabled = false,
  showFilters,
  setShowFilters,
  groupId = null,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [intialPage, setPage] = useState(1);

  const [categories, setCategories] = useState();
  const [userList, setUserList] = useState();
  const [dateFilter, setDateFilter] = useState({
    to: null,
    from: null,
  });

  const [sortDirection, setSortDirection] = useState("Desc");
  const [orderBy, setOrderBy] = useState("Created");

  const [visibility, setVisibility] = useState();

  const fetchTemplates = async (page, add) => {
    setLoading(true);
    const response = await axiosPrivate.post(`/Template/GetAllTemplates`, {
      pageSize: perPage,
      page: page,
      from: dateFilter.from,
      to: dateFilter.to,
      categories: categories,
      creators: userList,
      visibility: visibility,
      orderBy: orderBy,
      sortDirection: sortDirection,
      groupId: groupId,
    });

    if (add) {
      setTemplates([...templates, ...response.data.data]);
    } else {
      setTemplates(response.data.data);
      setPage(1);
    }

    setTotalRows(response.data.count);
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates(1, false);
  }, [categories, userList, dateFilter, visibility, orderBy, sortDirection]);

  const handleSelectedCategoriesChanged = (newList) => {
    setCategories(newList);
  };

  const handleDateFilterChanged = (e) => {
    setDateFilter({
      to: e[1].$d,
      from: e[0].$d,
    });
  };

  const handlePageChanged = async (val) => {
    setPage(val);

    await fetchTemplates(val, true);
  };

  const handleVisibilityChanged = (e) => {
    setVisibility(e);
  };

  const handleSortDirectionChanged = (newDir) => {
    setSortDirection(newDir);
  };

  const handleOrderByChanged = (newOrder) => {
    setOrderBy(newOrder);
  };

  const handleSelected = (e) => {
    setSelected(e);
  };
  return (
    <>
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {showFilters && (
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <TemplateVisibilitySelect
                visiblityOptionsChanged={handleVisibilityChanged}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <TemplateDateFilter
                dateFilter={dateFilter}
                dateChanged={(e) => handleDateFilterChanged(e)}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <TemplateUserSelect
                selectedUsersChanged={(e) => setUserList(e)}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <OrderTemplatesSelect onOrderChanged={handleOrderByChanged} />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <OrderTemplatesDirection
                onSortDirectionChanged={handleSortDirectionChanged}
              />
            </Col>
          </Row>
        )}
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: showFilters ? 3 : 4 }}
        >
          <Masonry gutter="1rem">
            {templates?.map((template, ind) => (
              <YourTemplateCard
                isSelected={template.id == selected}
                template={template}
                isForSelect
                key={ind}
                disabled={disabled}
                showAuthor
                preview
                showDescription
                setSelected={(e) => handleSelected(e)}
              />
            ))}
            {loading && <TemplateSkeletonCard />}
            {loading && <TemplateSkeletonCard />}
            {loading && <TemplateSkeletonCard />}
          </Masonry>
        </ResponsiveMasonry>

        {totalRows > templates?.length && (
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={(e) => {
                handlePageChanged(intialPage + 1);
              }}
            >
              See more
            </Button>
          </div>
        )}
        {totalRows <= templates?.length && (
          <Empty description="Looks like you have reached the end" />
        )}
      </Space>
    </>
  );
};

export default SelectableTemplate;
