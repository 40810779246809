import {
  Button,
  Card,
  Col,
  Flex,
  Grid,
  Row,
  Space,
  message,
  Typography,
  Tag,
  Pagination,
  Avatar,
} from "antd";
import NotificationTemplatesStats from "./NotificationTemplatesStats";
import { useContext, useState } from "react";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import NotificationTemplatesAuthorFilter from "./Filters/NotificationTemplatesAuthorFilter";
import NotificationTemplatesFromDateFilter from "./Filters/NotificationTemplatesFromDateFilter";
import NotificationTemplatesToDateFilter from "./Filters/NotificationTemplatesToDateFilter";
import NotificationTemplatesOrderByFilter from "./Filters/NotificationTemplatesOrderByFilter";
import NotificationTemplatesOrderDirectionFilter from "./Filters/NotificationTemplatesOrderDirectionFilter";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import NotificationTemplatresOptions from "./NotificationTemplatesOptions";
import EditSelectedNotificationGroupTemplate from "./EditSelectedNotificationGroupTemplate";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import ButtonIcon from "../../../shared/ButtonIcon";
import { IoClose } from "react-icons/io5";
import env from "../../../env.json";
import { UserOutlined } from "@ant-design/icons";
import { AuthorContext } from "../../../context/AuthorsContext";
const { useBreakpoint } = Grid;

const NotificationTemplatesList = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const { Text, Title } = Typography;
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [current, setCurrent] = useState();

  const [showFilters, setShowFilters] = useState(false);
  const screens = useBreakpoint();
  const perPage = 7;
  const {
    loading,
    stats,
    page,
    setPage,
    groups,
    setGroups,
    filters,
    setFilters,
  } = useContext(NotificationTemplatesContext);

  const { authors } = useContext(AuthorContext);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(
      `/Notification/Notification-Template-Group/${item}`
    );

    if (response.data.success) {
      setGroups((prev) => prev.filter((c) => c.id != item));
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <EditSelectedNotificationGroupTemplate
        onEdit={(e) =>
          setGroups((prev) =>
            prev.map((c) =>
              c.id == e.id
                ? { ...c, description: e.description, name: e.name }
                : c
            )
          )
        }
        open={editOpen}
        setOpen={setEditOpen}
        template={current}
      />

      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <NotificationTemplatesStats />
        <Card size="small">
          <Flex
            align="center"
            justify="space-between"
            gap={12}
            wrap="wrap"
            style={{
              ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
            }}
          >
            <Title level={4} style={{ margin: 0 }}>
              Notification designs
            </Title>

            <Space>
              <Button
                size="large"
                type="primary"
                block={screens.xs}
                className="flex-button new-buttons"
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
                ) : (
                  <ButtonIcon
                    background={"#5a6bc5"}
                    icon={<MdFilterAltOff />}
                  />
                )}
              </Button>
              <Button
                type="primary"
                size="large"
                block={screens.xs}
                onClick={() => navigate("/Templates/Notification/create")}
                className="flex-button new-buttons"
              >
                New design
                <ButtonIcon
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Space>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <NotificationTemplatesAuthorFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <NotificationTemplatesFromDateFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <NotificationTemplatesToDateFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <NotificationTemplatesOrderByFilter />
            <NotificationTemplatesOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={5}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={10}>
                <Text className="column-header">Language</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Date</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Author</Text>
              </Col>
              <Col span={1}>
                <Text className="column-header">Tool</Text>
              </Col>
            </Row>
          </Card>
          {loading ? (
            <TableLoading />
          ) : !groups || groups.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New notification desing"
                onAdd={() => navigate("/Templates/Notification/create")}
                description="Click the New Design button on the Notification desing page to create a new notification template"
                title="You have not created any notification templates yet"
              />
            </Card>
          ) : (
            <>
              {groups?.map((c, ind) => (
                <Card size="small" key={ind}>
                  <Row gutter={[6, 6]} style={{ height: "100%" }}>
                    <Col span={5} className="center-table-column">
                      <Text
                        type="link"
                        onClick={() =>
                          navigate(`/Templates/Notification/details/${c.id}`)
                        }
                      >
                        {c.name}
                      </Text>
                    </Col>
                    <Col span={10} className="center-table-column">
                      <Flex align="center" justify="start">
                        {c.languages?.slice(0, 2)?.map((c, i) => (
                          <Tag className={"black-color-tag"} color={"#e3e3e3"} key={i}>
                            {c}
                          </Tag>
                        ))}

                        {c.languages?.length > 2 ? (
                          <Tag className={"black-color-tag"} color={"#e3e3e3"}>
                            + {c.languages.length - 2} more
                          </Tag>
                        ) : (
                          ""
                        )}
                      </Flex>
                    </Col>

                    <Col span={4} className="center-table-column">
                      <Text>
                        {new Date(c?.createdAt * 1000).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}
                      </Text>
                    </Col>

                    <Col span={4} className="center-table-column">
                      <Flex align="center" justify="start" gap={6}>
                        <Avatar
                          src={`${env.fileUpload}${c.authorProfile}`}
                          icon={<UserOutlined style={{ color: "#5a6bc5" }} />}
                        />
                        <Text style={{ color: "#707070" }}>{c.authorName}</Text>
                      </Flex>
                    </Col>

                    <Col span={1}>
                      <Flex align="center" justify="end">
                        <NotificationTemplatresOptions
                          template={c}
                          setCurrent={setCurrent}
                          openEdit={() => setEditOpen(true)}
                          openDelete={() => setDeleteOpen(true)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end" className="w-100">
                  <Pagination
                    current={page}
                    pageSize={perPage}
                    onChange={(page) => setPage(page)}
                    total={stats?.total}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default NotificationTemplatesList;
