
const SideMenuIcon = ({Icon, isActive, iconClass}) =>{
    return <>
        <Icon/>

        <style>
        {`
          .${iconClass}-1 { fill: ${isActive ?"#ccc3fc" :"#ccd2da" }; }
          .${iconClass}-2 { fill: ${isActive ? "#816bf2" : "#61748f"}; }
        `}
      </style>
    </>
}
export default SideMenuIcon