import {
  Button,
  Flex,
  message,
  Modal,
  Typography,
  List,
  Checkbox,
  Input,
  Divider,
  Form,
} from "antd";
import { BsFillSendFill } from "react-icons/bs";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import InitilasAvatar from "../../../shared/IntialsAvatar";
import env from "../../../env.json";
import { v4 as uuidv4 } from "uuid";

const SendTestCampaign = ({ id }) => {
  const [sendOpen, setSendOpen] = useState(false);
  return (
    <>
      <SendTestEmailsModal id={id} open={sendOpen} setOpen={setSendOpen} />
      <Button
        size="large"
        block
        style={{ marginTop: 5 }}
        icon={<BsFillSendFill />}
        onClick={() => setSendOpen(true)}
        className="flex-button bold-button"
      >
        Send test campaign
      </Button>
    </>
  );
};

const SendTestEmailsModal = ({ open, setOpen, id }) => {
    const [loading, setLoading] = useState(false);


    const sendTestCampaign = async () => {
        setLoading(true);
        const response = await axios.post("/Campaign/SendTest", {
          id: id,
          emails: emails.filter(c => c.checked).map(c => c.email)
        });
    
        if (response.data.success) {
          message.success("Test campaign was sent!");
          form.resetFields();
          setOpen(false)
        } else {
          message.error(response.data.message);
        }
        setLoading(false);
      };

  const [form] = Form.useForm();

  const axios = useAxiosPrivate();

  const { Title, Text } = Typography;
  const [emails, setEmails] = useState();

  useEffect(async () => {
    const response = await axios.get(`/Users/GetAuthors`);


    setEmails(
      response.data.data.map((c) => ({
        profileImagePath: c.profileImagePath,
        email: c.email,
        firstname: c.firstname,
        lastname: c.lastname,
        checked: c.isYou,
        id: c.id,
      }))
    );
  }, []);

  const handleAdd = (e) => {
    setEmails((prev) => [
      ...prev,
      {
        email: e.email,
        checked: true,
        id: uuidv4(),
      },
    ]);

    form.resetFields();
  };

  const handleChange = (id, checked) => {
    console.log(`id ${id}, checked: ${checked}`)
  }
  return (
    <>
      <Modal
        title={
          <Title style={{ margin: 0 }} level={4}>
            Select emails to send the test to
          </Title>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button loading={loading} onClick={() => sendTestCampaign()} type="primary">Send</Button>
          </Flex>
        }
      >
        <List
          dataSource={emails}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Flex
                style={{ width: "100%" }}
                align="center"
                justify="space-between"
                gap={6}
              >
                <Flex gap={6} align="center" justify="start">
                  <InitilasAvatar
                    src={`${env.fileUpload}${item?.profileImagePath}`}
                    name={`${item.firstname} ${item.lastname}`}
                  />
                  <Text strong>{item.email}</Text>
                </Flex>
                <Checkbox onChange={(e) => handleChange(item.id, e.target.checked)} defaultChecked={item.checked}></Checkbox>
              </Flex>
            </List.Item>
          )}
        ></List>

        <Divider style={{ margin: 0, marginBottom: "10px" }} />
        <Form form={form} onFinish={handleAdd}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input className="w-100" size="large" placeholder="Enter test email"></Input>
            </Form.Item>
          </Form>
          <Button size="large" onClick={() => form.submit()}>
            Add
          </Button>
      </Modal>
    </>
  );
};

export default SendTestCampaign;
