import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  message,
  Pagination,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import TemplateStats from "./TemplateStats";
import { useContext, useState } from "react";
import ButtonIcon from "../../shared/ButtonIcon";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EmailTemplateAuthorFilter from "./Filters/EmailTemplateAuthorFilter";
import EmailTemplateFromFilter from "./Filters/EmailTemplateFromFilter";
import EmailTemplateToFilter from "./Filters/EmailTemplateToFilter";
import EmailTemplateOrderByFilter from "./Filters/EmailTemplateOrderByFilter";
import EmailTemplateOrderDirectionFilter from "./Filters/EmailTemplateOrderDirectionFilter";
import { DateFormat } from "../../dateformat";
import { IoClose } from "react-icons/io5";
import { TemplateContext } from "./TemplateContext";
import { AuthorContext } from "../../context/AuthorsContext";
import env from "../../env.json";
import { DeleteColumnOutlined, UserOutlined } from "@ant-design/icons";
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import "./TemplateList.css";
import EmailTemplateOptions from "./EmailTemplateOptions";
import PreviewTemplateModal from "./PreviewTemplateModal";
import { EmailTemplateTypes } from "../../shared/enums";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ShareTemplateModal from "./ShareTemplateModal";

const TemplateList = () => {
  const { Text, Title } = Typography;

  const {
    filters,
    setFilters,
    stats,
    setStats,
    templates,
    setTemplates,
    loading,
    page,
    setPage,
  } = useContext(TemplateContext);
  const { authors } = useContext(AuthorContext);

  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  const handleClick = (id, simple) => {
    if (simple) {
      navigate(`/Templates/EditSimpleEmail/${id}`);
    } else {
      navigate(`/Templates/Edit/${id}`);
    }
  };

  const [previewOpen, setPreviewOpen] = useState(false);

  const [current, setCurrent] = useState();

  const openPreview = (id, simple) => {
    setCurrent(id);
    setPreviewOpen(true);
  };

  const axios = useAxiosPrivate();

  const handleDubplicate = async (id) => {
    const response = await axios.post(`/Templates/Email/Duplicate`, {
      id: id,
    });

    if (response.data.success) {
      message.success("Selected design was duplicated!");

      setTemplates((prev) => [response.data, ...prev]);
      setStats((prev) => ({ ...prev, total: prev.total + 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    const response = await axios.delete(`/Templates/Email/${item}`);
    if (response.data.success) {
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
      setTemplates((prev) => prev.filter((c) => c.id != item));
    } else {
      message.error(response.data.message);
    }
  };

  const handleDeleteClicked = (id) => {
    setCurrent(id);
    setDeleteOpen(true);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const handleShare = (id) => {
    setShareOpen(true);
    setCurrent(id);
  }
  return (
    <>
    <ShareTemplateModal id={current} open={shareOpen} setOpen={setShareOpen}/>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current}
      />

      <PreviewTemplateModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={current}
        type={EmailTemplateTypes.UserCreated}
      />
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <TemplateStats />
        <Card size="small">
          <Flex align="center" justify="space-between" gap={12} wrap="wrap">
            <Title level={4} style={{ margin: 0 }}>
              Email designs
            </Title>

            <Space>
              <Button
                size="large"
                className="flex-button new-buttons"
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon
                    size="large"
                    icon={<MdFilterAlt />}
                    background={"#f1f1f1"}
                  />
                ) : (
                  <ButtonIcon
                    size="large"
                    icon={<MdFilterAltOff />}
                    background={"#f1f1f1"}
                  />
                )}
              </Button>

              <Button
                type="primary"
                size="large"
                onClick={() => navigate(`/Templates/Email/Create`)}
                className="flex-button new-buttons"
              >
                Create design
                <ButtonIcon
                  size="large"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Space>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <EmailTemplateAuthorFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <EmailTemplateFromFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <EmailTemplateToFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c, i) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item" key={i}>
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <EmailTemplateOrderByFilter />
            <EmailTemplateOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={20}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={4}>
                <Flex align="center" justify="end">
                  <Text className="column-header">Tool</Text>
                </Flex>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !templates || templates?.length == 0 ? (
            <Card size="small">
              <NoDataFound
                description="There is no desing found on your database! To add a new email desing click the button below!"
                title="No email designs found"
                addText="Add desing"
              />
            </Card>
          ) : (
            <>
              {templates?.map((c, i) => (
                <Card key={i} size="small">
                  <Row gutter={[6, 6]}>
                    <Col span={20}>
                      <Space direction="vertical" size={0}>
                        <Text
                          onClick={() => handleClick(c.id, c.simple)}
                          type="link"
                          className="template-name"
                          strong
                        >
                          {c.name}
                        </Text>
                        <Text>
                          {new Date(c.createdAt * 1000).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdited ? (
                            <Text className="template-updated">
                              {" "}
                              • Last edited at{" "}
                              {new Date(c.lastEdited * 1000).toLocaleDateString(
                                "en-us",
                                DateFormat
                              )}
                            </Text>
                          ) : (
                            ""
                          )}
                        </Text>

                        <Tag className="template-type-tag">
                          {c.simple ? "Simple" : "Advanced"}
                        </Tag>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Flex className="h-100" align="center" justify="end">
                        <EmailTemplateOptions
                          id={c.id}
                          onShare={handleShare}
                          onDelete={handleDeleteClicked}
                          duplicate={handleDubplicate}
                          simple={c.simple}
                          openPreview={openPreview}
                          handleUpdate={handleClick}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end">
                  <Pagination
                    total={stats?.total}
                    current={page}
                    pageSize={7}
                    onChange={(p) => setPage(p)}
                  />
                </Flex>
              </Card>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default TemplateList;
