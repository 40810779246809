import { Button, message, Popconfirm } from "antd";
import { useState } from "react";
import { FcEmptyTrash } from "react-icons/fc";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DeleteSender = ({ id, deleted }) => {
  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const response = await axios.post("Sender/Delete", { id: id });

    if (response.data.success) {
      deleted(id, response.data.newDefault);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Popconfirm  title="Delete the sender"
        description="Are you sure to delete this sender?"
        onConfirm={() => handleDelete()}
        okText="Confirm"
        cancelText="No">
        Delete selected user
      </Popconfirm>
    </>
  );
};

export default DeleteSender;
