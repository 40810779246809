import {
  Space,
  Row,
  Col,
  Button,
  Result,
  Modal,
  message,
  Flex,
  Card,
  Typography,
} from "antd";
import To from "./To";
import From from "./From";
import Subject from "./Subject";
import SendTime from "./SendTime";
import Template from "./Template";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { BiCheckDouble } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosRemoveCircle } from "react-icons/io";
import { BalanceContext } from "../../../context/BalanceContext";
import SendTestCampaign from "./SendTestCampaign";
import { CampaignContext } from "../CampaignContext";
import { DealsTabs } from "../../../shared/enums";
const Send = () => {
  const {setBalances} = useContext(BalanceContext)

  const {type, id} = useParams();

  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data } = useContext(CreateCampaignContext);
  const [fromValid, setFromValid] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  
  const [audienceCount,setAudienceCount] = useState();
  const axios = useAxiosPrivate();
  const {setCampaings} = useContext(CampaignContext)
  const save = async () => {
    setLoading(true);
    const response = await axios.post("/Campaign/UpdateSaveStatus", {
      campaignId: data.id,
      campaignSaveStatus: 1,
    });

    if (response.data.success) {
      setIsResultModalOpen(true);

      setCampaings(prev => prev.map(c => c.id == data.id ? {
        ...data,
        campaignStaus: 2
      } : c))
      if(type == "0"){
        setBalances(prev => ({
          ...prev, 
          email: prev.email - response.data.data
        }))
      }else if(type == "1"){
        setBalances(prev => ({
          ...prev, 
          whatsapp: prev.whatsapp - response.data.data
        }))
      }else{
        setBalances(prev => ({
          ...prev, 
          sms: prev.sms - response.data.data
        }))
      }
    }else{
      message.error(response.data.message);
    }
    setLoading(false);
  };

  

  const canApprove = () => {

    return data.audienceIds &&
      data.audienceIds.length > 0 &&
      (data.senderId || data.smsSenderId || data.whatsappPhoneNumberId) &&
      (data.templateId|| data.smsTemplateId || data.whatsappTemplateId) &&
      fromValid &&
      data.canEdit &&
      (data.startNow || (data.startDate && data.endDate))
      ? true
      : false;
  };

  return (
    <>
      <Modal
        closable={false}
        open={isResultModalOpen}
        footer={
          <>
            <Button type="primary" block onClick={() => navigate(`/Campaigns/Details/${id}`)}>
              Nice
            </Button>
          </>
        }
      >
        <Result
          icon={<SmileOutlined />}
          title="Great, we have done all the operations!"
        />
      </Modal>

      <Row gutter={12}>
        <Col xs={24} sm={24} md={16} lg={16} xxl={18} xxxl={18}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <To setAudienceCount={setAudienceCount}/>
            <From setIsValid={setFromValid} />
            {(type==0)&&<Subject />}
            <SendTime />

            <Card
              title={
                <Flex align="center" justify="space-between">
                  <Space>
                    {!canApprove() && (
                      <IoIosRemoveCircle
                        style={{ fontSize: 22, color: "#e74a3b" }}
                      />
                    )}
                    {canApprove() && (
                      <AiFillCheckCircle
                        style={{ fontSize: 22, color: "#1cc88a" }}
                      />
                    )}
                    <Title level={4} style={{ margin: 0 }}>
                      And at last.
                    </Title>
                  </Space>
                </Flex>
              }
            >
              <Title level={4} style={{margin:0}}>Audience reached {audienceCount} people</Title>
              <Text className="text-light">This is the number of people to receive your email.</Text>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <SendTestCampaign id={id}/>
                 
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Button
                    type="primary"
                    block
                    style={{ marginTop: 5 }}
                    size="large"
                    className="flex-button bold-button"
                    onClick={save}
                    disabled={!canApprove()}
                    icon={<BiCheckDouble style={{fontSize:22}}/>}
                    loading={loading}
                  >
                    Looks fine to me!
                  </Button>
                </Col>
              </Row>
            </Card>
          </Space>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xxl={6} xxxl={6}>
          <Space
            style={{ width: "100%", position: "sticky", top: 90 }}
            direction="vertical"
            size="large"
          >
            <Template />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default Send;
