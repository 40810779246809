import { useContext } from "react"
import { TemplateContext } from "./TemplateContext"
import { BiSolidNotification } from "react-icons/bi"
import { MdFirstPage, MdLastPage } from "react-icons/md"
import { Col, Row } from "antd"
import Stat from "../../shared/Stat"

const TemplateStats = () => {
    const {stats} = useContext(TemplateContext)
    return <>
    <Row gutter={[24, 24]}>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.total}
          name="Total"
          icon={
            <BiSolidNotification style={{ fontSize: 22, color: "#273270" }} />
          }
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.first}
          name="First"
          icon={<MdFirstPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.last}
          name="Last"
          icon={<MdLastPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
      <Stat
          showShadow={false}
          value={stats?.simple}
          name="Simple"
          icon={
            <BiSolidNotification style={{ fontSize: 22, color: "#273270" }} />
          }
          loading={false}
        />
      </Col>
    </Row>
    </>
}

export default TemplateStats