import { Button, Flex, Form, Input, message, Modal, Select, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import SmartText from "../../../components/SmartText";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateNotificationTemplateModal = ({ open, setOpen, groupId, onAdd }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
    const [languages, setLanguages] = useState();


  const { Text, Title } = Typography;
    const axios = useAxiosPrivate();

  const submit = async (e) => {
    setLoading(true);
    let response = await axios.post(`/Notification/Templates/Create-Template`, {
        languageId: e.language,
        template: e.template,
        groupId:groupId,
        header: e.header,
        url: e.url
    });

    if(response.data.success){
        setLoading(false)
        onAdd({...response.data, languageName: languages.find(d => d.value == e.language)?.label});
        form.resetFields()
        setOpen(false);
    }else{
        message.error(response.data.message);
    }
};

useEffect(async () => {
    const response = await axios.get('/Language');

    setLanguages(response.data.data.map(c => ({
        value: c.id,
        label: c.name
    })))
}, [])
  return (
    <Modal title={
        <Title style={{ margin: 0 }} level={4}>
          Add notification template
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Space className="w-100" direction="vertical">
        <Space className="w-100" direction="vertical" size={0}>
            <Text>Language</Text>
            <Form.Item
              name="language"
              rules={[
                {
                  required: true,
                  message: "Please provide a language",
                },
              ]}
            >
              <Select options={languages}
                placeholder="Template header"
                size="large"
              />
            </Form.Item>
          </Space>
          <Space className="w-100" direction="vertical" size={0}>
            <Text>Header</Text>
            <Form.Item
              name="header"
              rules={[
                {
                  required: true,
                  message: "Please provide a template header",
                },
              ]}
            >
              <Input
                placeholder="Template header"
                size="large"
                suffix={<SmartText />}
              />
            </Form.Item>
          </Space>

          <Space className="w-100" direction="vertical" size={0}>
            <Text>Url</Text>
            <Form.Item name="url">
              <Input size="large" placeholder="Enter template url" />
            </Form.Item>
          </Space>

          <Space className="w-100" direction="vertical" size={6}>
            <Flex align="center" justify="space-between">
              <Text>Template</Text>
              <SmartText />
            </Flex>

            <Form.Item
              name="template"
              rules={[
                {
                  required: true,
                  message: "Plase provide a template",
                },
              ]}
            >
              <TextArea rows={4} size="large" placeholder="Enter template" />
            </Form.Item>
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateNotificationTemplateModal;
