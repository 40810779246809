import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Flex,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { DateFormat } from "../../dateformat";
import { FaRegClock, FaUsers } from "react-icons/fa";
import { BsInboxFill } from "react-icons/bs";
import { HiPhone } from "react-icons/hi2";
import {
  MdFilterAlt,
  MdFilterAltOff,
  MdOutlineFileDownload,
} from "react-icons/md";
import LoadingSubscriptionCard from "./LoadingSubscriptionCard";
const SubscriptionList = () => {
  const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(() => false);

  const [showFilters, setShowFilters] = useState(() => false);
  const perPage = 10;

  const [invoices, setInvoices] = useState(() => []);

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(`/Invoice/invoices/${page}/${perPage}`);

    setTotal(response.data.count);

    setInvoices((prev) => [...prev, ...response.data.data]);
    setLoading(false);
  }, [page]);

  const { Title, Text } = Typography;

  return (<>
    <Flex align="center" justify="space-between" style={{marginBottom:5}}>
            <Title level={4} style={{ margin: 5, padding: 0 }}>
              Invoices ({total ?? 0})
            </Title>

            <Button
             size="large" style={{ borderRadius: 15 }}
             className="flex-button"
             onClick={() => setShowFilters(!showFilters)}
             icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
           >
            
             Filter
            </Button>
          </Flex>

        <Space direction="vertical" style={{ width: "100%" }}>
      <Row gutter={12}>
        {invoices &&
          invoices.map((invoice, ind) => (
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Card size="small" key={ind} style={{ marginTop: 12 }}>
                <Space>
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      width: "50px",
                      height: "50px",
                      background: "#f4f7fe",
                      borderRadius: "50px",
                    }}
                  >
                    {invoice.audience && (
                      <FaUsers style={{ fontSize: 26, color: "#422afb" }} />
                    )}
                    {invoice.phoneNumber && (
                      <HiPhone style={{ fontSize: 26, color: "#422afb" }} />
                    )}
                  </Flex>
                  <Space direction="vertical" size={0}>
                    <Title level={5} style={{ margin: 0 }} type="link">
                      # {invoice.number} 

                      <Tag
                        bordered={false}
                        color={invoice.stripeStatus == "paid" ? "green" : "red"}
                        style={{ textTransform: "uppercase" }}
                      >
                        {invoice.stripeStatus}
                      </Tag>
                    </Title>
                    <Space>
                      <Text className="text-light">
                        Tapzap{" "}
                        {invoice.audience?.status == 2 ? "PRO" : "ENTERPRISE"} x{" "}
                        {invoice.audience?.amount}
                      </Text>
                      
                    </Space>
                  </Space>
                </Space>

                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <Flex align="center" justify="space-between">
                  <Space>
                    <Button
                      onClick={() => {
                        window.open(invoice.invoicePdfUrl);
                      }}
                      shape="circle"
                      icon={<MdOutlineFileDownload style={{ fontSize: 18 }} />}
                      className="flex-button"
                    ></Button>
                  </Space>
                  <Space direction="vertical" size={0} align="end">
                    <Text className="text-light">Total:</Text>
                    <Text strong>
                      {(invoice.total / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>
                  </Space>
                </Flex>
              </Card>
            </Col>
          ))}

        {loading && <LoadingSubscriptionCard />}
      </Row>

      <Flex align="center" justify="center">
        {total > invoices.length && (
          <Button onClick={() => setPage((prev) => prev + 1)}>See more</Button>
        )}

        {total == invoices.length && (
          <Empty description="Looks like you have reached the end" />
        )}
      </Flex>
    </Space>
  </>

  );
};

export default SubscriptionList;
