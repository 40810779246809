import { Button, Space, Card, Flex, Row, Col, Typography, Grid, Avatar, Skeleton } from "antd";
import { useState, useContext } from "react";
import CreateAudience from "../CRUD/CreateAudience";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AudienceCard from "../Card/AudienceCard";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AudienceStats from "../AudienceStats";
import AudienceAuthorsFilter from "../Filters/AudienceAuthorsFilter";
import AudienceFromDateFilter from "../Filters/AudienceFromDateFilter";
import AudienceToDateFilter from "../Filters/AudienceToDateFilter";
import AudienceOrderByFilter from "../Filters/AudienceOrderByFilter";
import { AudienceOrderDirectionFilter } from "../Filters/AudienceOrderDirectionFilter";
import { AudienceListContext } from "../AudienceListContext";
import ButtonIcon from "../../../shared/ButtonIcon";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../../dateformat";
import env from '../../../env.json'
import { AuthorContext } from "../../../context/AuthorsContext";
import { UserOutlined } from "@ant-design/icons";
import NoDataFound from "../../../shared/NoDataFound";
import AudienceLoading from "./AudienceLoading";

const { useBreakpoint } = Grid;

const AudienceList = ({
  selectable,
  setSelected,
  selected,
  disabled,
  title,
}) => {

  const {authors} = useContext(AuthorContext)

  const { loading, setLoading, take, setTake, stats, setFilters, filters, setStats, audiences, setAudiences } = useContext(AudienceListContext)
  const [showFilters, setShowFilters] = useState(false)
  const { Title } = Typography;
  const screens = useBreakpoint();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDelete = (id) => {
    setAudiences(audiences.filter((a) => a.id != id));
    setStats(prev => ({...prev, total: prev.total - 1}));
  };

  const handleSelectedChanged = (gotSelected, id) => {
    if (!selectable || disabled) {
      return;
    }

    if (gotSelected) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((s) => s != id));
    }
  };

  return (
    <>
      <CreateAudience
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setAudiences={setAudiences}
      />

      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <AudienceStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between" wrap="wrap"
              style={{
                ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
              }}
            >
              {!title && (
                <Title style={{ margin: 0 }} level={4}>
                  Audiences
                </Title>
              )}
              {title && (
                <Title level={5} style={{ margin: 0 }}>
                  {title}
                </Title>
              )}

              <Space>

                <Button
                  size="large"
                  className="flex-button new-buttons"
                  type="primary"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  Filter

                  {!showFilters ? (
                    <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
                  ) : (
                    <ButtonIcon
                      background={"#5a6bc5"}
                      icon={<MdFilterAltOff />}
                    />
                  )}
                </Button>
                <Button
                 type="primary"
                 size="large"
                 className="flex-button new-buttons"
                  onClick={() => setIsModalOpen(true)}
                >
                  New audience
                  <ButtonIcon
                    icon={<FiPlus style={{ fontSize: 20 }} />}
                    background={"#5a6bc5"}
                  />
                </Button>
              </Space>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <AudienceAuthorsFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <AudienceFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <AudienceToDateFilter />
              </Col>
             
            </Row>
          )}

        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>
          <Space>
          <AudienceOrderByFilter />
          <AudienceOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

            {loading ? <>
              <AudienceLoading/>
            </>: !audiences ||audiences.length == 0 ? 
            <Card size="small"><NoDataFound title={"No audience found"} addText={"Add new audience"} description={"To add new audience click the New audience button below!"} onAdd={() => setIsModalOpen(true)}/></Card> : 
             <Space direction="vertical" size={24} style={{ width: "100%" }}>
             <Row gutter={[24, 24]} wrap>
               {audiences &&
                 audiences.map((audience) => (
                   <Col
                     key={audience.id}
                     xs={24}
                     sm={12}
                     md={8}
                     lg={8}
                     xl={6}
                     xxl={6}
                   >
                     <AudienceCard
                       disabled={disabled}
                       audience={audience}
                       onDelete={onDelete}
                       selectable={selectable}
                       selected={
                         selected &&
                         selected?.findIndex((f) => f == audience.id) >= 0
                       }
                       setSelected={handleSelectedChanged}
                     />
                   </Col>
                 ))}
             </Row>
   
             {audiences?.length < stats?.total && (
               <div style={{ textAlign: "center" }}>
                 <Button loading={loading} onClick={() => setTake(prev => prev + 16)}>
                   Show more audiences
                 </Button>
               </div>
             )}
           </Space>
             }
        
      </Space>

    </>
  );
};

export default AudienceList;
