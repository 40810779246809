import { useContext, useState } from "react";
import { Flex, Select, Typography } from "antd";
import { CampaignContext } from "../CampaignContext";
import { BsCalendarDateFill } from "react-icons/bs";
import { MdTitle } from "react-icons/md";
import OrderBySelect from "../../../shared/OrderBySelect";

const CampaignOrderBy = ({ onChanged }) => {
  const { filters, setFilters } = useContext(CampaignContext);
  const { Text } = Typography;
  const options = [
    {
      value: "Created",
      label: "Date created",
    },
    {
      value: "Name",
      label: "Name",
    },
  ];

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      orderBy: e,
    }));
  };
  return (
    <>
      <OrderBySelect
        options={options}
        defaultValue={filters?.orderBy}
        handleChanged={(e) => handleChanged(e)}
      />
    </>
  );
};

export default CampaignOrderBy;
