import { Form, Select, Space, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { AuthorContext } from "../../../context/AuthorsContext";

const AssignedUsersSelect = ({
  name = "assignedUser",
  label = "Owner",
  required = true,
  setDefault,
  placeholder,
}) => {
  const { Text } = Typography;

  const { authors, you } = useContext(AuthorContext);

  useEffect(async () => {
    if (setDefault) {
      setDefault(you);
    }
  }, []);

  return (
    <>
      <Space direction="vertical" size={0} className="w-100">
        <Text>{label}</Text>
        <Form.Item name={name} rules={[{ required: required }]}>
          <Select
            mode="multiple"
            size="large"
            placeholder={placeholder}
            options={authors}
          ></Select>
        </Form.Item>
      </Space>
    </>
  );
};

export default AssignedUsersSelect;
