import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import React from "react";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import YourTemplateCard from "../Components/YourTemplateCard";
import TemplateCategories from "../Filters/TemplateCategories";
import TemplateUserSelect from "../Filters/TemplateUserSelect";
import TemplateDateFilter from "../Filters/TemplateDateFilter";
import TemplateVisibilitySelect from "../Filters/TemplateVisibilitySelect";
import OrderTemplatesDirection from "../Filters/OrderTemplatesDirection";
import OrderTemplatesSelect from "../Filters/OrderTemplatesSelect";

import { Button, Col, Empty, Row, Space, message } from "antd";
import TemplateSkeletonCard from "../Components/TemplateSkeletonCard";
import { GrUp } from "react-icons/gr";

const YourTemplates = ({ showFilters, setShowFilters, search, setTotal, groupId }) => {
  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const perPage = 6;
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const [categories, setCategories] = useState();
  const [userList, setUserList] = useState();
  const [dateFilter, setDateFilter] = useState({
    to: null,
    from: null,
  });

  const [sortDirection, setSortDirection] = useState("Desc");
  const [orderBy, setOrderBy] = useState("Created");

  const [visibility, setVisibility] = useState();

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    await removeTemplate(item);
  };

  const removeTemplate = async (id) => {
    var response = await axiosPrivate.delete(`Template/${id}`);

    if (response && response.data.success) {
      message.success("Nice, template was deleted!");
      setTemplates(templates.filter((template) => template.id != id));
      setTotalRows(prev => prev - 1);
      setTotal(prev => prev - 1)
    } else {
      message.error(response.data.message);
    }
  };

  const handlePageChanged = async (page) => {
    setLoading(true);
    const response = await axiosPrivate.post(
      `/Template/GetAllTemplates`,
      {
        pageSize: perPage,
        page: page,
        from: dateFilter.from,
        to: dateFilter.to,
        categories: categories,
        creators: userList,
        visibility: visibility,
        orderBy: orderBy,
        sortDirection: sortDirection,
        search: search,
        groupId: groupId
      }
    );

    setTemplates([...templates, ...response.data.data]);
    
    setTotalRows(response.data.count);
    setTotal(response.data.count)
    setLoading(false);
    setPage(page)
  }

  useEffect(async () => {
    const controller = new AbortController();
    setLoading(true);
    const response = await axiosPrivate.post(
      `/Template/GetAllTemplates`,
      {
        pageSize: perPage,
        page: 1,
        from: dateFilter.from,
        to: dateFilter.to,
        categories: categories,
        creators: userList,
        visibility: visibility,
        orderBy: orderBy,
        sortDirection: sortDirection,
        search: search,
        groupId: groupId
      },
      {
        signal: controller.signal,
      }
    );

    setTemplates(response.data.data);
    setPage(1);

    setTotalRows(response.data.count);
    setTotal(response.data.count)
    setLoading(false);

    return () => controller.abort();
  }, [
    categories,
    userList,
    dateFilter,
    visibility,
    orderBy,
    sortDirection,
    search,
  ]);

  const handleSelectedCategoriesChanged = (newList) => {
    setCategories(newList);
  };

  const handleSelectedUserChanged = (list) => {
    setUserList(list);
  };

  const handleDateFilterChanged = (e) => {
    setDateFilter({
      to: e[1].$d,
      from: e[0].$d,
    });
  };

  const handleVisibilityChanged = (e) => {
    setVisibility(e);
  };

  const handleSortDirectionChanged = (newDir) => {
    setSortDirection(newDir);
  };

  const handleOrderByChanged = (newOrder) => {
    setOrderBy(newOrder);
  };

  return (
    <>
      {modalIsOpen && (
        <DeleteConfirmationModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          onDeleteConfirm={handleDeleteConfirm}
          item={itemToDelete}
        />
      )}

      <Row gutter={24} style={{margin:5}}>
        {showFilters && (
          <Col sm={24} xs={24} md={5} lg={5} xl={5} xxl={5}>
            <TemplateCategories
              isForUser={true}
              handleSelectedCategoriesChanged={handleSelectedCategoriesChanged}
            />
          </Col>
        )}

        <Col
          sm={24}
          xs={24}
          md={showFilters ? 19 : 24}
          lg={showFilters ? 19 : 24}
          xl={showFilters ? 19 : 24}
          xxl={showFilters ? 19 : 24}
        >
          <Space style={{ width: "100%" }} direction="vertical" size="large">
            {showFilters && (
              <>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <TemplateUserSelect
                      selectedUsersChanged={handleSelectedUserChanged}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <TemplateDateFilter
                      dateFilter={dateFilter}
                      dateChanged={(e, value) =>
                        handleDateFilterChanged(e, value)
                      }
                    />
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <TemplateVisibilitySelect
                      visiblityOptionsChanged={handleVisibilityChanged}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <OrderTemplatesSelect
                      onOrderChanged={handleOrderByChanged}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <OrderTemplatesDirection
                      onSortDirectionChanged={handleSortDirectionChanged}
                    />
                  </Col>
                </Row>
              </>
            )}
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: showFilters ? 3 : 4,
              }}
            >
              <Masonry gutter="1rem">
                {templates?.map((template) => (
                  <YourTemplateCard setTemplates={setTemplates} setTotal={setTotal}
                    key={template.id}
                    template={template}
                    handleDelete={handleOpenModal}
                  />
                ))}

                {loading && <TemplateSkeletonCard />}
                {loading && <TemplateSkeletonCard />}
                {loading && <TemplateSkeletonCard />}
                {loading && <TemplateSkeletonCard />}
              </Masonry>
            </ResponsiveMasonry>

            {!loading && totalRows > templates?.length && (
              <div style={{ textAlign: "center" }}>
                <Button
                  className="btn btn-outline-primary"
                  onClick={() => handlePageChanged(page + 1)}
                >
                  See more
                </Button>
              </div>
            )}
            {!loading && totalRows <= templates?.length && (
              <Empty description="Looks like you have reached the end" />
            )}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default YourTemplates;
