import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect, useState } from "react";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import { MdClose } from "react-icons/md";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import CreateNotificationTemplate from "./CreateNotificationTemplate";
import { useNavigate } from "react-router-dom";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";

const CreateNotificationTemplateGroup = () => {
  const { Title, Text } = Typography;
  const [languages, setLanguages] = useState();
  const {setGroups, setStats} = useContext(NotificationTemplatesContext)

  const [form] = Form.useForm();

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(async () => {
    const response = await axios.get("/Language");

    setLanguages(
      response?.data?.data?.map((c) => ({
        label: c.name,
        value: c.id,
        code: c.code,
      }))
    );
  }, []);

  const submit = async (e) => {
    setLoading(true);
    const { templates } = e;
    const templatesArray = Object.keys(templates).map((key) => templates[key]);

    let response = await axios.post("/Notification/Templates/Create-Group", {
      name: e.name,
      templates: templatesArray.map((c) => ({
        languageId: c.language,
        template: c.template,
        header: c.header,
        url: c.url,
      })),
    });

    if (response.data.success) {
      setStats(prev => ({...prev, total: prev.total + 1}))
      setGroups(prev => ([response.data, ...prev]))
      navigate("/Templates/Notification");
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <Form onFinish={submit} form={form}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} xxl={8}>
          <Card size="small">
            <Title style={{ margin: 0 }} level={4}>
              Main details
            </Title>
            <Space className="w-100" direction="vertical" size={0}>
              <Text>Name</Text>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a name",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter template name" />
              </Form.Item>
            </Space>
          </Card>
        </Col>

        <Col xs={24} sm={24} xxl={16}>
          <Space direction="vertical" size={24} className="w-100">
            <Card
              size="small"
              style={{
                color: "white",
                background: "rgb(129,133,254)",
                background:
                  "linear-gradient(159deg, rgba(129,133,254,1) 0%, rgba(66,43,243,1) 41%, rgba(66,42,250,1) 100%)",
              }}
            >
              <Flex align="center" justify="space-between" gap={24}>
                <Space direction="vertical">
                  <Title style={{ margin: 0, color: "white" }} level={4}>
                    How it works?
                  </Title>
                  <Text style={{ color: "white" }}>
                    TapZap automatically selects the appropriate language for
                    the template when sending out notifications. Ensuring that
                    recipients receive messages in their preferred or default
                    language. In cases when we can't decide the language, we go
                    with the default one, which in this case is English.
                  </Text>
                </Space>
                <Select
                  options={languages}
                  size="large"
                  style={{ minWidth: 300 }}
                  showSearch
                  onChange={(e) => {
                    let language = languages.find((c) => c.value == e);
                    if (
                      !language ||
                      selectedLanguages.find((c) => c.value === e)
                    ) {
                      return;
                    }
                    if (!language) {
                      return;
                    }

                    setSelectedLanguages((prev) => [...prev, language]);
                  }}
                  placeholder="Choose languages"
                />
              </Flex>
            </Card>

            <Card size="small">
              
                {selectedLanguages && selectedLanguages?.length > 0 ? (
                  <Tabs
                    defaultActiveKey="1"
                    items={selectedLanguages?.map((c, i) => {
                      return {
                        label: (
                          <Flex align="center" justify="space-between" gap={6}>
                            {c.label}
                            <Button
                              onClick={() =>
                                setSelectedLanguages((prev) =>
                                  prev.filter((d) => d.value != c.value)
                                )
                              }
                              type="text"
                              shape="circle"
                              size="small"
                              className="flex-button"
                              icon={<MdClose />}
                            ></Button>
                          </Flex>
                        ),
                        key: c.code,
                        children: (
                          <CreateNotificationTemplate
                            form={form}
                            language={c.code}
                            languageId={c.value}
                            languageName={c.label}
                          />
                        ),
                      };
                    })}
                  />
                ) : (
                  <Empty description="No language selected" />
                )}
            </Card>
          </Space>
        </Col>
      </Row>

      <Flex align="center" justify="end" gap={6} style={{ marginTop: 24 }}>
        <Button onClick={() => navigate("/Templates/Notification")}>
          Cancel
        </Button>
        <Button htmlType="submit" loading={loading} type="primary">
          Save changes
        </Button>
      </Flex>
    </Form>
  );
};

export default CreateNotificationTemplateGroup;
