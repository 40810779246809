import { useNavigate } from "react-router-dom";

import { RiWhatsappFill } from "react-icons/ri";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { MdCancel, MdEmail, MdSms } from "react-icons/md";
import { useState } from "react";
import ButtonIcon from "../../shared/ButtonIcon";
import { FaCheck } from "react-icons/fa";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { IoFastFood } from "react-icons/io5";

const CreateCampaignModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  const axios = useAxiosPrivate();

  const [step, setStep] = useState(() => 1);

  const [type, setType] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const submit = async(e) => {

    setLoading(true);

    const response = await axios.post("New-Campaign", {
      name: e.name,
      type: type
    });

    if(response.data.success){
      navigate(`/Campaigns/Edit/${response.data.id}/${type}`)
    }else{
      message.error(response.data.message)
    }

    setLoading(false)
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        title={
          <Title style={{ margin: 0 }} level={4}>
            {step == 1
              ? "What campaign do you want to create?"
              : "Provide campaign details"}
          </Title>
        }
      >
        <Space direction="vertical" className="w-100" size={"large"}>
          {step == 1 && (
            <Text style={{ fontSize: 16 }}>
              Choose what kind of campaign you want to create from scratch and
              reuse it whenever you need it.
            </Text>
          )}

          {step == 1 ? (
            <Row gutter={12}>
              <Col md={8}>
                <Button
                  onClick={() => {
                    setType(0);
                    setStep(2);
                  }}
                  size="large"
                  block
                  style={{ height: 100, fontSize: 22 }}
                >
                  <Flex vertical align="center" justify="center">
                    <MdEmail style={{ fontSize: 30 }} />
                    Email
                  </Flex>
                </Button>
              </Col>
              <Col md={8}>
                <Button
                  onClick={() => navigate(`/Campaigns/Create/1`)}
                  size="large"
                  block
                  disabled
                  style={{ height: 100, fontSize: 22 }}
                >
                  <Flex vertical align="center" justify="center">
                    <RiWhatsappFill style={{ fontSize: 30 }} />
                    Whatsapp
                  </Flex>
                </Button>
              </Col>
              <Col md={8}>
                <Button
                  disabled
                  onClick={() => navigate(`/Campaigns/Create/2`)}
                  size="large"
                  block
                  style={{ height: 100, fontSize: 22 }}
                >
                  <Flex vertical align="center" justify="center">
                    <MdSms style={{ fontSize: 30 }} />
                    SMS
                  </Flex>
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <Form form={form} onFinish={submit}>
                <Text>Name</Text>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Campaign name is required",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    count={{
                      show: true,
                      max: 128,
                    }}
                    placeholder="Enter a name you'll remember"
                  />
                </Form.Item>
              </Form>
            </>
          )}

          {step == 1 ? (
            <Text strong>
              * Campaigns can not change type after they are created!
            </Text>
          ) : (
            <Flex align="center" justify="end" gap={6}>
              <Button
                onClick={() => {
                  setStep(1);
                  setOpen(false);
                }}
                className="flex-button new-buttons"
              >
                Cancel
                <ButtonIcon
                  icon={<MdCancel style={{ fontSize: 16 }} />}
                  background={"#f1f1f1"}
                />
              </Button>
              <Button onClick={() => form.submit()} loading={loading} type="primary" className="flex-button new-buttons">
                Continue
                <ButtonIcon
                  icon={<FaCheck style={{ fontSize: 14 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Flex>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default CreateCampaignModal;
