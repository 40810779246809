import React, { useContext } from "react";
import { Layout, Typography, Flex, Space, Button, Breadcrumb } from "antd";
import UserProfileMenu from "./UserProfileMenu";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Search from "./Search";
import capitalize from 'lodash/capitalize'

const Header = ({ collapsed, setCollapsed }) => {
  const { Header } = Layout;
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    
    const breadcrumbLabel = capitalize(pathSnippets[index]);

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbLabel}</Link>
      </Breadcrumb.Item>
    );
  });

  const homeBreadcrumb = (
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>
  );


  return (
    <>
      <Header
        theme={"light"}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          minHeight: "50px",
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          margin: 0,
        }}
      >
        <Flex align="center" justify="start" gap={6}>
          <Button
            className="flex-button"
            shape="circle"
            size="large"
            type="text"
            style={{ color: "#607282" }}
            onClick={() => setCollapsed(!collapsed)}
            icon={<IoMenu style={{ fontSize: 28 }} />}
          />

          <Search />
        </Flex>

        <UserProfileMenu collapsed={collapsed} setCollapsed={setCollapsed} />
      </Header>

      <Breadcrumb
        style={{ margin: "24px 0px 0px 24px" }}
        >
          {homeBreadcrumb}
          {breadcrumbItems}
        </Breadcrumb>
    </>
  );
};

export default Header;
