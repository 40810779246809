import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  message,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import FollowupStats from "./FollowupStats";
import { useContext, useState } from "react";
import FollowupAuthorFilter from "./filters/FollowupAuthorFilter";
import FollowupToDateFilter from "./filters/FollowupToDateFilter";
import FollowupFromDateFilter from "./filters/FollowupFromDateFilter";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import NoDataFound from "../../shared/NoDataFound";
import FollowupOptions from "./FollowupOptions";
import FollowupOrderByFilter from "./filters/FollowupOrderByFilter";
import FollowupOrderDirectionFilter from "./filters/FollowUpOrderDirectionFilter";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../shared/ButtonIcon";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import { FollowupContext } from "./FollowupContext";
import TableLoading from "../../shared/TableLoading";
import CreateFollowUp from "./create/CreateFollowup";
import CreatefollowupModal from "./CreateFollowupModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import env from "../../env.json";

const FollowupList = () => {
  const axios = useAxiosPrivate();

  const [addOpen, setAddOpen] = useState(false);

  const {
    perPage,
    page,
    setPage,
    filters,
    setStats,
    setData,
    data,
    loading,
    stats,
    setFilters,
  } = useContext(FollowupContext);

  const navigate = useNavigate();

  const { Text, Title } = Typography;

  const [showFilters, setShowFilters] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [current, setCurrent] = useState();

  const onDelete = async (id, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(`/Followup/${id}`);

    if (response.data.success) {
      setData((prev) => prev.filter((c) => c.id != id));
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={onDelete}
        item={current}
      />
      <CreatefollowupModal open={addOpen} setOpen={setAddOpen} />
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <FollowupStats />

        <Card size="small">
          <Flex align="center" justify="space-between" wrap="wrap">
            <Title style={{ margin: 0 }} level={4}>
              Follow up management
            </Title>

            <Flex wrap="wrap" gap={10}>
              <Button
                size="large"
                className="flex-button new-buttons"
                onClick={() => setShowFilters(!showFilters)}
                type="primary"
              >
                Filter
                {!showFilters ? (
                  <ButtonIcon icon={<MdFilterAlt />} background={"#5a6bc5"} />
                ) : (
                  <ButtonIcon
                    background={"#5a6bc5"}
                    icon={<MdFilterAltOff />}
                  />
                )}
              </Button>

              <Button
                type="primary"
                size="large"
                className="flex-button new-buttons"
                onClick={() => setAddOpen(true)}
              >
                New follow up
                <ButtonIcon
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  background={"#5a6bc5"}
                />
              </Button>
            </Flex>
          </Flex>

          {showFilters && (
            <div style={{ margin: "24px" }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <FollowupAuthorFilter />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <FollowupFromDateFilter />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <FollowupToDateFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.selectedAuthors?.map((c) => {
              let user = filters?.authors?.find((d) => d.value == c);
              if (!user) {
                return;
              }

              return (
                <div className="filter-group-item">
                  <Avatar
                    icon={<UserOutlined />}
                    src={`${env.fileUpload}${user.profile}`}
                  />
                  {user.name}

                  <Button
                    shape="circle"
                    type="text"
                    onClick={() =>
                      setFilters((prev) => ({
                        ...prev,
                        selectedAuthors: prev.selectedAuthors.filter(
                          (e) => e != c
                        ),
                      }))
                    }
                    icon={<IoClose style={{ fontSize: 18 }} />}
                    className="flex-button"
                  ></Button>
                </div>
              );
            })}
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <FollowupOrderByFilter />
            <FollowupOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>
        <Space direction="vertical" className="w-100">
          <Card size="small">
            <Row>
              <Col span={6}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={7}>
                <Text className="column-header">Description</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Created</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Author</Text>
              </Col>
              <Col span={1}>
                <Text className="column-header">Tool</Text>
              </Col>
            </Row>
          </Card>
          {loading ? (
            <TableLoading />
          ) : !data || data.length == 0 ? (
            <Card>
              <NoDataFound
                addText="New Follow up"
                onAdd={() => setAddOpen(true)}
                description="Click the New Follow Up button on the Follow ups page to create a new follow up"
                title="You have not created any Followups yet"
              />
            </Card>
          ) : (
            <>
              {data?.map((c, id) => (
                <Card key={id} size="small">
                  <Flex align="center" justify="center">
                    <Row className="w-100" style={{ height: "100%" }}>
                      <Col span={6}>
                        <Flex align="center" className="h-100">
                          <Text
                            onClick={() =>
                              navigate(`/Followup/details/${c.id}`)
                            }
                            type="link"
                            style={{
                              color: "#707070",
                            }}
                          >
                            {c.name}
                          </Text>
                        </Flex>
                      </Col>
                      <Col span={7}>
                        <Flex align="center" className="h-100">
                          <Text style={{ color: "#707070" }}>
                            {c.description}
                          </Text>
                        </Flex>
                      </Col>
                      <Col span={5}>
                        <Flex align="center" className="h-100">
                          <Text style={{ color: "#707070" }}>
                            {new Date(c.createdAt).toLocaleDateString(
                              "en-us",
                              DateFormat
                            )}
                          </Text>
                        </Flex>
                      </Col>
                      <Col span={5}>
                        <Flex align="center" justify="start" gap={6}>
                          <Avatar
                            src={`${env.fileUpload}${c.authorProfile}`}
                            icon={<UserOutlined style={{ color: "#5a6bc5" }} />}
                          />
                          <Text style={{ color: "#707070" }}>
                            {c.authorName}
                          </Text>
                        </Flex>
                      </Col>
                      <Col span={1}>
                        <Flex justify="end">
                          <FollowupOptions
                            id={c.id}
                            setCurrent={setCurrent}
                            setDeleteOpen={setDeleteOpen}
                          />
                        </Flex>
                      </Col>
                    </Row>
                  </Flex>
                </Card>
              ))}

              <Pagination
                onChange={(page) => setPage(page)}
                current={page}
                pageSize={perPage}
                total={stats?.total}
              />
            </>
          )}
        </Space>
      </Space>
    </>
  );
};
export default FollowupList;
