import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { MediaContext } from "../MediaContext";
import { MdArrowDropDown } from "react-icons/md";

export const CreatedDateFilter = () => {
  const { Text } = Typography;
  const {filters, setFilters} = useContext(MediaContext)

  return (
    <Flex align="center" justify="start" gap={6}>
        <Text style={{ color: "#a5b6d5" }}>To</Text>
      <DatePicker
        size="large"
        value={filters?.to}
        onChange={e => setFilters(prev => ({
          ...prev,
          to: e
        }))}
          className="dates-filter-select"
        placeholder="Select created date start"
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};
